import { bg, standard } from "../../../../config";
import Buy from "./forms/Buy";
import Sell from "./forms/Sell";

export default function ButtonsRow(){
    return (
        <div className={`cursor-pointer flex flex-row flex-no-wrap w-full h-full ${bg.white} justify-between items-center flex-wrap sm:flex-no-wrap ${standard.rounded} select-none`}>
                <Buy/>
                <Sell/>
        </div>
    );
}