import { isEmptyString } from "./string";

class Datetime {
    static reformateDatetime(dt) {
        try{
            const options = {month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(dt + ' UTC').toLocaleString('en-US', options);//new Date(dt.toString()).toLocaleString();
        }catch(err){return ""}
    }

    static reformateDatetimeOnlyDate(dt) {
        try{
            const options = {month: 'long', day: 'numeric'};
        return new Date(dt + ' UTC').toLocaleString('en-US', options);//new Date(dt.toString()).toLocaleString();
        }catch(err){return ""}
    }

    static getLocalTime(dt){
        if(isEmptyString(dt)){
            return undefined;
        }
        return new Date(dt + ' UTC');
    }

    static calculateDaysLeft(end){
        return Math.floor(end / (1000 * 60 * 60 * 24));
    }

    static calculateHoursLeft(end){
        return Math.floor((end % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    }

    static calculateMinsLeft(end){
        return Math.floor((end % (1000 * 60 * 60)) / (1000 * 60));
    }

    static calculateSecsLeft(end){
        return Math.floor((end % (1000 * 60)) / 1000);
    }

    static getCurrentUTCDateTime() {
        const now = new Date();
      
        // Get UTC date components
        const month = now.getUTCMonth() + 1; // Months are zero-based
        const day = now.getUTCDate();
        const year = now.getUTCFullYear() % 100; // Get last two digits of the year
      
        // Get UTC time components
        const hours = now.getUTCHours();
        const minutes = now.getUTCMinutes();
        const seconds = now.getUTCSeconds();
      
        // Add leading zero if needed
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      
        // Create the formatted UTC date string
        const formattedUTCDate = `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} UTC`;
      
        return formattedUTCDate;
      }      
}

export default Datetime;