import { useNavigate } from 'react-router-dom';
import WelcomeImage from '../../../../assets/animations/Welcome_Notification.gif';
import Competition from '../assets/competitions.png';
import { ReactComponent as ExternalLink } from '../assets/external_link.svg';
import { bg, text, standard } from '../../../../config';
import { useContext } from 'react';
import { SystemContext } from '../../../../state/System';

export default function MainPanel() {

    const navigate = useNavigate();
    const { darkMode } = useContext(SystemContext)

    return (
        <div className={`flex flex-col h-full w-full ${bg.white} ${standard.rounded} ${standard.p}`}>
            <div className="flex flex-row justify-between flex-wrap">
                <div className="flex flex-col w-full sm:w-8/12">
                    <h2 className='select-none'>Welcome to Straato!</h2>
                    <p className="mt-3">The Straato Market is now in Sandbox Mode and trading has not yet begun.</p>
                    <p className="mt-3">Here are a few ways that you can earn free Straato now. When the Marketplace opens,
                        you will be able to sell them for profit!</p>
                </div>
                <div className="flex flex-col items-center justify-center p-2 w-0 sm:w-0 md:w-3/12 select-none">
                    <img src={WelcomeImage} className="max-h-full aspect-auto" />
                </div>
            </div>
            <div className={`w-full flex flex-row items-center ${standard.mt}`}>
                <div className={`flex flex-row items-center justify-center rounded-full ${bg.blue} h-10 w-11`}>
                    <h4 className="text-white font-semibold select-none">1.</h4>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                    <h4 className="mx-3">Refer your friends to join the Straato Market.</h4>
                    <ExternalLink className="w-4 h-4 cursor-pointer" fill={darkMode ? 'white' : ''} onClick={() => navigate("/d/referrals")} />
                </div>
            </div>
            <div className={`w-full flex flex-row items-center ${standard.mt}`}>
                <div className={`flex flex-row items-center justify-center rounded-full ${bg.blue} h-10 w-11`}>
                    <h4 className="text-white font-semibold select-none">2.</h4>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                    <h4 className="mx-3">Contribute to our Straato's launch Kickstarter.</h4>
                    <ExternalLink className="w-4 h-4 cursor-pointer" fill={darkMode ? 'white' : ''} onClick={() => window.location.href = 'https://docs.straato.com'} />
                </div>
            </div>
            <div className={`w-full flex flex-row items-center ${standard.mt}`}>
                <div className={`flex flex-row items-center justify-center rounded-full ${bg.blue} h-10 w-11`}>
                    <h4 className="text-white font-semibold select-none">3.</h4>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                    <h4 className="mx-3">Win weekly sandbox trading competitions.</h4>
                    <ExternalLink className="w-4 h-4 cursor-pointer" fill={darkMode ? 'white' : ''} onClick={() => navigate("/d/trade")} />
                </div>
            </div>
            <hr className={`${standard.mt}`} />
            <div className={`flex flex-col ${standard.mt} w-full h-full justify-between items-center`}>
                <h4 className="text-center">Sandbox Trading Competition #5</h4>
                <p className={`${text.blue} font-semibold`}>Prize: 100 Straato</p>
                <div className="flex flex-row w-full justify-center items-center">
                    <div className={`border-box flex flex-col items-center mr-10`}>
                        <h3 className={`${text.blue} text-center`}>5 Days</h3>
                        <p className="font-semibold mt-1 text-center">Time Remaining</p>
                    </div>
                    <div className="flex flex-col items-center justify-center p-2 w-16 md:w-1/4 select-none">
                        <img src={Competition} className="max-h-full aspect-auto" />
                    </div>
                    <div className={`border-box flex flex-col ml-10 items-center`}>
                        <h3 className={`${text.blue} text-center`}>2 Days</h3>
                        <p className="font-semibold mt-1 text-center">Time Elapsed</p>
                    </div>
                </div>
                <p>vs. 463 other traders</p>
                <div className="flex flex-row w-full justify-between flex-wrap">
                    <small>Week 2 Winner: <span className={`font-semibold ${text.blue}`}>Andrew N</span></small>
                    <small>Week 2 Prize: <span className={`font-semibold ${text.blue}`}>50 Straato</span></small>
                </div>
            </div>
        </div>
    );
}