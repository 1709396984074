import { motion } from 'framer-motion';

const spinTransition = {
    repeat: Infinity,
    duration: 1,
    ease: "linear"
}

export default function Loader(props) {
  return (
    <div className={"flex flex-row w-full justify-center items-center"}>
        <div className={"w-[50px] h-[50px]"}>
        <motion.span
            className={props.light ? "light-circle-style" : "circle-style"}
            animate={{ rotate: 360 }}
            transition={spinTransition}
            />
        </div>
    </div>
  );
}