import { useState, useEffect, useMemo } from 'react';

export default function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])
  
  
    useEffect(() => {
      if(ref.current) observer.observe(ref.current)
      return () => observer.disconnect()
    });
  
    return isIntersecting
  }