import API from "../../api";
import trading_leader from "../../datatypes/trading_leader";
import { isSandbox } from "../../../config";

const getTradingLeaderboard = (user, type) => {

    let url = '/getTradingLeaderboard?sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))
    + '&type=' + type;

    return new API(user).getRequest(url).then(json => {
        let leaders = []
        for (let i = 0; i < json.leaderboard.length; i++) {
            leaders.push(new trading_leader(json.leaderboard[i]).getJSON());
          }
        return leaders;
    });
}

export default getTradingLeaderboard;