import { getSafeValue } from "../../util/json";
//import { capitalize } from "../../util/string"; //might want to properly capitalize ratio_calculation_duration

class cycle_params {
    constructor(input){
        this.fee_percentage = getSafeValue(input, "FEE_PERCENTAGE", "float");
        this.high_reset_threshold = getSafeValue(input, "high_reset_threshold", "float");
        this.low_reset_threshold = getSafeValue(input, "low_reset_threshold", "float");
        this.min_avg_queue_velocity = getSafeValue(input, "min_avg_queue_velocity", "float");
        this.ratio_calculation_duration = getSafeValue(input, "ratio_calculation_duration", "string");
        this.mode = getSafeValue(input, "mode", "string");
        this.num_active_assets = getSafeValue(input, "num_active_assets", "int");
        this.num_trades = getSafeValue(input, "num_trades", "int");
        this.max_per_buy = getSafeValue(input, "max_per_buy", "int");
        this.max_per_sell = getSafeValue(input, "max_per_sell", "int");
        this.max_offer_increase_proportion = getSafeValue(input, "max_offer_increase_proportion", "float");
        this.max_offer_increase_dollar = getSafeValue(input, "max_offer_increase_dollar", "float");
        this.sandbox_last_peak_price = getSafeValue(input, "sandbox_last_peak_price", "float");
        this.next_start_time = getSafeValue(input, "next_start_time", "datetime");
        this.cycle_start_time = getSafeValue(input, "cycle_start_time", "string");
        //this.last_pull_time = getSafeValueWDefault(input, "last_pull_time", "new")
    }
}

export default cycle_params;