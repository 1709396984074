import Notification from "./components/Notification";
import { useState, useEffect, useContext, useRef } from 'react';
import Loader from '../../components/Loader';
import getNotifications from "../../../api/endpoints/notifications/getNotifications";
import { AccountContext } from "../../../state/Account";
import DashboardPage from "../general/layout/DashboardPage";
import Widget from "../general/layout/Widget";
import { bg, border, text, standard } from "../../../config";
import { notifications } from "../tutorial/config";

export default function Notifications() {

    const [offset, setOffset] = useState(0);
    const MAX = 15;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [prevDist, setPrevDist] = useState(0);
    const [dist, setDist] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [isRequesting, setIsRequesting] = useState(false);

    const { user } = useContext(AccountContext);
    const notiRef = useRef(null);

    useEffect(() => {
        if (notiRef.current) {
            notifications[0].target = notiRef.current
        }
    })

    const updateOffset = () => setOffset(offset + MAX);

    const handleScroll = (e) => {
        setPrevDist(dist);
        setDist(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight);
        if (dist < 500 && !isLoading && prevDist > 500) {
            updateOffset();
        }
    }

    const refresh = () => {
        if (!isRequesting) {
            setIsRequesting(true);
            setIsLoading(true);
            setData([]);
            setOffset(0);
            getData();
        }
    }

    const getData = () => {
        setIsLoading(true);
        getNotifications(user, offset).then((nots) => {
            setIsLoading(false);
            setFirstLoad(false);
            setData((prevData) => [...prevData, ...nots]);
            setIsRequesting(false);
        });
    }

    useEffect(() => {
        getData();
    }, [offset]);

    return (
        <DashboardPage visible={true}>
            <Widget className={`${standard.p}`}>
                <div className="flex flex-row w-full justify-between items-center flex-wrap select-none">
                    <h2>Notifications</h2>
                    <div onClick={() => refresh()} className={`${bg.button} ${standard.topRightButton} ${standard.rounded} `}>
                        <small className={`${text.blue}`}>Reload</small>
                    </div>
                </div>
                <hr className={`h-px ${standard.mt} ${bg.darkBlue} border-0`} />
                <div className="overflow-y-scroll no-scrollbar pb-4" onScroll={handleScroll}>
                    {data.map((note, i) => {
                        return <span key={i}><Notification {...note} />{i < (data.length - 1) ? <hr className={`${border.darkGrey}`} /> : <></>}</span>
                    })}
                    {isLoading ? <div className={`w-full h-full border-box ${standard.p}`}><Loader /></div> : <></>}
                </div>
                {!isLoading && data.length === 0 ? <div className="flex flex-row w-full justify-center py-6 items-center"><p>You have no notifications at this time.</p></div> : <></>}
            </Widget>
        </DashboardPage>
    );
}