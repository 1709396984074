import { base_api_url } from '../config';
import { getSafeValue } from '../util/json';
import { debugging } from '../config';

class API {
  constructor(user, setMode){
    this.auth = user.idToken.jwtToken;
    this.host = base_api_url;
    this.setMode = setMode;
  }

  static handleMode(json, setMode){
    //if is_live is true, then ignore; if is_live is false and frontend is debugging, 
    //then ignore; if is_live is false and frontend is not debugging, then everything is closed
    if(!getSafeValue(json, "isLive", "boolean") && !debugging){
      setMode('closed');
      return;
    }

    if(getSafeValue(json, "mode", "string") === "pause"){
      setMode('pause');
    }else if(getSafeValue(json, "mode", "string") === "closed"){
      setMode('closed');
    }else if(getSafeValue(json, "mode", "string") === "normal"){
      setMode('normal');
    }else{
      setMode('closed');
    }
  }

  getRequest(extension){
    if(!extension.startsWith('/')){ extension = '/' + extension};
    let url = this.host + extension;
    return fetch(url, {
        method:'get',
        headers: new Headers({
            'Authorization': "Bearer " + this.auth,
            'Content-Type': 'application/json',
        }),
    })
    .then((response) => response.json())
    .then((data) => {
      if(getSafeValue(data, "statusCode", "int") !== 200){throw data} 
      return data;
    })
  }

  postRequest(extension, body){
    if(!extension.startsWith('/')){ extension = '/' + extension};
    return fetch(this.host + extension,{
        method: 'post',
        headers: new Headers({
            'Authorization': this.auth,
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)
    })
    .then((response) => response.json())
    .then((data) => {
      if(getSafeValue(data, "statusCode", "int") !== 200){throw data} 
      return data;
    })
  }
}

  export default API;