//build in support for half-width fields and loading (props.loading)

import { text, ring, bg, outline, standard } from "../../config";
import Loader from "./Loader";

export default function Form(props) {
    return (
        <form className="space-y-6" onSubmit={props.callback}>
        {props.schema.map((field, i) => {
          if(!Array.isArray(field)){
            return <div>
                <label htmlFor={field.id} className={`block text-sm font-medium leading-6 ${text.alwaysblack}`}>
                  {field.title}
                </label>
                <div className="mt-2">
                  <input
                    onChange={e => field.setter(e.target.value)}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    autoComplete={field.autoComplete}
                    required
                    className={`block w-full ${standard.formRounded} border-0 py-1.5 ${text.alwaysblack} shadow-sm ring-1 ring-inset ${ring.grey} placeholder:${text.grey} focus:ring-2 focus:ring-inset focus:${ring.blue} sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
          }else{
            return <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor={field[0].id} className={`block text-sm font-medium leading-6 ${text.alwaysblack}`}>
                {field[0].title}
              </label>
              <div className="mt-2">
                <input
                  onChange={e => field[0].setter(e.target.value)}
                  type={field[0].type}
                  name={field[0].name}
                  id={field[0].id}
                  autoComplete={field[0].autoComplete}
                  className={`block w-full ${standard.formRounded} border-0 py-1.5 ${text.alwaysblack} shadow-sm ring-1 ring-inset ${ring.grey} placeholder:${text.grey} focus:ring-2 focus:ring-inset focus:${ring.blue} sm:text-sm sm:leading-6`}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor={field[1].id} className="block text-sm font-medium leading-6">
                {field[1].title}
              </label>
              <div className="mt-2">
                <input
                  onChange={e => field[1].setter(e.target.value)}
                  type={field[1].type}
                  name={field[1].name}
                  id={field[1].id}
                  autoComplete={field[1].autoComplete}
                  className={`block w-full ${standard.formRounded} border-0 py-1.5 ${text.alwaysblack} shadow-sm ring-1 ring-inset ${ring.grey} placeholder:${text.grey} focus:ring-2 focus:ring-inset focus:${ring.blue} sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
          </div>
          }
        })}
        {props.children}
        <p className={`${standard.mt} ${text.red}`}>
            {props.errorMessage}
          </p>
        <div>
          {props.isLoading ?
          <Loader light={true}/> :
          <button
            type="submit"
            className={`flex w-full justify-center ${standard.formRounded} ${bg.blue} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:${bg.lightBlue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}>
            {props.submit}
          </button>
          }
        </div>
      </form>
    );
}