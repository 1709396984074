import { text } from "../../../config";
import ShowOnScroll from "./ShowOnScroll";

export default function FAQ(){
    return (
        <ShowOnScroll>
        <div class="max-w-screen-xl mx-auto px-5 min-h-sceen mt-24">
        <div class="flex flex-col items-center">
            <h4 className={`text-center ${text.alwaysblack}`}>Frequently Asked Questions</h4>
        </div>
        <div class={`grid divide-y divide-gray-200 w-9/12 mx-auto mt-8`}>
            <div class="py-5">
                <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                        <span>Why did we set out to build Straato?</span>
                        <span class="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
                    </summary>
                    <p class={`${text.desc} mt-3 group-open:animate-faq`}>
                    Straato got its start from the founders’ attempts at developing a quantitative intraday equity trading strategy. After months of experimentation, some pretty crazy math and machine learning, and countless failed backtests, we realized that, in short time intervals and given market efficiency today, the task is very, very difficult, if not impossible. So, we decided to think about how we could potentially change that. We soon realized that any possible alternative would have to come in the form of an entirely new market, designed with an outside-the-box mindset. Straato was soon set into motion.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                        <span>How was the Straato system created?</span>
                        <span class="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
                    </summary>
                    <p class={`${text.desc} mt-3 group-open:animate-faq`}>
                    The Straato Market was born out of a year-long (and ongoing!) thought exercise as to how to design a new and better market for active traders, considering liquidity management, market efficiency, and volatility tradeoffs. The ramifications of the Straato system can be quite interesting to think about, and we invite you to join us in our pondering on the Straato community Discord.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                        <span>How can I make money trading Straato? How can someone lose money trading Straato?</span>
                        <span class="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
                    </summary>
                    <p class={`${text.desc} mt-3 group-open:animate-faq`}>
                    You can make money on Straato the same way you do on the stock market: by buying low and selling high. You can, of course, also lose money by buying tokens at higher prices, holding them through the reset, and being forced to sell them for lower prices. Just like the stock market, Straato is a zero-sum game. All we can hope to provide is a new and exciting trading experience!
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                        <span>When will the live Straato Market be released?</span>
                        <span class="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
                    </summary>
                    <p class={`${text.desc} mt-3 group-open:animate-faq`}>
                    Just like any market, the Straato Market works best with more trading volume. Without any activity, trading would be really boring and not very profitable. Right now, we are trying to build up a user base for the sandbox environment before we release the live production Market. You can help accelerate this process by referring your friends!
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                        <span>How is Straato different from cryptocurrency?</span>
                        <span class="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
                    </summary>
                    <p class={`${text.desc} mt-3 group-open:animate-faq`}>
                    Straato is not a cryptocurrency in any capacity. We do not use blockchain or any notion of decentralized finance. Instead of an “Initial Coin Offering,” where many crypto currencies are sold for real money to first adopters, we simply give Straato away for free.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                        <span>I have other questions about Straato. Where should I go for answers?</span>
                        <span class="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
                    </summary>
                    <p class={`${text.desc} mt-3 group-open:animate-faq`}>
                    There are a lot of places to go. We welcome any questions you might have regarding the exchange system or any aspect of Straato. The first and most comprehensive resource to check out is our user guides at docs.straato.com. If you can’t find the answer to your question there, we would encourage you to ask on our Discord community. Finally, feel free to reach out to Straato Support or book a meeting with a Straato team member. We’d love to talk if you just have suggestions for improvements, too!</p>
                </details>
            </div>
        </div>
    </div>
    </ShowOnScroll>
    )
}