import Actions from "./actions/Actions";
import Education from "./education/Education";
import Notifications from "./notifications/Notifications";
import Referrals from "./referrals/Referrals";
import Sidebar from "./sidebar/Sidebar";
import { useState, useEffect, useContext } from "react";
import Account from "./account/Account";
import OfferQueue from "./queue/OfferQueue";
import TradingDashboard from "./trading/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { bg, disableTutorial, isSandbox, pages, standard } from "../../config";
import LoadingPage from "../components/LoadingPage";
import ErrorBoundaryPage from "../error/ErrorBoundaryPage";
import { TradingContext, TradingContextView } from "../../state/Trading";
import { QueueContext, QueueContextView } from "../../state/Queue";
import { SocketManager } from "../../state/Socket";
import Sandbox from "./sandbox/Sandbox";
import { ErrorBoundary } from "react-error-boundary";
import { SystemContext } from "../../state/System";
import Joyride from "react-joyride";
import { history, notifications, offerQueue, referrals, sandbox, tradingDash } from "./tutorial/config";
import PauseView from "./general/ClosedView";

export default function Dashboard() {

    const loc = useLocation();

    const page = loc.pathname.replace('/d/', '');

    const [expanded, setExpanded] = useState(false);

    const [hasError, setHasError] = useState(false);

    const { darkMode, showTutorial } = useContext(SystemContext)

    const navigate = useNavigate();

    const handleError = () => {
        setHasError(true);
    };

    const clearError = () => {
        setHasError(false);
    };

    const [steps, setSteps] = useState([]);

    const firstStep = [
        {
            content: (
                <>
                    {page === 'trade' ? (
                        <div>
                            <h4 className="text-left">Welcome to your main dashboard!</h4>
                            <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
                        </div>
                    ) : (
                        <div>
                            <h4 className="text-left">Welcome to the {page} page!</h4>
                            <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
                        </div>
                    )}
                </>
            ),
            placement: "center",
            target: "body",
        },
    ];

    useEffect(() => {
        let conditionalSteps = [];
        if (page === 'trade') {
            conditionalSteps.push(...tradingDash, ...sandbox);
        } else if (page === 'sandbox') {
            conditionalSteps.push(...sandbox, ...offerQueue)
        } else if (page === 'queue') {
            conditionalSteps.push(...offerQueue, ...referrals)
        } else if (page === 'referrals') {
            conditionalSteps.push(...referrals, ...notifications)
        } else if (page === 'notifications') {
            conditionalSteps.push(...notifications, ...history)
        } else if (page === 'history') {
            conditionalSteps.push(...history)
        }
        setSteps([...firstStep, ...conditionalSteps]);
    }, [page]);

    const getPage = (page) => {
        if (page === 'notifications') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <Notifications />
                </ErrorBoundary>
            );
        } else if (page === 'education') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <Education />
                </ErrorBoundary>
            );
        } else if (page === 'history') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <Actions />
                </ErrorBoundary>
            );
        } else if (page === 'referrals') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <Referrals />
                </ErrorBoundary>
            );
        } else if (page === 'account') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <Account />
                </ErrorBoundary>
            );
        } else if (page === 'queue') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <SocketManager>
                        <QueueContextView>
                                    <OfferQueue />
                        </QueueContextView>
                    </SocketManager>
                </ErrorBoundary>
            );
        } else if (page === 'trade') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <SocketManager>
                        <TradingContextView>
                            <TradingDashboard />
                        </TradingContextView>
                    </SocketManager>
                </ErrorBoundary>
            );
        } else if (page === 'docs') {
            window.location.replace('https://docs.straato.com');
            return <LoadingPage visible={false} />;
        } else if (page === 'community') {
            window.location.replace('https://discord.gg/BrWFGP7CTs');
            return <LoadingPage visible={false} />;
        } else if (page === 'sandbox') {
            return (
                <ErrorBoundary onError={handleError} onReset={clearError}
                    fallbackRender={({ error }) => <ErrorBoundaryPage error={error} />}>
                    <Sandbox />
                </ErrorBoundary>
            );
        }
    };

    const handleJoyrideCallback = (data) => {
        const { action, index } = data;

        if (action === 'next' && index === 4) {
            navigate('/d/sandbox');
            page = 'sandbox'
        } else if (page === 'sandbox' && action === 'next' && index === 3) {
            navigate('/d/queue');
            page = 'queue'
        } else if (page === 'queue' && action === 'next' && index === 2) {
            navigate('/d/referrals');
            page = 'referrals'
        } else if (page === 'referrals' && action === 'next' && index === 2) {
            navigate('/d/notifications');
            page = 'notifications'
        } else if (page === 'notifications' && action === 'next' && index === 2) {
            navigate('/d/history');
            page = 'history'
        }
    };

    return (
        <>
            <div className="flex flex-row flex-no-wrap">
                {isSandbox && !hasError && (page === 'queue' || page === 'trade' || page === 'history') ?
                    <div className="fixed flex flex-row w-full justify-center items-center z-20">
                        <div className={`fixed mx-4 ${bg.orange} px-2 ${standard.mt} rounded-b-xl`}>
                            <small className="tiny text-white">SANDBOX MODE</small>
                        </div>
                    </div> : <></>}
                {!hasError && <Sidebar buttons={pages} page={page} setExpanded={setExpanded} expanded={expanded} />}
                <div className={`box-border ${bg.main} min-h-screen w-full box-content`}>
                    {isSandbox && !hasError && (page === 'queue' || page === 'trade' || page === 'history') ? <div className="w-full fixed border-t-8 border-orange-400 z-20"></div> : <></>}
                    {!disableTutorial && (
                        <Joyride
                            continuous
                            callback={handleJoyrideCallback}
                            steps={steps}
                            hideCloseButton
                            scrollToFirstStep
                            showSkipButton
                            run={true}
                            styles={{
                                options: {
                                    arrowColor: !darkMode ? '#fff' : '#111827',
                                    backgroundColor: !darkMode ? '#fff' : '#111827',
                                    primaryColor: !darkMode ? '#3b82f6' : '#4b5563',
                                    textColor: !darkMode ? '#3b82f6' : '#fff',
                                }
                            }}
                        />
                    )}
                    {getPage(page)}
                </div>
            </div>
        </>
    )
}