import useWindowDimensions from "../../../../hooks/useWindowDimensions"

export default function DashboardPage(props){

    const { width } = useWindowDimensions();

    return width > 768 ?
        <div className={`border-box h-screen w-full p-3 ${props.visible ? "" : "hidden"}`}>
            {props.children}
        </div>
    :   <div className={`flex flex-col w-full h-screen p-3 flex-no-wrap overflow-y-scroll no-scrollbar ${props.visible ? "" : "hidden"}`}>
            {props.children}
        </div>

}