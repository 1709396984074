import { useContext, useState } from 'react';
import Leaderboard from '../../general/Leaderboard';
import { AccountContext } from '../../../../state/Account';
import { bg, standard } from '../../../../config';

export default function SandboxLeaderboard (props) {
    
    const [toggle, setToggle] = useState('actual');

    const { user } = useContext(AccountContext);

    let leaderMap = { 'username': "", 'gain': 'Profit'}

    const getData = (setIsLoading, setData) => {
        setIsLoading(false);
        //setIsLoading(true);
        /*getTradingLeaderboard(user, toggle).then((data) => {
            setData(data);
            setIsLoading(false);
            props.setLeaderboardLoading(false);
        }).catch((err) => {
            setData([]);
            setIsLoading(false);
            props.setLeaderboardLoading(false);
        });*/
    }

    return (
        <div className={`flex flex-col h-full w-full ${bg.white} ${standard.rounded} ${standard.p} overflow-hidden cols-auto`}>
            <h4 className='select-none'>Sandbox Competition Leaderboard</h4>
            <p className='select-none'>Week 10</p>
            <Leaderboard excludes={["isUser", "username", "rank"]} getData={getData} map={leaderMap} prefixes={{'gain':toggle === "actual" ? '$': ''}} postfixes={{'gain':toggle === "relative" ? '%': ''}}
            emptyMessage={"We can't fetch the trading leaderboard now."}/>
        </div>
    );
}