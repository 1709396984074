import { useContext, useEffect, useState } from "react";
import Feature from "../../general/Feature";
import PromoCode from "./PromoCode";
import getAccountDetails from "../../../../api/endpoints/account/getAccountDetails";
import { AccountContext } from '../../../../state/Account';
import changeAutoBuyInStatus from "../../../../api/endpoints/account/changeAutoBuyInStatus";
import liveBuyIn from "../../../../api/endpoints/account/liveBuyIn";
import { bg, isSandbox, ring, text, border, standard } from "../../../../config";
import Loader from "../../../components/Loader";
import Widget from "../../general/layout/Widget";
import { getSafeValue } from "../../../../util/json";

export default function General(props) {

    const [autoBoughtIn, setAutoBoughtIn] = useState(false);

    const [boughtIn, setBoughtIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { user } = useContext(AccountContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getAccountDetails(user).then((data) => {
            setData(data);
            setAutoBoughtIn(data.auto_buy_in);
            setBoughtIn(data.bought_in);
            setIsLoading(false);
        });
    }, []);

    const changeOptOut = () => {
        changeAutoBuyInStatus(user).then((res) => {
            setAutoBoughtIn(!autoBoughtIn);
        }).catch((err) => { });
    }

    const buyIn = () => {
        liveBuyIn(user).then((res) => {
            setBoughtIn(true);
        }).catch((err) => { });
    }

    if (!isLoading) {
        return (
            <Widget className={`${standard.p} justify-between`}>
                <div>
                    <h2>My Account</h2>
                    <hr className={`h-px ${standard.mt} ${bg.blue} border-0`} />
                    <div className="overflow-y-scroll no-scrollbar">
                        <div className={"flex flex-row flex-wrap w-full justify-start items-center"}>
                            <Feature label={"Tag Name"} value={data.tag_name} />
                            <Feature label={"Email Address"} value={data.email} />
                        </div>
                        <div className={"flex flex-row flex-wrap w-full justify-start items-center"}>
                            <Feature label={"First Name"} value={data.first_name} />
                            <Feature label={"Last Name"} value={data.last_name} />
                        </div>
                        <div className={"flex flex-row flex-wrap w-full justify-start items-center"}>
                            <Feature label={"Joined Date"} value={data.joined} />
                        </div>
                        {
                            boughtIn ?
                                <div className={"flex flex-row flex-wrap w-full justify-start items-center"}>
                                    <Feature label={"Trading Status"} value={"In Cycle"} />
                                </div>
                                :
                                <div className="flex flex-row flex-no-wrap items-center">
                                    <Feature label={"Trading Status"} value={"Out of Cycle"} />
                                    <div className={`ml-8 ${bg.offwhite} py-2 px-4 ${standard.rounded} cursor-pointer`} onClick={buyIn}>
                                        <small className={`${text.blue}`}>Buy in to Current Cycle</small>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div>
                    <hr />
                    <div className={`flex flex-row flex-wrap w-full justify-between items-center ${standard.mt}`}>
                        {!isSandbox ? <>
                            <div className="flex flex-row flex-no-wrap items-center">
                                <label class="relative inline-flex items-center cursor-pointer mr-3">
                                    <input id="autoBoughtInToggle" type="checkbox" checked={autoBoughtIn} class="sr-only peer" onChange={changeOptOut} />
                                    <div class={`w-11 h-6 ${bg.button} peer-focus:outline-none peer-focus:ring-4 peer-focus:${ring.lightBlue} dark:peer-focus:${ring.darkBlue} rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:${border.grey} after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${border.darkGrey} peer-checked:${bg.blue}`}></div>
                                </label>
                                <small>{autoBoughtIn ? "You will automatically buy in to cycles." : "You will not be automatically bought in to cycles."}</small>
                            </div>
                            <div className="flex flex-row flex-no-wrap">
                                <PromoCode />
                            </div></> : <p>You cannot opt out of cycles in sandbox mode.</p>}
                    </div>
                </div>
            </Widget>
        );
    } else {
        return (
            <Widget className={`${standard.p} justify-between`}>
                <div>
                    <h2>My Account</h2>
                    <hr className={`h-px ${standard.mt} ${bg.blue} border-0`} />
                    <div className="mt-8">
                        <Loader />
                    </div>
                </div>
            </Widget>
        )
    }
}
