import { isSandbox } from "../../../config";
import { getSafeValue } from "../../../util/json";
import API from "../../api";
import cycle_params from "../../datatypes/cycle_params";

const getDashboardCycleParams = (user) => {
    return new API(user).getRequest('/getDashboardCycleParams?sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))).then(json => {
        return new cycle_params(getSafeValue(json, "cycle_params", "object"));
    });
}

export default getDashboardCycleParams;