import { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/text_logo.png";
import { AccountContext } from "../../state/Account";
import { text, bg, outline, standard } from "../../config";

export default function ErrorBoundaryPage() {
  const { user, getSession, setUser } = useContext(AccountContext);
  const [dash, setDash] = useState(false);

  useEffect(() => {
    if (!user) {
      getSession().then((user) => {
        setUser(user);
        setDash(true);
      }).catch((err) => {
        setUser(null);
      });
    } else {
      setDash(true);
    }
  }, []);

  return (
    <div className="h-screen flex flex-col h-full justify-center items-center p-6">
      <img
        className="mx-auto h-16 sm:h-24 w-auto"
        src={logo}
        alt="Straato"
      />
      <div className="flex flex-col w-full">
        <h4 className={`mt-10 text-center text-4xl tracking-tight sm:text-4xl leading-8 !${text.black}`}>Whoops! Something went wrong.</h4>
        <p className={`mt-6 text-center tracking-tight leading-8 !${text.black}`}>Sorry for the inconvenience. If this is a recurring issue, please contact Straato support.</p>
      </div>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href={dash ? "/d/trade" : "/"}
          className={`whitespace-nowrap relative ${standard.formRounded} ${bg.blue} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ${text.lightBlue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue} z-10`}
        >
          {dash ? "Dashboard" : "Home"}
        </a>
      </div>
    </div>
  )
}