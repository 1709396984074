import { useContext } from "react";
import { formatAxisLabels } from "../../../../../util/labels";


//can add noData prop for pause mode...
export default function getResetConfig(tooltip, yMin, yMax, data_length, darkMode){

    return {
        chart: {
          stacked: false,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: [0],
          shared: true,
          custom: tooltip,
        },
        colors: ['#2E93fA', '#EF4444', '#EF4444'],
        stroke: {
          width: [1, 1, 1],
          dashArray: [0, 10, 10],
          curve: 'smooth'
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: { enabled: false },
          type: 'numeric',
          min: 1,
          max: Math.max(data_length, 100)
        },
        yaxis: {
          show: false,
          opposite: false,
          min: yMin,
          max: yMax,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            minWidth: 40,
            formatter: function (value) {
              return formatAxisLabels(value, false);
            },
            style: {
              colors: ['#90A4AE'],
            }
          },
        },
        grid: {
          show: false,
        },
        noData: {
          text: "No data available for the reset statistic at this time.",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: -20,
          style: {
            color: darkMode ? "#ffffff" : "#000000",
            fontSize: '16px',
            fontFamily: "Segoe UI"
          }
        }
      }
}

export function getPriceConfig(tooltip, colorFunction, xMax, yMaxVol, yMaxPrice, colWidth, darkMode){
    return {
        chart: {
          toolbar: {
            show: false,
        },
        stacked: false
        },
        tooltip: {
          enabled: true,
          custom: tooltip,
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: ['#3B82F6'],
          width: [1, 0, 1]
        },
        fill: {
          colors: ['#3B82F6',
            colorFunction
          ],
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.6,
            opacityTo: 0.3,
        },
          stroke: {
            show: false
          },
          opacity: 1,
          type: ['gradient', 'solid'],
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            show: false,
          }, 
          axisTicks: {
            show: false,
          },
          min: 0,
          max: xMax,
          tooltip: { enabled: false }
        },
        yaxis: [{
          show: true,
          opposite: true,
          axisTicks: {
            show: false,
          },
          min: 1,
          labels: {
            minWidth: 40,
            formatter: function (value) {
              return formatAxisLabels(value, true);
            },
            style: {
              colors: ['#90A4AE'],
            }
          }},
          {
            seriesName: 'volume',
            show: false,
            min: 0,
            max: yMaxVol,
          },
          {
            seriesName: 'price',
            show: false,
            min: 1.00,
            max: yMaxPrice*1.01//priceData.length < MIN_BARS ? (1.1 + priceData.length / MIN_BARS)*getMax(priceData, "y", 1) : getMax(priceData, "y", 1)*1.1,
          }
        ],
        grid: {
          show: true,
          borderColor: '#90A4AE11',
          xaxis: {
            lines: {
                show: true
            }
          },   
          yaxis: {
              lines: {
                  show: true
              }
          },  
          padding: {
            top: -20,
            right: 0,
            bottom: 0,
            left: 0
        },  
        },
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: colWidth + "%",//Math.min((60 / (1 + 30*Math.exp(-volumeData.length /3))) / ( MIN_BARS / volumeData.length), MIN_BARS) + "%",
            borderRadius: 0,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
          },
          candlestick: {
            colors: {
              upward: '#3B82F6',
              downward: '#3B82F6'
            },
            wick: {
              useFillColor: false
            }
          }
        },
        markers: {
          size: 0,
        },
        noData: {
          text: "No data available for Straato price and volume at this time.",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: -20,
          style: {
            color: darkMode ? "#ffffff" : "#000000",
            fontSize: '16px',
            fontFamily: "Segoe UI"
          }
        }
      }
}