export function formatAxisLabels(value, moneySign, noCommas, asInt){

    let val = value ? (isFinite(value) ? value : 0.0) : 0.0;
    
    if(val > 100 && !noCommas){
        val = parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
        if(asInt){
            val = parseInt(val);
        }else{
            val = parseFloat(val).toFixed(2);
        }
    }

    if(moneySign) return "$" + val;
    return val;
}

export function getIntPart(value){
    return Math.floor(value);
}

export function getDecimalPart(value){
    return parseFloat(value - Math.floor(value)).toFixed(2).substring(1);
}

export function minMaxStepEnforcer(val, min, max, step){
    if(!val) return val
    
    if(step === 1) val = Number(parseInt(val - (val%step)));
    if(step === 0.01) val = Number(val).toFixed(2);

    if(val > max) val = max;

    if(val < min) val = min;

    return val;
}