import { useContext, useEffect, useState } from 'react';
import { ReactComponent as X} from './assets/x.svg'
import { SystemContext } from '../../../state/System';
import BuyForm from '../../../assets/animations/BuyForm_Animation.gif';
import OfferForm from '../../../assets/animations/OfferForm_Animation.gif';
import Queue from '../../../assets/animations/StandardQueue_Animation.png';
import { bg } from '../../../config';

const Modal = (props) => {

    const { darkMode } = useContext(SystemContext);
    const [visible, setVisible] = useState(props.defaultVisible ? true : false);

    useEffect(() => {
        if(props.setVisible){
            props.setVisible(visible); //to pass visibility out...
            //props.refresh(setIsLoading, props.setData);
        }
    }, [visible]);

    const stopProp = (e) => {
        e.stopPropagation();
    }

    const getImg = () => {
        if(props.type === "buy" || props.type === "offer"){
            return <img src={props.type === "buy" ? BuyForm : OfferForm } className="max-w-full aspect-auto" style={{aspectRatio: 4}}/>
        }else if(props.type === "queue"){
            return <img src={Queue} className="max-w-full aspect-auto" style={{aspectRatio: 4}}/>
        }
    }

    return (
        <>
            <div className={props.className} onClick={visible ? {} : () => setVisible(true)}>
                {props.button_view}
            </div>
            {visible ? 
            <div className="z-50 flex flex-row fixed top-0 left-0 right-0 bottom-0 w-screen h-screen backdrop-blur-md backdrop-brightness-75 justify-center items-center p-6" onClick={() => setVisible(false)}>
                <div className={`flex flex-col w-full md:w-1/2 xl:w-1/3 ${bg.white} rounded-2xl p-6`} onClick={stopProp}>
                    <div className="flex flex-row w-full items-center justify-between flex-no-wrap space-x-6">
                        <h2>{props.title}</h2>
                        <div className="w-4" onClick={() => setVisible(false)}><X fill={darkMode ? "white" : ""}/></div>
                    </div>
                    <p className="mt-3">{props.subtext}</p>
                    <div className="flex flex-col mt-3 items-center justify-center p-2 w-full">
                        {getImg()}
                    </div>
                    {props.children}
                </div>
            </div> : <></>}
        </>
        );

}

export default Modal;