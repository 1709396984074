import Cloud from '../../assets/images/text_logo.png';
import { bg } from '../../config';
import Loader from './Loader';

export default function LoadingPage(props) {
    return (
        <div className={`flex flex-col items-center ${bg.white} justify-center h-screen w-full ${props.visible ? "hidden" :""} select-none`}>
            <div className="flex flex-col items-center justify-center p-2 w-1/2 sm:w-1/2 md:w-3/12">
                <img src={Cloud} className="max-h-full aspect-auto"/>
                <div className="mt-8">
                    <Loader/>
                </div>
            </div>
            {props.loadingText ?
            <div className="mt-8 p-2">
                <p className="text-center">{props.loadingText}</p>
            </div> : <></>
            }
        </div>
    );
}