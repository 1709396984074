import { getSafeValue } from "../../util/json";

class referral {
    constructor(input){
        this.username = getSafeValue(input, "username", "string");
        this.tag_name = getSafeValue(input, "tag_name", "string");
        this.thanked = getSafeValue(input, "thanked_for_referral", "boolean");
        this.joined = getSafeValue(input, "joined", "datetime");
    }

    getJSON() {
        return {
            username: this.username,
            tag_name: this.tag_name,
            thanked: this.thanked,
            joined: this.joined
        }
    }
}

export default referral;