import Modal from "../../general/Modal";
import { useContext, useState } from "react";
import redeemPromoCode from "../../../../api/endpoints/account/redeemPromoCode";
import { AccountContext } from "../../../../state/Account";
import { bg, text, outline, ring, standard } from "../../../../config";

export default function PromoCode() {

    const [code, setCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { user } = useContext(AccountContext);

    const redeem = () => {
        setIsLoading(true);
        redeemPromoCode(user, code).then(() => {
            setIsLoading(false);
        })
    }

    return (
        <Modal title={"Redeem Promo Code"} subtext={"Enter your promo code to earn awesome prizes!"} className={`${bg.button} py-2 px-4 ${standard.rounded} cursor-pointer`}
            button_view={<small className={`${text.blue}`}>Redeem Promo Code</small>}>
            <div className="w-full">
                <form className="space-y-6" onSubmit={redeem}>
                    <div>
                        <label htmlFor="email" className={`block text-sm font-medium leading-6 ${text.black}`}>
                            Promo Code
                        </label>
                        <div className="mt-2">
                            <input
                                id="promo"
                                name="promo"
                                type="text"
                                required
                                className={`block w-full ${standard.formRounded} border-0 py-1.5 ${text.black[13]} shadow-sm ring-1 ring-inset ${ring.grey} placeholder:${text.darkGrey} focus:ring-2 focus:ring-inset focus:${ring.blue} sm:text-sm sm:leading-6`}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className={`flex w-full justify-center ${standard.formRounded} ${bg.blue} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:${bg.lightBlue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}
                        >
                            Redeem
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}