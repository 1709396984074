import GradientView from '../GradientView';
import Hero from './components/Hero';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import Mission from './components/Mission';
import Marketplace from './components/Marketplace';
import Featured from './components/Featured';
import HowItWorks from './components/HowItWorks';
import Sandbox from './components/Sandbox';
import Community from './components/Community';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
//import Testimonials from './components/Testimonials';

export default function Home() {

    return (
        <GradientView>
                <Hero />
                <Mission/>
                <Marketplace/>
                <Community/>
                <Featured/>
                <HowItWorks/>
                <Sandbox/>
                <Contact/>
                <FAQ/>
                <CallToAction />
                <Footer/>
        </GradientView>
    );
}