import { getSafeValue } from "../../util/json";

class personal_info {

    constructor(input){
        this.accepted_tos = getSafeValue(input, "accepted_tos", "boolean");
        this.setup_complete = getSafeValue(input, "setup_complete", "boolean");
        this.sell_allowed = getSafeValue(input, "sell_allowed", "boolean");
        this.bought_in = getSafeValue(input, "bought_in", "boolean");
        this.credit_balance = getSafeValue(input, "credit_balance", "float");
        this.total_straato = getSafeValue(input, "total_straato", "int");
        this.action_count = getSafeValue(input, "action_count", "int");
        this.total_output = getSafeValue(input, "total_output", "float");
        this.total_input = getSafeValue(input, "total_input", "float");
        this.pending_offers = getSafeValue(input, "pending_offers", "int");
        this.show_tutorial = getSafeValue(input, "show_tutorial", "boolean");
    }

    calcProfit(current_price){
        return (this.total_output + current_price * this.total_straato) - this.total_input;
    }

    getCashProfit(current_price){
        return "$" + parseFloat(this.calcProfit(current_price)).toFixed(2);
    }

    getPercentProfit(current_price){
        let div = this.total_input === 0 ? 1 : this.total_input;
        return parseInt((this.calcProfit(current_price) / div) * 100) + "%";
    }

}

export default personal_info;