import { getSafeValue } from "../../util/json";

class referral_leader {
    constructor(input){
        this.rank = getSafeValue(input, "rank", "int");
        this.isUser = getSafeValue(input, "isUser", "boolean");
        this.username = getSafeValue(input, "username", "string");
        this.referrals = getSafeValue(input, "numReferrals", "int");
        this.tag_name = getSafeValue(input, "tag_name", "string");
        this.joined = getSafeValue(input, "joined", "string");
    }

    getJSON() {
        return {
            rank: this.rank,
            isUser: this.isUser,
            username: this.username,
            tag_name: this.tag_name,
            referrals: this.referrals,
            joined: this.joined
        }
    }
}

export default referral_leader;