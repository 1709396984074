import DiscordCommunity from '../assets/discord_community.png';
import SideBySide from "./SideBySide";

export default function Community(){

    return (
        <SideBySide
            img_source={DiscordCommunity}
            imgHeight={500}
            title={"A community of traders building the future."}
            text={"We envision a market that not only engages traders individually, but also a platform to connect traders to both collaborate and compete for profits. To accomplish this goal, we need to build a vibrant community. We envision an online space for traders to discuss the Straato Market, organize group actions and strategies, and connect over shared interests. We believe that Straato has the potential to be so much more than just another trading platform. It is an exercise in the building of an entirely new digital economy, and we haven't even scratched the surface of its potential ramifications. We want to explore those possibilities with you. We can’t think of a better place to accomplish all that than on Discord. Join the community now."}
            />
        )
}