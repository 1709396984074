import logo from "../../../assets/images/text_logo.png";
import GradientView from '../../GradientView';
import { useState, useContext } from 'react';
import Form from "../../components/Form";
import { checkLowercase, checkUppercase } from "../../../util/string";
import { useNavigate, useParams } from "react-router-dom";
import { AccountContext } from "../../../state/Account";
import { errorMessageMap, getAmplifyErrorMessage } from '../../../util/error';
import SignInWithGoogle from "../other/Google";
import FormGradientView from "../../FormGradientView";
import { border, ring, text, standard } from "../../../config";

export default function SignUp() {

  const [fname, setFname] = useState(null)
  const [lname, setLname] = useState(null)
  const [email, setEmail] = useState(null)
  const [tagName, setTagName] = useState(null);
  const [cpassword, setCPassword] = useState(null)
  const [password, setPassword] = useState(null)
  const [expectedUse, setExpectedUse] = useState('fun')
  const [checkbox, setCheckbox] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const { signup } = useContext(AccountContext)
  const { referral } = useParams();

  const goodPassword = (myString) => {
    return /\d/.test(myString) && myString.match(/[|\\/~^:,;?!&%$@*+]/) && checkLowercase(myString) && checkUppercase(myString)
  }

  const submit = (event) => {
    event.preventDefault()
    setIsLoading(true);
    if (!checkbox) {
      setErrorMessage(errorMessageMap.TermsAndPrivacy)
      setIsLoading(false)
      return
    }
    if (email && password && cpassword && cpassword === password) {
      if (goodPassword(password)) {
        signup(email, password, {
          given_name: fname,
          family_name: lname,
          email: email,
          'custom:tagName': tagName,
          'custom:expectedUse': expectedUse,
          'custom:referralCode': referral !== undefined ? referral : 'straato'
        })
          .then(data => {
            setIsLoading(false)
            navigate('/confirm-email?email=' + btoa(email));
          })
          .catch(err => {
            //console.log(err);
            setErrorMessage(getAmplifyErrorMessage(err))
            setIsLoading(false)
          })
      } else {
        setErrorMessage(errorMessageMap.PasswordConform)
        setIsLoading(false)
      }
    } else {
      setErrorMessage(errorMessageMap.MatchingPasswords)
      setIsLoading(false)
    }
  }


  const formProps = {
    submit: 'Sign up',
    callback: submit,
    schema: [
      [
        {
          title: "First name",
          type:"text",
          name:"first-name",
          id:"first-name",
          autoComplete:"given-name",
          setter: setFname
        },
        {
          title: "Last name",
          type:"text",
          name:"last-name",
          id:"last-name",
          autoComplete:"family-name",
          setter: setLname
        }
      ],
    {
      title: "Email address",
      id:"email",
      name:"email",
      type:"email",
      autoComplete:"email",
      setter: setEmail
    },
    {
      title: "Tag name",
      id:"tagname",
      name:"tagname",
      type:"text",
      setter: setTagName
    },
    {
      title: "Password",
      id:"password",
      name:"password",
      type:"password",
      autoComplete:"current-password",
      setter: setPassword
    },
    {
      title: "Confirm Password",
      id:"confirmPassword",
      name:"confirmPassword",
      type:"password",
      setter: setCPassword
    }
  ]
  }

  return (
  <FormGradientView>
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="Straato"
            />
          </a>
          <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight !${text.alwaysblack}`}>
            Sign Up
          </h2>
          <p className={`${standard.mt} text-center !${text.alwaysblack}`}>
            Sign up to start trading Straato!
          </p>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form {...formProps} errorMessage={errorMessage} isLoading={isLoading}>
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="whysignup" className={`block text-sm font-medium leading-6 ${text.alwaysblack}`}>
                  Why are you signing up for Straato?
                </label>
              </div>
              <div className="mt-2">
                <select id="whysignup" onChange={e => setExpectedUse(e.target.value)} className={`border ${text.alwaysblack} text-sm ${standard.formRounded} focus:${ring.blue} focus:${border.blue} block w-full p-2.5`}>
                  <option selected>Select one</option>
                  <option value='fun'>For fun!</option>
                  <option value='investment'>To make money!</option>
                  <option value='strategy'>For a puzzle!</option>
                </select>
              </div>
            </div>

            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  onChange={(e) => setCheckbox(e.target.checked)}
                  id="terms"
                  name="terms"
                  type="checkbox"
                  className={`h-4 w-4 rounded ${border.grey} ${text.blue} focus:${ring.blue}`}
                />
              </div>
              <div className="text-sm leading-6">
                <p className={`!${text.desc}`}>I agree to Straato's Privacy Policy, Terms of Services, and to receive communications.</p>
              </div>
            </div>
            </Form>
            <SignInWithGoogle/>
          <p className={`mt-10 text-center text-sm !${text.desc}`}>
            or,{' '}
            <a href="/login" className={`font-semibold leading-6 ${text.blue} hover:${text.lightBlue}`}>
              log in
            </a>
          </p>
        </div>
      </div>
    </div>
  </FormGradientView>
  );
}
