import SideBarButton from "./components/SidebarButton";
import Straato from '../../../assets/images/text_logo.png';
import { useState, useRef, useEffect, createRef, useContext } from 'react';
import { ReactComponent as UpCaret } from './assets/upcaret.svg'
import { ReactComponent as DownCaret } from './assets/downcaret.svg'
import { ReactComponent as LogOut } from './assets/logout.svg'
import { ReactComponent as Moon } from './assets/moon.svg';
import { ReactComponent as Sun } from './assets/sun.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useIsVisible from '../../../hooks/useIsVisible';
import { SystemContext } from "../../../state/System";
import { AccountContext } from "../../../state/Account";
import { useNavigate } from "react-router-dom";
import { bg } from "../../../config";

function FullSidebar(props) {
    
    const { height } = useWindowDimensions();
    const [buttonHeight, setButtonHeight] = useState(0);
    const [numButtons, setNumButtons] = useState(0);

    const { setDarkMode, darkMode } = useContext(SystemContext);
    const { setUser, logout } = useContext(AccountContext);
    const navigate = useNavigate();

    const scrollRef = useRef(null);

    const lRef = useRef(null);
    const fRef = useRef(null);

    const refsArray  =  props.buttons.map(button => ({id: button.name, ref: createRef()}));

    const topVisible = useIsVisible(fRef);
    const botVisible = useIsVisible(lRef);

    useEffect(() => {
        if(refsArray[0].ref.current !== null){
            setButtonHeight(refsArray[0].ref.current.clientHeight);
            setNumButtons(parseInt((height - 125) / buttonHeight));
        }
        refsArray[props.buttons.findIndex((element) => element.name === props.page)].ref.current.scrollIntoView()
    }, [height]);

    useEffect(() => {
        if(refsArray[0].ref.current !== null){
            setButtonHeight(refsArray[0].ref.current.clientHeight);
            setNumButtons(parseInt((height - 125) / buttonHeight));
        }
      });

      const signout = () => {
            setUser(null);
            logout();
            navigate('/');
      }

    return (
        <div className={`flex ${props.width} h-screen flex-col items-center justify-between ${bg.white}`}>
            <div className="flex w-full flex-col items-center h-full">
            <img src={Straato} className={"mt-5 w-8/12 sm:w-8/12 md:w-8/12 lg:w-1/2 xl:w-1/2 select-none"}/>
            <div className="mt-3 mb-3 h-4 w-4 cursor-pointer" onClick={() => setDarkMode(!darkMode)}>
                {darkMode ? 
                    <Moon fill="white"/> : <Sun/>
                }
            </div>
            {topVisible ? <div className="w-4">
                                <UpCaret fill={darkMode ? "#111827" : "white"}/>
                            </div> : 
                            <div onClick ={() => scrollRef.current.scrollBy({top:-buttonHeight, behavior: "smooth"})} className="w-4 sm:w-4 motion-safe:animate-fadeIn cursor-pointer">
                                <UpCaret fill={darkMode ? "white": ""}/>
                            </div>}
            <div ref={scrollRef} className="box-border snap-y overflow-y-scroll no-scrollbar snap-proximity fade-around h-screen" style={{height: numButtons * buttonHeight ? numButtons * buttonHeight : 0}}>
                {props.buttons.map((button, i) => {
                    return <div key={i} ref={refsArray[i].ref}><SideBarButton ref={(i === 0) ? fRef : (i === (props.buttons.length -1) ? lRef : null) } name={button.name} label={button.label} active={props.page} select={props.setPage}/></div>
                })}
            </div>
            {botVisible ? <></> : 
                    <div onClick ={() => scrollRef.current.scrollBy({top:buttonHeight, behavior: "smooth"})} className="w-4 sm:w-4 motion-safe:animate-fadeIn cursor-pointer">
                        <DownCaret fill={darkMode ? "white": ""}/>
                    </div>}
            </div>
            <div className={"flex flex-row w-full items-center justify-center pb-6 select-none cursor-pointer"} onClick={signout}>
                    <div className="w-4 mr-3">
                        <LogOut fill={darkMode ? "white": ""}/>
                    </div>
                <p>Log Out</p>
            </div>
        </div>);
}

function Tab (props) {
    return (
    <div className="flex flex-col justify-center h-screen">
        <div className={`flex w-4 h-1/6 flex-row items-center justify-center ${bg.blue} rounded-r-3xl`} onClick={() => props.setExpanded(!props.expanded)}>
            <div className={`relative inline-block h-1/3 mx-0.5 min-h-[1em] w-0.5 ${bg.main}`}/>
            <div className={`relative inline-block h-1/3 mr-1 min-h-[1em] w-0.5 ${bg.main}`}/>
        </div>
    </div>);
}

export default function Sidebar(props) {

    const { width } = useWindowDimensions();

    return width > 600 ?
        <FullSidebar {...props} width={"w-40"}/> : (props.expanded ?  <div className="fixed flex flex-row animate-slidein z-40"><FullSidebar {...props} width={"w-1/2"}/><Tab setExpanded={props.setExpanded} expanded={props.expanded}/></div> : <div className="fixed flex flex-row"><Tab setExpanded={props.setExpanded} expanded={props.expanded}/></div>
)
}