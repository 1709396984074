import { useRef, useState, useEffect } from 'react';
import Buy from '../assets/Buy_Notification.png';
import Welcome from '../assets/Welcome_Notification.png';
import AutoOptIn from '../assets/AutoOptIn_Notification.png';
import Credit from '../assets/Credit_Purchase_Notification.png';
import Payout from '../assets/Credit_Payout_Notification.png';
import Reset from '../assets/Reset_Notification.png';
import Referral from '../assets/Referral_Notification.png';
import Referral_Thanks from '../assets/Referral_Thanks_Notification.png';
import Cycle_Start from '../assets/Cycle_Start_Notification.png';
import TOSChange from '../assets/TOSChange_Notification.png';
import Offer from '../assets/Offer_Notification.png';
import Opt_Control from '../assets/Opt_Control_Notification.png';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { bg } from '../../../../config';
import Straato from '../../../../assets/images/symbol_logo.png';

export default function Notification (props) {

    const textRef = useRef(null);
    const [imgHeight, setImgHeight] = useState(0);

    const { width } = useWindowDimensions();

    let read = true; //this will be based on a prop

    useEffect(() => {
        setImgHeight(textRef.current.clientHeight);
    }, []);

    const getImgSrc = () => {
        if(props.icon_type === 'welcome'){
            return Welcome;
        }else if(props.icon_type === 'credit_purchase'){
            return Credit;
        }else if(props.icon_type === 'credit_payout'){
            return Payout;
        }else if(props.icon_type === 'buy'){
            return Buy;
        }else if(props.icon_type === 'opt_change'){
            return AutoOptIn;
        }else if(props.icon_type === 'offer'){
            return Offer;
        }else if(props.icon_type === 'referral'){
            return Referral;
        }else if(props.icon_type === 'reset'){
            return Reset;
        }else if(props.icon_type === 'referral_thanks'){
            return Referral_Thanks;
        }else if(props.icon_type === 'tos'){ //not sure name
            return TOSChange;
        }else if(props.icon_type === 'opt_control'){ //not sure name
            return Opt_Control;
        }else if(props.icon_type === 'cycle_start'){ //not sure name
            return Cycle_Start;
        }else{
            return Straato;
        }
    }

    return (
        <div className="flex flex-row w-full justify-between pt-3 pb-3">
            <div className="flex flex-col items-center justify-center">
                <div className="flex flex-col justify-start h-full p-3">
                    <div className={`h-3 w-3 sm:h-4 sm:w-4 rounded-full ${read ? bg.offwhite : bg.darkBlue}`}>
                    </div>
                </div>
            </div>
            <div ref={textRef} className="flex flex-col items-start w-full">
                <h4>{props.title}</h4>
                <small className="font-light">{props.time}</small>
                <p className="mt-3 break-words [overflow-wrap:anywhere]">{props.message}</p>
            </div>
            { width > 768 ? 
            <div className="flex justify-center items-center h-32 w-36 pl-4 select-none">
                <img src={getImgSrc()} className="aspect-auto"/>
            </div> : <></>}
        </div>
    );
}