import { isSandbox } from "../../../config";
import API from "../../api";

const getTransactionHistory = (user, download, table, offset) => {

    let url = '/getTransactionHistory?sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))
        + '&download=' + (download ? parseInt(1) : parseInt(0))
        + '&table=' + table
        + '&offset=' + parseInt(offset);

    return new API(user).getRequest(url).then(json => {
        return json;
    });
}

export default getTransactionHistory;