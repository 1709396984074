import { Route } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import React from "react";
import { Account } from "./state/Account";
import { aws_config } from "./config";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryPage from "./views/error/ErrorBoundaryPage";
import { System } from './state/System';
import Dashboard from './views/dashboard/dashboard';
import Home from "./views/home/Home";
import ResetPassword from "./views/auth/other/ResetPassword";
import ConfirmEmail from "./views/auth/signup/ConfirmEmail";
import Login from "./views/auth/login/Login";
import DashboardProtection from "./views/dashboard/DashboardProtection";
import SignUp from "./views/auth/signup/SignUp";
import MFA from "./views/auth/signup/MFA";
import HubspotContactForm from "./views/components/Feedback";

Amplify.configure({
  Auth: aws_config
})

export const App = () => {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Account />}>
        <Route exact path="*" element={<ErrorBoundaryPage />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signup/:referral?" element={<SignUp />} />
        <Route exact path="/confirm-email" element={<ConfirmEmail />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/mfa" element={<MFA />} />
        <Route exact path="/feedback" element={<HubspotContactForm/>}/>
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/d" element={<DashboardProtection />}>
          <Route exact path="/d/:subpage?" element={<Dashboard />} />
        </Route>
      </Route>
    )
  );

  const handleError = (error) => {
    return <ErrorBoundaryPage error={error} />;
  };

  return (
    <System>
      <ToastContainer />
      <ErrorBoundary fallbackRender={handleError}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </System>
  );
}
