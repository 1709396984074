import API from "../../api";
import account_details from "../../datatypes/account_details";
import { isSandbox } from "../../../config";

const getAccountDetails = (user) => {
    let url = '/getAccountDetails?sandbox=' + (isSandbox ? parseInt(1) : parseInt(0));
    
    return new API(user).getRequest(url).then(json => {
        return new account_details(json.data);
    });
}

export default getAccountDetails;