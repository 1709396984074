import { getSafeValue } from "../../util/json";

class queueObject{
    constructor(input){
        this.price = "$" + getSafeValue(input, "price", "float").toFixed(2);
        this.raw_price = getSafeValue(input, "price", "float"); //used for expected price calculations, not ui
        this.num = getSafeValue(input, "count", "int");
        this.seller_id = getSafeValue(input, "seller_id", "string");
        this.id = getSafeValue(input, "action_id", "string");
        this.listed_time = getSafeValue(input, "listed_time", "datetime")
        this.adjusted_time_on_seconds = getSafeValue(input, "adjusted_time_on_seconds", "float");
    }
}

export default queueObject;