import QRCodeDisplay from "./components/QRCodeDisplay";
import ReferralLeaderboard from "./components/Leaderboard";
import MainPanel from "./components/MainPanel";
import { useState, useEffect, useContext, useRef } from "react";
import getReferralInfo from '../../../api/endpoints/referrals/getReferralInfo';
import { AccountContext } from "../../../state/Account";
import LayoutManager from "../general/layout/LayoutManager";
import DashboardPage from "../general/layout/DashboardPage";
import { referrals } from "../tutorial/config";

export default function Referrals (props) {

    const [link, setLink] = useState(null);

    const { user } = useContext(AccountContext);

    const ref = useRef(null)

    useEffect(() => {
        const targets = document.querySelectorAll('.referrals-target');
        targets.forEach((target, index) => {
            if (target && referrals[index]) {
                referrals[index].target = target;
            }
        });
    })

    useEffect(() => {
        getReferralInfo(user).then((link) => {
            setLink('https://straato.com/signup/' + link);
        }).catch((err) => setLink(null));
    }, []);

    let config = {'rows': 'grid-rows-5', 'cols': 'grid-cols-7',
    views: [
        {'view': <MainPanel refer={ref} link={link}/>, 'row-span': 'row-span-5', 'col-span': 'col-span-4'},
        {'view': <ReferralLeaderboard/>, 'row-span': 'row-span-3', 'col-span': 'col-span-3', 'height': 400},
        {'view': <QRCodeDisplay link={link}/>, 'row-span': 'row-span-2', 'col-span': 'col-span-3', 'height': 400}
    ]
    }

    return <DashboardPage visible={true}><LayoutManager config={config}/></DashboardPage>
}