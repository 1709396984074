import { bg, border, text } from '../../../../config';
import referralThanks from "../../../../api/endpoints/referrals/referralThanks";

const Referral = ({ referral, index, setLoading, user }) => {

  const thank = (username) => {
      referralThanks(user, username).then(() => {
          setLoading(true);
      }).catch((err) => { });
  }

  return (
    <li key={index} className={`flex justify-between items-center py-2 ${bg.white} ${text.black} ${index == 0 ? '' : 'border-t'} font-semibold ${border.darkGrey}`}>
      <div className="flex flex-row py-2 w-full items-center">
        <div className='w-1/6 flex justify-center'>
          <div className='w-full text-center'>
            {index + 1}
          </div>
        </div>
        <div className='w-full text-start px-2 ml-4'>
          <p>{referral.tag_name}</p>
        </div>
        <div className='w-full flex justify-center px-2'>
          <span>Joined {referral.joined}</span>
        </div>
        <div className='w-1/4 flex justify-center'>
          <button className={`text-white rounded-md py-1 px-3 w-24 h-8 ${referral.thanked ? `${bg.lightGrey}` : `${bg.blue} hover:${bg.lightBlue}`}`} disabled={referral.thanked}
            onClick={() => {if(!referral.thanked) thank(referral.username)}}>
            {referral.thanked ? "Thanked" : "Thank"}
          </button>
        </div>
      </div>
    </li>
  );
};

export default Referral;
