import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveImage = ({ src, alt, height, width }) => {
  const [imageHeight, setImageHeight] = React.useState(0);
  const [imageWidth, setImageWidth] = React.useState(0);

  React.useEffect(() => {
    const image = new Image();
    image.onload = () => {
      const naturalHeight = image.naturalHeight;
      const naturalWidth = image.naturalWidth;
      if (height) {
        if (typeof height === 'string' && height.endsWith('%')) {
          const percentage = parseInt(height, 10) / 100;
          setImageHeight(percentage * naturalHeight);
        } else {
          setImageHeight(height);
        }
        setImageWidth((naturalWidth / naturalHeight) * imageHeight);
      } else if (width) {
        if (typeof width === 'string' && width.endsWith('%')) {
          const percentage = parseInt(width, 10) / 100;
          setImageWidth(percentage * naturalWidth);
        } else {
          setImageWidth(width);
        }
        setImageHeight((naturalHeight / naturalWidth) * imageWidth);
      }
    };
    image.src = src;
  });

  return (
    <img
      src={src}
      alt={alt}
      style={{
        height: imageHeight,
        width: imageWidth,
        objectFit: 'cover', // adjust as needed
      }}
    />
  );
};

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ResponsiveImage;

  