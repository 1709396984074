import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AccountContext } from '../../state/Account';
import { isKey } from '../../util/json';
import Loader from '../components/Loader';

const DashboardProtection = () => {
    const { user, setUser, getSession } = useContext(AccountContext)

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getSession().then((user) => {
            setUser(user);
            setIsLoading(false);
        }).catch((err) => {
            setUser(null);
            setIsLoading(false);
        });
    }, []);

    if(!isLoading){
        if (user && isKey(user, "idToken") && isKey(user.idToken, "jwtToken")){
            //console.log(user.idToken.jwtToken);
            return <Outlet/>
        }else{
            return <Navigate to="/login?failed=true" />;
        }
    }else{
        return <Loader/>
    }
}

export default DashboardProtection;