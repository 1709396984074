import logo from "../../../assets/images/text_logo.png";
import { AccountContext } from "../../../state/Account";
import GradientView from '../../GradientView';
import Form from "../../components/Form";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmptyString } from "../../../util/string";
import FormGradientView from "../../FormGradientView";
import { text } from "../../../config";

export default function ResetPassword(props) {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const [show, setShow] = useState(false);

  const { forgotPassword, forgotPasswordSubmit } = useContext(AccountContext);


  useEffect(() => {
    if(!isEmptyString(searchParams.get('email'))){
      setShow(true);
      setEmail(atob(searchParams.get('email')));
    }else{
      setShow(false);
    }
  }, [searchParams]);

  const submit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if(show){
        forgotPasswordSubmit(email, code, password) //still need to check that passwords are the same
        .then((data) => {
          setIsLoading(false);
            navigate('/login');
        })
        .catch((err) => {
            //setErrorMessage(getAmplifyErrorMessage(err));
            setIsLoading(false);
        })
    }else{
        forgotPassword(email)
        .then((data) => {
            setSearchParams({email: btoa(email)});
            setIsLoading(false);
        })
        .catch((err) => {
            //setErrorMessage(getAmplifyErrorMessage(err));
            setIsLoading(false);
        })
    }
  }


  const formProps = {
    submit: 'Reset',
    callback: submit,
    schema: show ? [
    {
      title: "Code",
      id:"code",
      name:"code",
      type:"password",
      setter: setCode
    },
    {
      title: "Password",
      id:"password",
      name:"password",
      type:"password",
      setter: setPassword
    },
    {
      title: "Confirm password",
      id:"confirmPassword",
      name:"confirmPassword",
      type:"password",
      setter: setConfirmPassword
    }
  ] :
  [
    {
      title: "Email Address",
      id:"email",
      name:"email",
      type:"email",
      autoComplete:"email",
      setter: setEmail
    },
  ]
}

  return (
  <FormGradientView>
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="Straato"
            />
          </a>
          <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight !${text.alwaysblack}`}>
            Reset Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form {...formProps} loading={isLoading}/>
          <p className={`mt-10 text-center text-sm !${text.alwaysblack}`}>
              {show ? <>
              Didn't receive code?{' '}
              <span className={`font-semibold leading-6 !${text.blue} hover:${text.lightBlue} cursor-pointer`}>
                Resend.
              </span>
              <br /> 
              <br /> 
              </> : <></>}
              Here by accident?{' '}
            <a href="/login" className={`font-semibold leading-6 ${text.blue} hover:${text.lightBlue}`}>
              Log in.
            </a>
            </p>
        </div>
      </div>
    </div>
  </FormGradientView>
  );
}
