import { getSafeValue } from "../../util/json";

class account_details {
    constructor(input){
        this.username = getSafeValue(input, "username", "string");
        this.tag_name = getSafeValue(input, "tag_name", "string");
        this.first_name = getSafeValue(input, "first_name", "string");
        this.last_name = getSafeValue(input, "last_name", "string");
        this.email = getSafeValue(input, "email", "string");
        this.joined = getSafeValue(input, "joined", "datetime");
        this.paypal_user_id = getSafeValue(input, "paypal_user_id", "string");
        this.auto_buy_in = getSafeValue(input, "auto_buy_in", "boolean");
        this.bought_in = getSafeValue(input, "bought_in", "boolean");
        this.paypal_isSetup = getSafeValue(input, "paypal_isSetup", "boolean");
    }
}

export default account_details;