import React, { useState, useRef, useEffect } from 'react';
import { text, bg, standard } from '../../../../config';

const FilterDropdown = ({ statuses, handleFilterChange, includeLabel = true, label }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(includeLabel ? 'Filter by Status' : label);
    const dropdownRef = useRef(null);

    const statusLabels = {
        'completed': 'Completed',
        'in_progress': 'In Progress',
        'waiting': 'Waiting',
        'error': 'Error',
        'queue_vs_price': 'Queue Vol. vs Price Dist.',
        'queue_vs_time': 'Queue Vol. vs Time Dist.'
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleStatusChange = (status) => {
        setSelectedStatus(statusLabels[status] || 'Filter by Status');
        handleFilterChange(status);
        setIsOpen(false);
    };

    const handleReset = () => {
        setSelectedStatus('Filter by Status');
        handleFilterChange('');
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <div>
                <button
                    type="button"
                    className={`inline-flex w-full justify-center gap-x-1.5 ${standard.formRounded} ${standard.dropdown} px-3 py-2 text-sm font-semibold ${text.blue}`}
                    onClick={toggleDropdown}
                >
                    {selectedStatus}
                    <svg
                        className={`-mr-1 h-5 w-5 ${text.blue}`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <div className={`absolute right-0 z-10 w-56 origin-top-right ${standard.formRounded} ${bg.white} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${bg.darkButton}`} role="menu" aria-orientation="vertical" tabIndex="-1">
                    <div role="none">
                        { includeLabel && (
                                <button
                                    onClick={handleReset}
                                    className={`${selectedStatus === 'Filter by Status'
                                        ? bg.blue + ' ' + text.white
                                        : text.darkButton
                                        } px-4 py-2 text-sm w-full text-left hover:${bg.blue} rounded-t-md hover:${text.white} ${text.black}`}
                                    role="menuitem"
                                    tabIndex="-1"
                                >
                                    Filter by Status
                                </button>
                        )}
                        {statuses.map((status, index) => (
                            <button
                                key={index}
                                onClick={() => handleStatusChange(status)}
                                className={`${selectedStatus === statusLabels[status]
                                    ? bg.blue + ' ' + text.white
                                    : text.darkButton
                                    } ${status === 'error' || status === 'queue_vs_time' ? 'rounded-b-md' : ''} ${status === 'queue_vs_price' ? 'rounded-t-md' : ''} px-4 py-2 text-sm w-full text-left ${standard.dropdownOption} ${text.black}`}
                                role="menuitem"
                                tabIndex="-1"
                            >
                                {statusLabels[status]}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterDropdown;
