import { standard, text } from "../../../config";
import ShowOnScroll from "./ShowOnScroll";

export default function Mission() {
    
    return (
        <ShowOnScroll>
            <div className="w-full px-0 md:px-12 mt-20" id="about-straato">
                <div className="flex flex-col w-full p-6 md:p-20 items-center justify-center md:rounded-2xl">
                    <h1 className={`text-center font-bold ${text.alwaysblack}`}>Built for traders.</h1>
                    <p className={`font-light mt-12 leading-8 text-center sm:w-9/12 ${text.desc}`}>Hundreds of thousands of Americans trade stocks everyday. Yet, 97% of retail day-traders lose money in the markets, while large firms rake in profits with advanced technology and information advantages. That doesn’t seem fair to us. But there is nothing that we can do to change that on the broader market.</p>
                    <p className={`${standard.mt} font-light leading-8 text-center sm:w-9/12 ${text.desc}`}>So, we created a new market to level the playing field, while addressing the root cause of why savvy traders still lose money: random price drops that can wipe out a day’s worth of earnings in just a few seconds. We are starting by testing our exchange system with digital tokens, but we hope to one day generalize the technology to create a new class of equity derivatives.</p>
                </div>
            </div>
        </ShowOnScroll>
    )
}