import { bg } from '../../../../config';
import Filler1 from '../assets/filler_1.jpg';
import Filler2 from '../assets/filler_2.jpg';
import Filler3 from '../assets/filler_3.jpg';


//need to make sure that when the user clicks back they go are redirected to education page

export default function Resource (props) {

    return (
        <div className={`border-box cols-auto h-full`}>
            <div className={`h-full w-full border-box flex flex-col items-start ${bg.white} rounded-2xl overflow-hidden`} onClick={() => window.location = 'https://www.google.com'}>
                <img src={props.index % 3 == 0 ? Filler1: props.index % 3 == 1 ? Filler2 : Filler3} className="w-full aspect-auto" style={{height: 200}}/>
                <div className="px-6 w-full pb-6">
                    <div className="flex flex-row w-full items-center justify-between flex-wrap">
                        <small className="font-light py-2">{props.publisher}</small>
                        <small className="font-light py-2">{props.date}</small>
                    </div>
                    <h4 className="line-clamp-3">{props.title}</h4>
                </div>
            </div>
        </div>
    );
}