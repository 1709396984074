import logo from "../../../assets/images/text_logo.png";
import { border, text } from "../../../config";

export default function Footer() {
  return (
    <footer className="m-4">
    <div className="w-full max-w-screen-xl mx-auto">
        <div className="sm:flex sm:items-center sm:justify-between">
            <a href="/" className="flex items-center mb-4 sm:mb-0">
                <img src={logo} className="h-8 mr-3" alt="Straato Logo" />
            </a>
            <ul className={`flex flex-wrap items-center mb-6 text-sm font-medium ${text.desc} sm:mb-0`}>
                <li>
                    <a href="/login" className="mr-4 hover:underline md:mr-6 ">Log in</a>
                </li>
                <li>
                    <a href="/signup" className="hover:underline">Sign up</a>
                </li>
            </ul>
        </div>
        <hr className={`my-6 ${border.grey} sm:mx-auto lg:my-8`} />
        <span className={`block text-sm !${text.desc} sm:text-center`}>© {(new Date().getFullYear())} <a href="/" className="hover:underline">Straato LLC</a>. All Rights Reserved.</span>
        </div>
      </footer>
    );
}
