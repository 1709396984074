import { getSafeValue } from "../../util/json";

class trading_leader {
    constructor(input){
        this.rank = getSafeValue(input, "rank", "int");
        this.isUser = getSafeValue(input, "isUser", "boolean");
        this.username = getSafeValue(input, "username", "string");
        this.tag_name = getSafeValue(input, "tag_name", "string");
        this.gain = getSafeValue(input, "gain", "float");
    }

    getJSON() {
        return {
            rank: this.rank,
            isUser: this.isUser,
            username: this.username,
            tag_name: this.tag_name,
            gain: this.gain
        }
    }
}

export default trading_leader;