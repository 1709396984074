import { getSafeValue } from "../../util/json";

class live_numbers {
    constructor(input){
        this.mkt_price = getSafeValue(input, "mkt_price", "float");
        this.min_offer_price = getSafeValue(input, "min_offer_price", "float");
        this.new_trade_ct = getSafeValue(input, "new_trade_ct", "float");
        this.queue_len = getSafeValue(input, "queueLen", "int");
        this.price_change = getSafeValue(input, "price_change", "float");
        this.queue_velocity = getSafeValue(input, "q_velocity", "float");
        this.reset_ratio = (getSafeValue(input, "reset_ratio", "float") === -1 ? "-" : getSafeValue(input, "reset_ratio", "float").toFixed(2));
        //this.last_pull_time = getSafeValueWDefault(input, "last_pull_time", "new")
    }
}

export default live_numbers;