import { ReactComponent as Info } from '../../assets/icons/info.svg';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const PopUp = ({ description }) => {

    return (
        <>
            <Popup
                trigger={<Info />}
                position="right center"
                on={['hover', 'focus']}
                closeOnDocumentClick
                contentStyle={{marginLeft: '20px'}}
            >
                {description}
            </Popup>
        </>
    );
};

export default PopUp
