import { text } from "../../../../config";
import Modal from "../../general/Modal";

export default function StatsModal(props) {
    return (
        <Modal title={"Offer Queue Stats"} type={"queue"} subtext={"These metrics reflect the current state of the offer queue."}
        className="cursor-pointer" button_view={<p className={`mt-2 ${text.blue}`}>View All Stats &#8594;</p>}>
            <div className="flex flex-row w-full flex-wrap">
                {props.children}
            </div>
        </Modal>
    );
}