import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Hub } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Outlet } from 'react-router-dom';

const AccountContext = React.createContext();

const Account = (props) => {

  const [user, setUser] = useState(null);

  const getSession = () => {
    return Auth.currentSession(); //currentAuthenticatedUser({bypassCache: true});//
  }

  const getCurrentAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser({bypassCache: true});
  }

  const authenticate = (username, password) => {
    return Auth.signIn(username, password);
  }

  const signup = (email, password, attributes) => {
    return Auth.signUp({
        username: email,
        password: password,
        attributes: attributes,
        autoSignIn: {
            enabled: true,
        }
      });
  }

  const logout = () => {
    return Auth.signOut();
  };

  const confirmSignup = (email, code) => {
    return Auth.confirmSignUp(email, code);
  }

  const listenToAutoSignInEvent = (navigation, email, setIsLoading) => {
      Hub.listen('auth', ({ payload }) => {
          const { event } = payload;
          if (event === 'autoSignIn') {
              const user = payload.data;
              setUser(user);
              let navString = '/mfa?username=' + btoa(user.username) + 
              '&challengeName=' + btoa(user.challengeName) +
              '&userpool=' + btoa(user.pool.userPoolId) +
              '&clientid=' + btoa(user.pool.clientId) +
              '&endpoint=' + btoa(user.client.endpoint) +
              '&session=' + btoa(user.Session) +
              '&email=' + btoa(email);
              setIsLoading(false);
              navigation(navString);
          } else if (event === 'autoSignIn_failure') {
              setIsLoading(false);
              navigation('/login');
          }
      });
  }

  const setupTOTP = (user) => {
    return Auth.setupTOTP(user);
  }

  const getPreferredMFA = (user) => {
    return Auth.getPreferredMFA(user, {
          bypassCache: true
    });
  }

  const setPreferredMFA = (user) => {
    Auth.setPreferredMFA(user, 'TOTP');
  }
  
  const verifyTOTP = (user, challengeAnswer) => {
    return Auth.verifyTotpToken(user, challengeAnswer);
  }

  const resendCode = (email) => {
    return Auth.resendSignUp(email)
  }

  const confirmSignIn = (user, code) => {
    return Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
  }

  const forgotPassword = (username) => {
    return Auth.forgotPassword(username);
  }

  const forgotPasswordSubmit = (username, code, new_password) => {
    return Auth.forgotPasswordSubmit(username, code, new_password)

  }

  const rememberDevice = () => {
    return Auth.rememberDevice();
  }

  const forgetDevice = () => {
    return Auth.forgetDevice();
  }

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        signup,
        confirmSignup,
        resendCode,
        listenToAutoSignInEvent,
        setupTOTP,
        verifyTOTP,
        setPreferredMFA,
        confirmSignIn,
        forgotPassword,
        forgotPasswordSubmit,
        user,
        setUser,
        rememberDevice,
        forgetDevice,
        getPreferredMFA,
        getCurrentAuthenticatedUser
      }}
    >
      <Outlet/>
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };