import React from 'react'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import Google from "../../../assets/images/google_logo.png";
import { text, standard, bg } from '../../../config';

function SignInWithGoogle () {

    const signin = () => {
      Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })
    }
    
    return (
        <div>
        <div className={`mt-6 flex w-full justify-center items-center ${standard.formRounded} border ${bg.alwayswhite} px-3 py-1.5 leading-6 shadow-sm space-x-4 cursor-pointer`}
          onClick={signin}>
          <img src={Google} className="h-5 aspect-auto"/>
          <p className={`text-sm font-semibold !${text.alwaysblack}`}>Sign in with Google</p>
        </div>
      </div>
    );
}

export default SignInWithGoogle;
