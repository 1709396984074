export default function GradientView(props) {
    return (
      <div className="relative isolate overflow-hidden">
        <div
          className="-z-10 absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(25%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(25%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        {/*New Views...*/}
        <div
          className="-z-10 absolute inset-x-0 left-[calc(5%-13rem)] top-[calc(5%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(8%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(9%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>




        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(13%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(12%-13rem)] top-[calc(10%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(12%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(100%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>




        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(17%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(25% 20%, 55% 70%, 80% 90%, 15% 30%, 50% 80%, 70% 25%, 20% 50%, 65% 85%, 90% 40%, 30% 40%, 70% 10%, 95% 60%, 35% 15%, 75% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(0%-13rem)] top-[calc(15%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(10% 30%, 50% 70%, 80% 40%, 15% 20%, 60% 80%, 90% 50%, 25% 40%, 70% 85%, 95% 30%, 35% 50%, 75% 15%, 90% 60%, 20% 15%, 55% 90%, 85% 30%, 5% 10%, 40% 80%, 70% 30%, 25% 60%, 60% 25%, 85% 70%, 30% 20%, 80% 75%, 90% 35%, 15% 85%, 50% 45%, 80% 90%, 35% 55%, 65% 25%, 95% 65%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(16%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(100%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>




        <div
          className="-z-10 absolute inset-x-0 left-[calc(5%-13rem)] top-[calc(25%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(25%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(21%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>


        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(27%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(15%-13rem)] top-[calc(28%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(31%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>




        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(39%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(25% 20%, 55% 70%, 80% 90%, 15% 30%, 50% 80%, 70% 25%, 20% 50%, 65% 85%, 90% 40%, 30% 40%, 70% 10%, 95% 60%, 35% 15%, 75% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(0%-13rem)] top-[calc(36%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(10% 30%, 50% 70%, 80% 40%, 15% 20%, 60% 80%, 90% 50%, 25% 40%, 70% 85%, 95% 30%, 35% 50%, 75% 15%, 90% 60%, 20% 15%, 55% 90%, 85% 30%, 5% 10%, 40% 80%, 70% 30%, 25% 60%, 60% 25%, 85% 70%, 30% 20%, 80% 75%, 90% 35%, 15% 85%, 50% 45%, 80% 90%, 35% 55%, 65% 25%, 95% 65%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(33%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(100%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>





        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(45%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(25% 20%, 55% 70%, 80% 90%, 15% 30%, 50% 80%, 70% 25%, 20% 50%, 65% 85%, 90% 40%, 30% 40%, 70% 10%, 95% 60%, 35% 15%, 75% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(0%-13rem)] top-[calc(46%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(10% 30%, 50% 70%, 80% 40%, 15% 20%, 60% 80%, 90% 50%, 25% 40%, 70% 85%, 95% 30%, 35% 50%, 75% 15%, 90% 60%, 20% 15%, 55% 90%, 85% 30%, 5% 10%, 40% 80%, 70% 30%, 25% 60%, 60% 25%, 85% 70%, 30% 20%, 80% 75%, 90% 35%, 15% 85%, 50% 45%, 80% 90%, 35% 55%, 65% 25%, 95% 65%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(43%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(100%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>



        <div
          className="-z-10 absolute inset-x-0 left-[calc(5%-13rem)] top-[calc(52%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(53%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(56%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>



        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(58%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(0%-13rem)] top-[calc(63%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(65%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>


        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(70%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(70%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(70%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(100%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>



        <div
          className="-z-10 absolute inset-x-0 left-[calc(50%-13rem)] top-[calc(75%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              'polygon(15% 20%, 45% 60%, 80% 90%, 10% 30%, 55% 75%, 25% 10%, 70% 40%, 95% 80%, 40% 50%, 20% 90%, 60% 30%, 85% 70%, 25% 15%, 60% 80%, 95% 50%, 5% 5%, 40% 90%, 70% 40%, 20% 60%, 55% 20%, 85% 70%, 35% 25%, 75% 70%, 90% 30%, 15% 80%, 50% 40%, 80% 85%, 30% 50%, 65% 10%, 95% 60%)'
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(0%-13rem)] top-[calc(80%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="-z-10 absolute inset-x-0 left-[calc(100%-13rem)] top-[calc(83%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(20% 30%, 60% 70%, 90% 40%, 5% 10%, 40% 90%, 70% 20%, 15% 40%, 50% 80%, 75% 50%, 25% 15%, 60% 80%, 95% 50%, 30% 10%, 70% 25%, 90% 80%, 10% 5%, 45% 85%, 75% 30%, 25% 60%, 60% 20%, 85% 65%, 35% 25%, 80% 75%, 90% 35%, 20% 85%, 55% 45%, 80% 90%, 30% 50%, 65% 15%, 95% 60%)',
            }}
          />
        </div>

        {/*Old Views...*/}
        <div
          className="-z-10 absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffffff] to-[#2563eb] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="-z-10 absolute inset-x-0 top-[calc(40%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffffff] to-[#2563eb] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="-z-10 absolute inset-x-0 left-[calc(60%-13rem)] top-[calc(70%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(70%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="-z-10 absolute inset-x-0 top-[calc(60%-13rem)] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(100%-11rem)] aspect-[3/1] w-[50rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(50.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 21.5% 0.1%, 80.7% 2%, 72.5% 29% 14%, 96% 7%, 73% 32%, 100% 80%, 79% 76%, 20% 100%, 55% 69%, 19% 40%)',
            }}
          />
        </div>

        {props.children}


        <div
          className="-z-10 absolute inset-x-0 left-0 top-[calc(90%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(90%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffffff] to-[#2563eb] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="-z-10 absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="-z-10 absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffffff] to-[#2563eb] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

      </div>
    );
}
