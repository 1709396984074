const testAccount = false;
const disableTutorial = true;
const debugging = false;

const aws_test_config = {
  identityPoolId: 'us-east-1:7e4c43f0-e0ee-4a93-aa5f-56daae0ae68b',
  region: 'us-east-1',
  userPoolId: "us-east-1_drp3w3svD",
  userPoolWebClientId: '4fhaftkkh19nr8mq4oovgir7jg',
  oauth: {
    domain: "straatotest.auth.us-east-1.amazoncognito.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: "http://localhost:3000/d/trade",
    redirectSignOut: "http://localhost:3000",
    responseType: "code"
  }
}

const aws_config = {
  identityPoolId: 'us-east-1:d0687a71-b263-41a3-8a8d-af1bb11cb9d6',
  region: 'us-east-1',
  userPoolId: 'us-east-1_XL7CJ4b47',
  userPoolWebClientId: '65vl70j7oo2ubtslsihaqip7jl',
  oauth: {
    domain: "login.straato.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: "https://www.straato.com/d/trade",
    redirectSignOut: "https://www.straato.com",
    responseType: "code"
  }
}

const base_api_url = testAccount ? "https://t59ibctz69.execute-api.us-east-1.amazonaws.com/test" : "https://qx9veu1isf.execute-api.us-east-1.amazonaws.com/straato"; //"https://qx9veu1isf.execute-api.us-east-1.amazonaws.com/beta";

const base_web_url = "https://www.straato.com/";

const websocket = testAccount ? "wss://byvpf3ldo2.execute-api.us-east-1.amazonaws.com/production/" : "wss://f3h1hxl6x8.execute-api.us-east-1.amazonaws.com/development/";

const extensions = {
  GET_NOTIFICATIONS: '/getNotifications',
  BUY: '/buy',
  OFFER: '/offer',
  GET_TRANSACTION_HISTORY: '/getTransactionHistory'
}

const pages = [
  //{name: 'sandbox', label: 'Sandbox'},
  { name: 'trade', label: 'Dashboard' },
  //{ name: 'queue', label: 'Offer Queue' },
  { name: 'referrals', label: 'Referrals' },
  { name: 'notifications', label: 'Notifications' },
  //{name: 'account', label: 'Account'},
  //{ name: 'history', label: 'History' },
  { name: 'docs', label: 'Docs' },
  { name: 'community', label: 'Community' }
]

const default_page = 'trade';

const isSandbox = true;

const bg = {
  green: 'bg-green-500',
  red: 'bg-red-500',
  orange: 'bg-orange-400',
  yellow: 'bg-yellow-400',
  white: 'bg-white dark:bg-gray-900',
  alwayswhite: 'bg-white',
  offwhite: 'bg-gray-100 dark:bg-gray-500',
  lightGrey: 'bg-gray-200',
  grey: 'bg-gray-400',
  darkGrey: 'dark:bg-gray-500',
  black: 'bg-gray-900',
  lightBlue: 'bg-blue-500',
  blue: 'bg-blue-600',
  darkBlue: 'bg-blue-600 dark:bg-blue-800',
  main: 'bg-blue-50 dark:bg-black',
  button: 'bg-gray-100 dark:bg-gray-700',
}

const text = {
  green: '!text-green-500 dark:text-green-500',
  red: '!text-red-500 dark:text-red-500',
  orange: 'text-orange-400 dark:text-orange-400',
  yellow: 'text-yellow-400 dark:text-yellow-500',
  offwhite: 'text-gray-100',
  white: 'text-white dark:text-black',
  grey: 'text-gray-400',
  darkGrey: 'text-gray-500',
  desc: 'text-gray-700 dark:text-gray-700',
  black: 'text-gray-900 dark:text-gray-100',
  alwaysblack: 'text-gray-900 dark:text-gray-900',
  lightBlue: '!text-blue-500',
  blue: '!text-blue-600 !dark:text-blue-600',
}

const outline = {
  blue: 'outline-blue-600'
}

const ring = {
  blue: 'ring-blue-600',
  lightBlue: 'ring-blue-500',
  darkBlue: 'ring-blue-800',
  grey: 'ring-gray-200',
  darkGrey: 'dark:ring-gray-700',
  focusDark: 'dark:focus:ring-blue-600'
}

const border = {
  blue: 'border-blue-600 dark:border-blue-800',
  grey: 'border-gray-200',
  darkGrey: 'dark:border-gray-600',
  orange: 'border-orange-400',
}

const standard = {
  rounded: 'rounded-xl',
  formRounded: 'rounded-md',
  p: 'p-6',
  mt: 'mt-4',
  topRightButton: 'py-2 px-4 cursor-pointer my-4 sm:m-0',
  mdW: 'md:w-1/3',
  dropdown: 'bg-white dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700',
  dropdownOption: 'hover:bg-blue-600 hover:text-white'
}

module.exports = {
  aws_config: testAccount ? aws_test_config : aws_config,//("REACT_APP_TEST_AWS" in process.env) ? aws_test_config : aws_config,
  base_api_url: base_api_url,
  base_web_url: base_web_url,
  websocket: websocket,
  extensions: extensions,
  disableTutorial: disableTutorial,
  pages: pages,
  default_page: default_page,
  isSandbox: isSandbox,
  text: text,
  bg: bg,
  outline: outline,
  ring: ring,
  border: border,
  standard: standard,
  debugging: debugging
}