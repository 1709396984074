import { forwardRef } from "react";
import { bg, standard } from "../../../../config";

const Widget = forwardRef((props, ref) => {
    return (
        <div ref={ref} className={`flex flex-col h-full w-full 
        ${bg.white} ${standard.rounded} cols-auto ${props.className}`}>
            {props.children}
        </div>
    );
  });

export default Widget;