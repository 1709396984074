import API from "../../api";
import referral from "../../datatypes/referral";

const getMyReferrals = (user, offset) => {
    return new API(user).getRequest('/getMyReferrals?offset=' + parseInt(offset)).then(json => {
        let refs = []
        for (let i = 0; i < json.myReferrals.length; i++) {
            refs.push(new referral(json.myReferrals[i]).getJSON());
          }
        return {"referrals" : refs, "count" : json.referralsCount};
    });
}

export default getMyReferrals;