import ButtonsRow from "./components/ActionBar";
import MainChartView from "./components/MainView";
import ResetGraph from "./components/ResetWatch";
import { useContext, useEffect, useRef, useState } from 'react';
import LoadingPage from "../../components/LoadingPage";
import Stats from "./components/StatsWidget";
import DashboardPage from "../general/layout/DashboardPage";
import LayoutManager from "../general/layout/LayoutManager";
import { tradingDash } from "../tutorial/config";
import PauseView from "../general/ClosedView";
import { TradingContext } from "../../../state/Trading";
import ClosedView from "../general/ClosedView";

export default function TradingDashboard() {
    
    const [mainChartLoading, setMainChartLoading] = useState(true);
    const [resetGraphLoading, setResetGraphLoading] = useState(true);
    const [infoLoading, setInfoLoading] = useState(true);

    const [unit, setUnit] = useState('1c');

    const ref = useRef(null)

    const { mode } = useContext(TradingContext);

    useEffect(() => {
        const targets = document.querySelectorAll('.dashboard-target');
        targets.forEach((target, index) => {
            if (target && tradingDash[index]) {
                tradingDash[index].target = target;
            }
        });
    })

    let visible = !mainChartLoading && !resetGraphLoading && !infoLoading;

    let config = {'rows': 'grid-rows-12', 'cols': 'grid-cols-6', 'breakpoint' : 1200,
    views: [
        {'view': <MainChartView refer={ref} setLoading={setMainChartLoading} isLoading={mainChartLoading} unit={unit} setUnit={setUnit}/>, 'row-span': 'row-span-6', 'col-span': 'col-span-full', 'height': 400},
        {'view': <ResetGraph refer={ref} setLoading={setResetGraphLoading} isLoading={resetGraphLoading} unit={unit} setUnit={setUnit}/>, 'row-span': 'row-span-6', 'col-span': 'col-span-4'},
        {'view': <Stats refer={ref} setLoading={setInfoLoading} isLoading={infoLoading}/>, 'row-span': 'row-span-5', 'col-span': 'col-span-2'},
        {'view': <ButtonsRow/>, 'row-span': 'row-span-1', 'col-span': 'col-span-2'}
    ]
}

    return mode === 'closed' ?
        <ClosedView/>
        : <><DashboardPage visible={visible}><LayoutManager config={config}/></DashboardPage>
            <LoadingPage visible={visible} loadingText={"Just give us a second to load your dashboard. Happy trading!"}/></>

}