import { useContext, useState } from 'react';
import Toggle from "../../general/Toggle";
import getTradingLeaderboard from '../../../../api/endpoints/account/getTradingLeaderboard';
import { isSandbox, standard } from '../../../../config';
import Leaderboard from '../../general/Leaderboard';
import { AccountContext } from '../../../../state/Account';
import Widget from '../../general/layout/Widget';

export default function AccountLeaderboard (props) {
    
    const [toggle, setToggle] = useState('actual');

    const { user } = useContext(AccountContext);

    let leaderMap = { 'username': "", 'gain': 'Profit'}

    const getData = (setIsLoading, setData) => {
        setIsLoading(true);
        getTradingLeaderboard(user, toggle).then((data) => {
            setData(data);
            setIsLoading(false);
        }).catch((err) => {
            setData([]);
            setIsLoading(false);
        });
    }

    return (
        <Widget className={`${standard.p} overflow-hidden`}>
            <h4>Top Players{isSandbox ? " (Sandbox)": ""}</h4>
            <div className="flex flex-row flex-wrap justify-start">
                <Toggle name={"actual"} toggle={toggle} setToggle={setToggle} label={"Numeric"}/>
                <Toggle name={"relative"} toggle={toggle} setToggle={setToggle} label={"Relative"}/>
            </div>
            <Leaderboard excludes={["isUser", "username", "rank"]} getData={getData} map={leaderMap} prefixes={{'gain':toggle === "actual" ? '$': ''}} postfixes={{'gain':toggle === "relative" ? '%': ''}}
            emptyMessage={"We can't fetch the trading leaderboard now."}/>
        </Widget>
    );
}