import { useState, useEffect, memo, useContext } from 'react';
import { getSafeValue } from '../../../../util/json';
import Loader from '../../../components/Loader';
import { ReactComponent as UpCaret } from '../../sidebar/assets/upcaret.svg'
import { ReactComponent as DownCaret } from '../../sidebar/assets/downcaret.svg'
import { SystemContext } from '../../../../state/System';
import { bg, border, text } from '../../../../config';

export default memo(function ActionsTable(props) {

  const [offset, setOffset] = useState(0);
  const MAX = 15;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [prevDist, setPrevDist] = useState(0);
  const [dist, setDist] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState(null);
  const { darkMode } = useContext(SystemContext)
  const emptyMessages = {
    'completed': "You don't have any completed transactions yet!",
    'in_progress': "You don't have any transactions in progress yet!",
    'waiting': "You don't have any waiting transactions yet!",
    'error': "All good here! You don't have any transactions with an error."
  }

  const updateOffset = () => {
    if (!isLoading) {
      setOffset((prevOffset) => prevOffset + MAX);
    }
  };

  const handleScroll = (e) => {
    if (!props.disableLazyLoad) {
      setPrevDist(dist);
      setDist(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight);
      if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 100 && !isLoading && prevDist > 100) {
        updateOffset();
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    props.getData(setIsLoading, offset, [], setData, MAX, props.selectedStatus);
    setOffset(0);
  }, [offset, props.selectedStatus]);

  const handleSort = (column) => {
    const order = sortedColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';

    setSortOrder(order);
    setSortedColumn(column);

    let sortedData = [...filteredData];

    sortedData.sort((a, b) => {
      const valA = a[column]
      const valB = b[column]

      if (valA < valB) {
        return order === 'asc' ? -1 : 1;
      } else if (valA > valB) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
  };

  const mapper = item => props.map[item];

  let excludes = props.excludes ? props.excludes : [];
  let blocks = props.blocks ? props.blocks : {};

  data.forEach(row => {
    if ('trade_count' in row && typeof row['trade_count'] === 'number') {
      row['num'] = parseInt(row['trade_count'] + row['queue_count']) + " [" + row['trade_count'] + " sold, " + row['queue_count'] + " on queue]"
      row['trade_count'] = props.status_transform(row['trade_count'], row['queue_count']);
    }
  })

  let headers = data.length > 0 ? Object.keys(data[0]).filter((val) => !excludes.includes(val)).map(mapper) : Object.values(props.map);

  let formatting = props.formatting ? props.formatting : {};

  let headerNames = data.length > 0 ? Object.keys(data[0]).filter((val) => !excludes.includes(val)) : []

  const filteredData = data.filter(row => !props.selectedStatus || row.trade_count === props.selectedStatus);

  return (
    <>
      <div className="inline-block min-w-full overflow-x-auto overflow-y-scroll no-scrollbar" onScroll={handleScroll} ref={props.refer}>
        <table className="min-w-full text-left font-light">
          <thead className={`text-left ${bg.white} font-semibold top-0 sticky ${border.darkGrey} select-none`}>
            <tr>
              <th scope="col" className={`${text.black} py-2 px-2 pr-6`}>#</th>
              {headers.map((header, i) => { 
                return (
                  <th key={i} scope="col" className="text-left pr-6 whitespace-nowrap">
                    <div className="flex flex-row items-center">
                      {header}
                      {header !== 'Transaction' && header !== 'Status' && header !== 'Time' && (
                        <span className="cursor-pointer ml-2" onClick={() => handleSort(headerNames[i])}>
                          {sortedColumn === headerNames[i] && sortOrder === 'asc' ? <div className="w-2"><UpCaret fill={!darkMode ? "#111827" : "white"} /></div> : <div className="w-2"><DownCaret fill={!darkMode ? "#111827" : "white"} /></div>}
                        </span>
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="pb-0 mb-0">
            {filteredData.map((r, i) => {
              let row = r;
              return (
                <tr key={i} className={`${border.darkGrey} ${i % 2 === 0 ? bg.main : bg.white}`}>
                  <td className={`whitespace-nowrap font-medium text-xl px-4 py-2`}>{i + 1}</td>
                  {Object.keys(row).map((key, k) => {
                    if (!excludes.includes(key)) {
                      return (
                        <td key={k} className="pr-4">
                          <div className={`whitespace-nowrap text-base ${key in blocks ? "py-1 rounded text-white w-20 px-1 text-center text-sm " + (typeof blocks[key] === 'object' ? blocks[key][row[key]] : blocks[key]) : ""}`}>
                            {getSafeValue(props.prefixes, key, "string")}
                            {key in formatting ? formatting[key](row[key]) : row[key]}
                            {getSafeValue(props.postfixes, key, "string")}
                          </div>
                        </td>
                      );
                    }
                  })}
                </tr>);
            })}
          </tbody>
        </table>
        {isLoading ? <div className="w-full h-full border-box p-6"><Loader /></div> : <></>}
      </div>
      {!isLoading && filteredData.length === 0 ? <div className="flex flex-row w-full items-center justify-center mt-10 px-20"><p className="text-center">{props.emptyMessage ? emptyMessages[props.selectedStatus] || props.emptyMessage : "No data at the moment."}</p></div> : <></>}
    </>
  );
});