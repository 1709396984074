import { isSandbox } from "../../../config";
import API from "../../api";
import live_numbers from "../../datatypes/live_numbers";

export const handleDashboardLiveNumbers = (json, setMode) => {
    API.handleMode(json, setMode);

    return new live_numbers(json);
}

const getDashboardLiveNumbers = (user, setMode) => {
    return new API(user).getRequest('/getDashboardLiveNumbers?rest_api_call=1&last_pull_time=new&sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))).then(json => {
        return handleDashboardLiveNumbers(json, setMode);
    });
}

export default getDashboardLiveNumbers;