import logo from "../../../assets/images/text_logo.png";
import GradientView from '../../GradientView';
import { useState, useEffect, useContext } from 'react';
import Form from "../../components/Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AccountContext } from "../../../state/Account";
import ErrorBoundaryPage from "../../error/ErrorBoundaryPage";
import { isEmptyString } from "../../../util/string";
import { getAmplifyErrorMessage } from "../../../util/error";
import { Auth } from "aws-amplify";
import FormGradientView from "../../FormGradientView";
import { text } from "../../../config";

export default function ConfirmEmail(props) {

  const [code, setCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [fromLogin, setFromLogin] = useState(false);
  const [resent, setResent] = useState('resend');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const [valid, setValid] = useState(true);

  const { resendCode, confirmSignup, listenToAutoSignInEvent, getSession } = useContext(AccountContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  //need to add gtag support back
  /*
        if (window.document.location.href.includes('confirm-email')) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-11213545565/1fx_CKiInrgYEN3AhOMp'
        });
      }
  */

  useEffect(() => {
    if(!isEmptyString(searchParams.get('login')) && searchParams.get('login') === 'true'){
      setFromLogin(true);
    }
    if(!isEmptyString(searchParams.get('email'))){
      setValid(true);
      setEmail(atob(searchParams.get('email')));
    }else{
      setValid(false);
      setEmail(null);
    }
  }, [searchParams]);


  const confirm = (event) => {
    event.preventDefault();
    if(!isEmptyString(email) && !confirmed){
      setIsLoading(true);
      confirmSignup(email, code)
      .then(() => {
        if(fromLogin){ 
          setConfirmed(true);
          setIsLoading(false);
        }
        else{ listenToAutoSignInEvent(navigate, email, setIsLoading); }
      })
      .catch((err) => {
        setIsLoading(false);
        if(err.message === "User cannot be confirmed. Current status is CONFIRMED"){
          setConfirmed(true);
        }else{
          setErrorMessage(getAmplifyErrorMessage(err));
        }
      });
    }else{
      navigate('/login');
    }
  }

  const handleResend = () => {
    resendCode(email).then(res => {
      setResent('resent');
      return new Promise(r => setTimeout(r, 3000))
    }).catch((err) => {
      setResent('failed') 
      return new Promise(r => setTimeout(r, 3000))
    }).then(() => {
      setResent('resend');
    })

  }

  const formProps = {
    submit: 'Continue',
    callback: confirm,
    schema: [
    {
      title: "Confirmation code",
      id:"code",
      name:"code",
      type:"password",
      setter: setCode
    },
  ]
  }

  return valid ? (
  <FormGradientView>
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="Straato"
            />
          </a>
          <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight ${text.black}`}>
            Confirm Email
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form {...formProps} isLoading={isLoading} errorMessage={errorMessage}/>
          {confirmed ? 
          <p className={`mt-10 text-center text-sm ${text.green}`}>
            Success! Your user is confirmed. {' '}
            <span className={`cursor-pointer font-semibold leading-6 ${text.blue} hover:${text.lightBlue}`} onClick={() => navigate('/login')}>
              Login.
            </span>
          </p>
          :
          <p className={`mt-10 text-center text-sm ${text.grey}`}>
            Didn't receive a code?{' '}
            <span className={`cursor-pointer font-semibold leading-6 ${resent === 'resent' ? text.green : resent === 'failed' ? text.red: text.blue + 'hover: ' + text.lightBlue}`} 
              onClick={handleResend}>
              {resent === 'resent' ? "Resent Code!" : resent === 'failed' ? "Failed to resend code." : "Resend."}
            </span>
          </p>}
        </div>
      </div>
    </div>
  </FormGradientView>
  ) : <ErrorBoundaryPage/>;
}
