import logo from "../../../assets/images/text_logo.png";
import { useContext, useState, useEffect, memo } from 'react';
import GradientView from '../../GradientView';
import { AccountContext } from "../../../state/Account";
import { Navigate, redirect, useNavigate, useSearchParams } from "react-router-dom";
import Form from "../../components/Form";
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { text, default_page, bg, outline, ring, border, standard } from "../../../config";
import QRCode from 'qrcode.react'
import { getAmplifyErrorMessage } from "../../../util/error";
import { Auth } from "aws-amplify";
import FormGradientView from "../../FormGradientView";

const SetupMFA = function SetupMFA(props) {
  const [page, setPage] = useState(0);
  const [qr, setQr] = useState(null);
  const [selectedMFA, setSelectedMFA] = useState("computer");
  const [setupCode, setSetupCode] = useState(null);

  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const maxPage = 3;

  const { verifyTOTP, setPreferredMFA, setupTOTP } = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(() => {
    setQr(`otpauth://totp/AWSCognito:${props.email + "_test"}?secret=${setupCode}&issuer=Straato`);
  }, [setupCode]);

  useEffect(() => {
    if (props.user !== null) {
      setupTOTP(props.user).then((code) => {
        setSetupCode(code);
      }).catch((err) => {
        navigate('/login');
      });
    }
  }, [props.user]);

  const selectMFA = (event) => {
    setSelectedMFA(event.target.value);
  };

  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    verifyTOTP(props.user, code)
      .then(() => {
        setPreferredMFA(props.user);
        setIsLoading(false);
        navigate('/d/' + default_page);
      })
      .catch((e) => {
        setErrorMessage(getAmplifyErrorMessage(e));
        setIsLoading(false);
      });

  }

  const formProps = {
    submit: 'Submit',
    callback: submit,
    schema: [
      {
        title: "MFA Code",
        id: "code",
        name: "code",
        type: "password",
        setter: setCode
      },
    ]
  }

  const getPageView = () => {
    switch (page) {
      case 0:
        return (
          <div>
            <div>
              For maximum security, Straato requires you to download an app for TOTP multi-factor authentication on your phone. Although there are many MFA providers, we recommend Google Authenticator as an easy-to-use option. You can read more about MFA{' '}
              <a href="https://docs.straato.com/onboarding/create-account#multi-factor-authentication" className={`leading-6 ${text.blue} hover:${text.lightBlue}`}>
                here
              </a>
              .
            </div>
            <div className={`${standard.mt}`}>
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                className={`flex w-full justify-center ${standard.formRounded} ${bg.blue} px-3 py-1.5 text-sm font-semibold leading-6 ${text.offwhite} shadow-sm hover:${text.blue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}
              >
                Download for Android
              </a>
            </div>
            <div className={`${standard.mt}`}>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                className={`flex w-full justify-center ${standard.formRounded} ${bg.blue} px-3 py-1.5 text-sm font-semibold leading-6 ${text.offwhite} shadow-sm hover:${text.blue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}
              >
                Download for iOS
              </a>
            </div>
            <div className="pt-4">You may proceed to setup MFA after you have downloaded your chosen MFA provider app. A Google Account is not necessary to use Google Authenticator.</div>
          </div>
        );
      case 1:
        return (
          <div>
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="device-type" className={`block text-sm font-medium leading-6 !${text.grey}`}>
                  What type of device are you on?
                </label>
              </div>
              <div className="mt-2">
                <select id="device-type" className={`border ${text.grey} text-sm ${standard.formRounded} focus:${ring.blue} focus:${border.blue} block w-full p-2.5`} value={selectedMFA} onChange={selectMFA}>
                  <option value="computer">A laptop or desktop computer.</option>
                  <option value="mobile">A smartphone or other mobile device.</option>
                </select>
              </div>
            </div>
          </div>
        );
      case 2:
        if (selectedMFA === "computer") {
          return (
            <div>
              <div>You have indicated that you are using your computer to sign up for the Straato Market.</div>
              <br />
              <div className="flex flex-row justify-center">
                <QRCode value={qr} id="mfaQrCode" />
              </div>
              <br />
              <div>Open your Google Authenticator app and click the large plus sign in the bottom right-hand corner. Then, click "Scan a QR code". Scan the above code.</div>
            </div>
          );
        } else {
          return (
            <div>
              <div>You have indicated that you are using your mobile device to sign up for the Straato Market. Copy the code below.</div>
              <br />
              <div>
                <span onClick={() => navigator.clipboard.writeText(setupCode)} className={`flex w-full justify-center ${standard.formRounded} ${bg.blue} px-3 py-1.5 text-sm font-semibold leading-6 ${text.offwhite} shadow-sm hover:${bg.blue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}>
                  Copy Code
                </span>
              </div>
              <br />
              <div>Open your Google Authenticator app, then click the large plus sign in the bottom right-hand corner. Then click "Enter a setup key".</div>
              <br />
              <div>Type "Straato" as your "Account" and paste the copied code into the "Key" field. Then click "Add".</div>
            </div>
          );
        }
      case 3:
        return (
          <div>
            <div>You have now setup multi-factor authentication. Enter the code displayed for your Straato account in the Google Authenticator app into the field below.</div>
            <Form {...formProps} isLoading={isLoading} errorMessage={errorMessage} />
          </div>
        );
    }
  }

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <a href="/">
          <img className="mx-auto h-10 w-auto" src={logo} alt="Straato" />
        </a>
        <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight !${text.grey}`}>MFA Setup</h2>
      </div>
      {getPageView()}
      <div className={`inline-flex w-full justify-center ${standard.mt}`}>
        {page > 0 ? (
          <button onClick={() => setPage(page - 1)} className={`${bg.darkGrey} hover:${bg.grey} ${text.black} font-bold py-2 px-4 rounded-l`}>
            Prev
          </button>
        ) : (
          ""
        )}
        <div className="w-full"></div>
        {page < maxPage ? (
          <button onClick={() => setPage(page + 1)} className={`${bg.darkGrey} hover:${bg.grey} ${text.black} font-bold py-2 px-4 rounded-r`}>
            Next
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );

};

function MFALogin(props) {

  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rememberDevice, setRememberDevice] = useState(false);

  const { confirmSignIn } = useContext(AccountContext);
  const navigate = useNavigate();

  const signin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    confirmSignIn(props.user, code)
      .then(() => {
        setIsLoading(false);
        if (rememberDevice) Auth.rememberDevice();
        navigate('/d/' + default_page);
      })
      .catch((err) => {
        setErrorMessage(getAmplifyErrorMessage(err));
        setIsLoading(false);
      });
  }

  const formProps = {
    submit: 'Sign in',
    callback: signin,
    schema: [
      {
        title: "MFA Code",
        id: "code",
        name: "code",
        type: "password",
        setter: setCode
      },
    ]
  }

  return (
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="Straato"
            />
          </a>
          <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight !${text.black}`}>
            MFA Log In
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form {...formProps} isLoading={isLoading} errorMessage={errorMessage}>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  onChange={(e) => setRememberDevice(e.target.value)}
                  id="terms"
                  name="terms"
                  type="checkbox"
                  className={`h-4 w-4 rounded ${border.grey} ${text.blue} focus:${ring.blue}`}
                />
              </div>
              <div className="text-sm leading-6">
                <p className={`!${text.darkGrey}`}>Remember my device to skip multi-factor authentication?</p>
              </div>
            </div>
          </Form>
          <p className={`mt-10 text-center text-sm !${text.darkGrey}`}>
            Don't have MFA setup? Contact{' '}
            <a href="mailto:support@straato.com" className={`font-semibold leading-6 ${text.blue} hover:${text.lightBlue}`}>
              Straato Support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

function MFA() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [cognitoUser, setCognitoUser] = useState(null);
  const [email, setEmail] = useState(null);

  const navigate = useNavigate('/login');

  useEffect(() => {
    try {
      const pool = new CognitoUserPool({
        UserPoolId: atob(searchParams.get('userpool')),
        ClientId: atob(searchParams.get('clientid')),
        endpoint: atob(searchParams.get('endpoint')),
        Storage: window.localStorage,
      });

      let cognitoUser = new CognitoUser({
        Username: atob(searchParams.get('username')),
        Pool: pool,
        Storage: window.localStorage,
      });
      cognitoUser.Session = atob(searchParams.get('session'));

      setCognitoUser(cognitoUser);
    } catch (err) { }

    if (searchParams.get('email') !== null) {
      setEmail(atob(searchParams.get('email')));
    }

  }, [searchParams]);

  const getView = () => {
    if (atob(searchParams.get('challengeName')) === "MFA_SETUP") {
      return <SetupMFA user={cognitoUser} email={email} />
    } else if (atob(searchParams.get('challengeName')) === "SOFTWARE_TOKEN_MFA") {
      return <MFALogin user={cognitoUser} />
    } else {
      navigate('/d/trade')
    }
  }

  return (
    <FormGradientView>
      {getView()}
    </FormGradientView>
  );
}

export default MFA;