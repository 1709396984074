export const tradingDash = [
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
]

export const sandbox = [
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
];

export const offerQueue = [
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
]

export const referrals = [
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
]

export const notifications = [
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
]

export const history = [
    {
        content: (
            <div>
                <h4 className="text-left">Title</h4>
                <h5 className="text-left">Lorem ipsum dolor sit amet.</h5>
            </div>
        ),
        target: 'body'
    },
]