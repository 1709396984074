import { useEffect, useRef } from "react"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import SandboxLeaderboard from "./components/Leaderboard"
import MainPanel from "./components/MainPanel"
import { sandbox } from "../tutorial/config"

export default function Sandbox(){

    const { width } = useWindowDimensions()
    const sandboxRef = useRef(null)

    useEffect(() => {
        const targets = document.querySelectorAll('.sandbox-target');
        targets.forEach((target, index) => {
            if (target && sandbox[index]) {
                sandbox[index].target = target;
            }
        });
    }, []);

    return width > 768 ?
    <>
    <div className={`border-box h-screen w-full p-4 overflow-y-scroll no-scrollbar`}>
        <div className="grid grid-flow-col grid-rows-5 grid-cols-7 gap-4 h-full">
            <div className="row-span-full col-span-4 sandbox-target" ref={sandboxRef}>
                <MainPanel/>
            </div>
            <div className="row-span-full col-span-3 sandbox-target">
                <SandboxLeaderboard/>
            </div>
        </div>
    </div>
    </>
    :
    <>
    <div className={`flex flex-col w-full min-h-screen p-4 flex-no-wrap overflow-y-scroll no-scrollbar`}>
        <div className="grid grid-cols-1 gap-4">
            <div className="sandbox-target" ref={sandboxRef}>
                <MainPanel />
            </div>
            <div style={{height: 600}} className="sandbox-target">
                <SandboxLeaderboard/>
            </div>
        </div>
    </div>
    </>
}