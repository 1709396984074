const quantile = (arr, q) => {
    arr.sort(function(a, b){return a-b});
    const pos = (arr.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (arr[base + 1] !== undefined) {
        return arr[base] + rest * (arr[base + 1] - arr[base]);
    } else {
        return arr[base];
    }
};

const q25 = arr => quantile(arr, .25);
const q50 = arr => quantile(arr, .50);
const q75 = arr => quantile(arr, .75);

function formatNumber(theNumber, decimals=true)
{
    if(theNumber == null){
        return null;
    }
    else{
        if(decimals){
            return Number(theNumber).toFixed(2).toLocaleString();
        }
        else{
            return Number(theNumber).toFixed(0).toLocaleString();
        }
    }
}

function percentChange(input, output){
    return divide(change(input, output), input) * 100.00;
}

function change(input, output){
    return output - input;
}

function divide(numerator, denominator){
    return denominator !== 0.00 ? numerator / denominator : 0.00;
}

function minimum(val1, val2){
    if(Math.abs(val2) < Math.abs(val1)){
        return val2;
    }else if(Math.abs(val1) < Math.abs(val2)){
        return val1;
    }else{
        return val1;
    }
}

function maximum(val1, val2){
    if(Math.abs(val2) < Math.abs(val1)){
        return val1;
    }else if(Math.abs(val1) < Math.abs(val2)){
        return val2;
    }else{
        return val1;
    }
}

function notZero(val){
    return val !== undefined && val !== 0 && val !== 0.0 && val !== 0.00;
}

function getMax(arr, prop, ind) {
    ind = ind ? ind : 0;
    let max = 0;
    for (var i=0 ; i<arr.length ; i++) {
        if ((Array.isArray(arr[i][prop]) ? arr[i][prop][ind] : arr[i][prop]) > max)
            max = Array.isArray(arr[i][prop]) ? arr[i][prop][ind] : arr[i][prop];
    }
    return max;
}

function getMin(arr, prop, ind) {
    ind = ind ? ind : 0;
    let min = Number.MAX_SAFE_INTEGER;
    for (var i=0 ; i<arr.length ; i++) {
        if ((Array.isArray(arr[i][prop]) ? arr[i][prop][ind] : arr[i][prop]) < min)
            min = Array.isArray(arr[i][prop]) ? arr[i][prop][ind] : arr[i][prop];
    }
    return min;
}

function divideByUntil(base_val, div, lim){
    if(div === 0 || lim === 0) return 0
    
    let val = base_val
    while(val > lim){
        val = val / div
    }

    return val
}

function findClosest(arr, target){
    let bottom = 0
    let top = 0
    for(var i = 0; i < arr.length; i++){
        top = arr[i];
        if(arr[i] > target) break;
        bottom = arr[i];
    }
    if(Math.abs(top - target) < Math.abs(target - bottom)) return top
    return bottom
}

function partition(low, high, step, ticks) {
    let mid = []
    for(var i = low; i <= high; i+=step){
        mid.push(i);
    }
    mid = mid.map(function(each_element){
        return Number(each_element.toFixed(2));
    });

    let diff = Math.ceil(Number(((high - low)/ticks).toFixed(2))/step);

    if(diff <= 0) return [undefined, undefined]
    
    let ret = []
    for(var p = 0; p < mid.length; p+=diff){
        ret.push(low + p*step);
    }
    ret.push(ret[ret.length-1] + diff*step);
    
    return [ret, ret[ret.length - 1]]
}

module.exports = {
    getQ1: q25,
    getQ2: q50,
    getQ3: q75,
    formatNumber: formatNumber,
    percentChange: percentChange,
    change: change,
    divide: divide,
    minimum: minimum,
    maximum: maximum,
    notZero: notZero,
    getMax: getMax,
    getMin: getMin,
    divideByUntil: divideByUntil,
    partition: partition
}
