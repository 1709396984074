export default [
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
    {
        'publisher': 'Medium',
        'date': '1/23/23',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida.'
    },
    {
        'publisher': 'Yahoo',
        'date': '11/11/11',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt c'
    },
    {
        'publisher': 'TowardsDataScience',
        'date': '12/22/22',
        'title': 'Lorem ipsum dolor sit '
    },
    {
        'publisher': 'CNN',
        'date': '4/1/20',
        'title': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt cursus massa, et tincidunt ante fermentum ac. In efficitur magna eget augue consequat, ut euismod turpis gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non consectetur dui. Curabitur a elit tempor, viverra felis sit amet, scelerisque magna. Proin blandit lorem vitae neque euismod, non pretium ex vulputate. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi nec suscipit risus.'
    },
]