import Marquee from "react-fast-marquee";

import BusinessInsider from '../assets/businessinsider.png';
import YahooFinance from '../assets/yahoofinance.png';
import TimeBusiness from '../assets/tbn.png';
import MarketWatch from '../assets/marketwatch.svg';
import Medium from '../assets/medium.png';
import AssociatedPress from '../assets/ap.png';
import ShowOnScroll from "./ShowOnScroll";
import { text } from "../../../config";

export default function Featured(){
    return (
        <ShowOnScroll>
        <div className="flex flex-col w-full py-20 items-center justify-center">
            <h4 className={`${text.desc}`}>As featured in</h4>
            <Marquee className="mt-8 w-full">
                <div className="px-12">
                    <img alt="Yahoo Finance" src={YahooFinance} className="h-10 aspect-auto"/>
                </div>
                <div className="px-12">
                    <img alt="Time Business News" src={TimeBusiness} className="h-10 aspect-auto"/>
                </div>
                <div className="px-12">
                    <img alt="Marketwatch" src={MarketWatch} className="h-10 aspect-auto"/>
                </div>
                <div className="px-12">
                    <img alt="US Business Insider" src={BusinessInsider} className="h-10 aspect-auto"/>
                </div>
                <div className="px-12">
                    <img alt="Medium" src={Medium} className="h-10 aspect-auto"/>
                </div>
                <div className="px-12">
                    <img alt="Associated Press" src={AssociatedPress} className="h-10 aspect-auto"/>
                </div>
            </Marquee>
        </div>
        </ShowOnScroll>
    )
}