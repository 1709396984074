import ShowOnScroll from "./ShowOnScroll";
import { useRef } from "react";
import useIsVisible from "../../../hooks/useIsVisible";
import { text } from "../../../config";

export default function SideBySide(props){

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <ShowOnScroll>
        <div ref={ref} className={`flex ${props.textRight ? "flex-row" : "flex-row-reverse"} ${props.wrapBack ? "flex-wrap" : "flex-wrap-reverse"} w-full p-6 md:p-20 items-center justify-center`}>
            <div className={`flex w-full mt-0 md:w-7/12 justify-center items-center ${props.textRight ? "pr-0 sm:pr-20 md:pr-40" : "pl-0 sm:pl-20 md:pl-40"}
            ${isVisible ? `animate-slidein${props.textRight ? "": "right"} opacity-100` : "opacity-0"}`} style={{height: props.imgHeight}}>
                <img src={props.img_source} className="max-h-full aspect-auto"/>
            </div>
            <div className="flex flex-col w-full md:w-5/12 text-center md:text-left">
                <h2 className={`${text.alwaysblack} font-bold`}>{props.title}</h2>
                <p className={`${text.desc} font-light mt-10 leading-8`}>{props.text}</p>
            </div>
        </div>
        </ShowOnScroll>
    )

}