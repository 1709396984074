import { useState, useRef, useEffect, forwardRef, memo, useContext } from 'react';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import useDelayUnmount from '../../../../hooks/useDelayUnmount';
import Cloud from '../../../../assets/images/symbol_logo.png';
import useIsVisible from '../../../../hooks/useIsVisible';
import { border, bg, isSandbox, standard, text } from '../../../../config';
import { AccountContext } from '../../../../state/Account';
import getQueueData from '../../../../api/endpoints/queue/getQueueData';
import { QueueContext } from '../../../../state/Queue';
import useUpdateOnPropsChange from '../../../../hooks/useUpdateOnPropsChange';
import Widget from '../../general/layout/Widget';
import { ReactComponent as UpCaret } from '../../sidebar/assets/upcaret.svg'
import { SystemContext } from '../../../../state/System';
import PauseView from '../../general/PauseView';

const Slot = memo(forwardRef(function Slot(props, ref) {

    const [isMounted, setIsMounted] = useState(true);
    const shouldRenderChild = useDelayUnmount(isMounted, 1000);

    useEffect(() => {
        //this makes sure component is re-mounted every time it is re-rendered
        setIsMounted(true);
    });

    useEffect(() => {
        setIsMounted(false);
    }, [props.data])

    return shouldRenderChild ? <div key={props.id} className={`box-border snap-always snap-end h-full ${isMounted ? "motion-safe:animate-queuemove" : "motion-safe:animate-queuemoveout"} px-4`}>
        <div ref={ref} className={`h-full filter bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 ${border.blue} ${standard.rounded} p-0.5 items-center`} style={{ width: props.width }}>
            <div className={`flex flex-col h-full bg-white ${border.blue} border-0 ${standard.rounded} p-4 justify-between items-center ${bg.white}`}>
                <div className="flex flex-col items-center justify-center w-full">
                    <img src={Cloud} className="w-1/3 aspect-auto mb-2 select-none" />
                    {isSandbox ? <small className={`font-light whitespace-nowrap ${text.black}`}>Sandbox Mode</small> : <small className={`font-light whitespace-nowrap ${text.black}`}>Listed by {props.seller}</small>}
                </div>
                <div className="flex flex-col items-center">
                    <h2 className={`whitespace-nowrap m-0 ${text.black}`}>{props.price}</h2>
                    <small className={`font-light whitespace-nowrap m-0 p-0 ${text.black} font-semibold`}><span className={`${text.blue}`}>{props.num}</span> Straato</small>
                    <br className='select-none' />
                    <small className={`font-light whitespace-nowrap m-0 p-0 ${text.black}`}>Listed: {props.listed_time}</small>
                    <small className={`font-light whitespace-nowrap m-0 p-0 ${text.black}`}>On queue: {props.adjusted_time_on_seconds} seconds</small>
                </div>
            </div>
        </div>
    </div> : <></>
}));

export default function Queue(props) {

    useUpdateOnPropsChange(props);

    const { width } = useWindowDimensions();
    const { darkMode } = useContext(SystemContext)

    const mainRef = useRef(null);
    const arrowRef = useRef(null);
    const scrollRef = useRef(null);

    const lRef = useRef(null);
    const fRef = useRef(null);

    const firstVisible = useIsVisible(fRef);
    const lastVisible = useIsVisible(lRef);

    const [mainHeight, setMainHeight] = useState(0);
    const [arrowHeight, setArrowHeight] = useState(0);

    const { queueData, setQueueData, setMode, mode, queueStats } = useContext(QueueContext);
    const { user } = useContext(AccountContext);

    useEffect(() => { //need this useEffect to fix the not rendering on first load bug...not entirely sure why though haha!
        setMainHeight(mainRef.current.clientHeight);
        setArrowHeight(arrowRef.current.clientHeight);
    });

    useEffect(() => {
        fetchQueueData();
    }, []);

    const fetchQueueData = async () => {
        getQueueData(user, setMode).then((offers) => {
            setQueueData(offers);
            props.setQueueLoading(false);
        });
    }

    return (
        <Widget ref={mainRef} className="pt-4" style={{ maxHeight: 300 }}>
            <div className='flex flex-row'>
                <div className='flex items-center justify-center'>
                    {firstVisible || mode === 'pause' ? (<></>) : (
                        <div onClick={() => scrollRef.current.scrollBy({ left: -((mainHeight - arrowHeight)), behavior: "smooth" })} className="m-0 p-0 cursor-pointer select-none ml-2">
                            <UpCaret fill={darkMode ? 'white' : 'black'} style={{ transform: 'rotate(270deg)', width: "1.5rem" }} />
                        </div>
                    )}
                </div>
                <div ref={scrollRef} className="box-border flex flex-row w-full overflow-x-scroll no-scrollbar fade-sideways" style={{ height: mainHeight - arrowHeight }}>
                    {mode === 'pause' ? <PauseView time={10000000} /> :
                        queueData.map((group, i) => {
                            return <Slot ref={i === 0 ? fRef : i === (queueData.length - 1) ? lRef : null}
                                width={mainHeight - arrowHeight}
                                data={queueData} index={i} {...group} />
                        })}
                </div>
                <div className='flex items-center justify-center'>
                    {lastVisible || mode === 'pause' ? (<></>) : (
                        <div onClick={() => scrollRef.current.scrollBy({ left: (mainHeight - arrowHeight), behavior: "smooth" })} className="m-0 p-0 cursor-pointer select-none mr-2">
                            <UpCaret fill={darkMode ? 'white' : 'black'} style={{ transform: 'rotate(90deg)', width: "1.5rem" }} />
                        </div>
                    )}
                </div>
            </div>
            <div ref={arrowRef} className="flex w-full flex-row justify-center px-4 pt-2 pb-6">
                {mode === 'pause' ? <></> :
                    <small className="font-light whitespace-nowrap text-center">Below queue updates every 3 seconds. Queue has {queueStats.queue_len} offers.</small>
                }
            </div>
        </Widget>
    );
}