import Countdown from 'react-countdown';
import { bg, text } from '../../../config';
import Widget from './layout/Widget';

export default function PauseView(props) {

  const renderer = ({ hours, minutes, seconds }) => {
    const formatTime = (time) => {
      return time < 10 ? `0${time}` : time;
    };
  
    return (
      <div className='flex flex-row w-full justify-center items-center'>
        <div className={`flex flex-col items-center justify-center rounded p-4 ${bg.blue} h-12 w-16`}>
          <h2 className='text-white'>{formatTime(hours)}</h2>
        </div>
        <h2 className={`${text.blue} mx-2`}>:</h2>
        <div className={`flex flex-col items-center justify-center rounded p-4 ${bg.blue} h-12 w-16`}>
          <h2 className='text-white'>{formatTime(minutes)}</h2>
        </div>
        <h2 className={`${text.blue} mx-2`}>:</h2>
        <div className={`flex flex-col items-center justify-center rounded p-4 ${bg.blue} h-12 w-16`}>
          <h2 className='text-white'>{formatTime(seconds)}</h2>
        </div>
      </div>
    );
  };

  return (
    <div className="border-box w-full items-center justify-center">
      <Widget className="flex flex-col items-center justify-center p-8">
        <p class="font-light text-gray-500 md:text-lg dark:text-gray-400 pb-4">Trading on the Straato Market is currently paused.</p>
        <p className={`${text.blue} font-semibold md:text-2xl`}>Trading will resume in:</p>
        <div className="flex flex-col w-full justify-center items-center py-2">
          <Countdown zeroPadTime={2} date={Date.now() + props.time} renderer={renderer} />
        </div>
        <div className="mt-4 text-center">
          <p class="font-light text-gray-500 md:text-lg dark:text-gray-400">In the meantime, join the community on the <a href="https://discord.gg/BrWFGP7CTs" className={`${text.blue} hover:${text.lightBlue} font-semibold underline`}>Straato Discord</a> to stay up-to-date on announcements and more.</p>
        </div>
      </Widget>
    </div>
  );
}