import ReferralImage from '../../../../assets/animations/ReferralInfo_Animation.gif';
import { bg, standard } from '../../../../config';
import useUpdateOnPropsChange from '../../../../hooks/useUpdateOnPropsChange';
import Widget from '../../general/layout/Widget';
import MyReferrals from './MyReferrals';
import { useState, useEffect, useRef } from 'react';

export default function MainPanel(props) {

    useUpdateOnPropsChange(props);

    const [copied, setCopied] = useState(false);
    const widgetRef = useRef(null);

    useEffect(() => {
        if (copied) {
            new Promise(r => setTimeout(r, 3000)).then(() => {
                setCopied(false);
            });
        }
    }, [copied]);

    return (
        <Widget className={`pt-6 px-6`}>
            <div className="flex flex-row justify-between flex-wrap">
                <div className="flex flex-col w-full sm:w-8/12">
                    <h2 className='select-none'>Referrals</h2>
                    <p className={`${standard.mt}`}>Refer your friends to join the Straato Market by sharing your referral QR code and link!</p>
                    <p className={`${standard.mt}`}>Earn 5 Straato for every referral and climb the leaderboard for extra rewards!</p>
                </div>
                <div className="flex flex-col items-center justify-center p-2 w-0 sm:w-0 md:w-3/12 select-none">
                    <img src={ReferralImage} className="max-h-full aspect-auto" />
                </div>
            </div>

            <h4 className="mb-2 mt-6 select-none">Referral Link</h4>
            <div className={`flex flex-row w-full flex-no-wrap ${bg.offwhite} justify-between items-center ${standard.rounded}`}>
                <div className="p-4 w-9/12 overflow-x-scroll no-scrollbar mr-2">
                    <p>{props.link}</p>
                </div>
                <div className={`flex items-center justify-center cursor-pointer p-4 px-6 ${copied ? bg.green : bg.blue} ${standard.rounded} w-3/12 select-none`}
                    onClick={() => {
                        navigator.clipboard.writeText(props.link);
                        setCopied(true);
                    }}>
                    <h4 className="text-white">{copied ? "Copied!" : "Copy"}</h4>
                </div>
            </div>
            <MyReferrals />
        </Widget>
    );
}