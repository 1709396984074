import { getSafeValue } from "../../util/json";

class notification {
    constructor(input){
        this.title = getSafeValue(input, "title", "string");
        this.icon_type = getSafeValue(input, "icon_type", "string");
        this.message = getSafeValue(input, "message", "string");
        this.unseen = getSafeValue(input, "unseen", "boolean");
        this.time = getSafeValue(input, "time", "datetime");
        this.broadcast = getSafeValue(input, "broadcast", "boolean");
        this.sandbox = getSafeValue(input, "sandbox", "boolean");
    }
}

export default notification;