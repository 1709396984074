import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { bg, border, ring, text } from '../../../../../config'

export default function ChartToggleView(props) {

    const { width } = useWindowDimensions();
  
    const toggles = {'1c': 'This Cycle','3c': '3 Cycles','5c': '5 Cycles', '10c': '10 Cycles', '25c': '25 Cycles'}
  
    const showToggles = () => {
      if (width > 768) {
        return Object.keys(toggles).map((key, i) => {
          return (
            <div onClick={() => props.setUnit(key)} className={`flex flex-row items-center justify-center py-0 px-6 mx-2 rounded cursor-pointer ${props.unit === key ? bg.blue : bg.offwhite} select-none`}>
              <small className={`tiny ${props.unit === key ? 'text-white' : text.black} whitespace-nowrap`}>{toggles[key]}</small>
            </div>
          );
        })
      } else {
        return (
          <select value={props.unit} onChange={e => {props.setUnit(e.target.value)}} className={`border ${text.black} tiny focus:${ring.blue} focus:${border.blue} rounded py-0`}>
            {Object.keys(toggles).map((key, i) =>
              <option value={key}><small className="tiny">{toggles[key]}</small></option>
            )}
  
          </select>
        );
      }
    }
  
    return (
      <div className="flex flex-row w-full justify-between items-start">
        {props.children}
        <div className="px-4 pt-4 flex flex-row">
          <div className="flex flex-row items-center">
            {showToggles()}
          </div>
        </div>
      </div>
    );
  
  }