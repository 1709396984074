import General from "./components/General";
import Payment from "./components/Payment";
import Leaderboard from "./components/Leaderboard";
import DashboardPage from "../general/layout/DashboardPage";
import LayoutManager from "../general/layout/LayoutManager";

export default function Account () {
    
    let config = {'rows': 'grid-rows-5', 'cols': 'grids-cols-7', 'flow': 'grid-flow-col',
        views: [
            {'view': <General/>, 'row-span': 'row-span-4', 'col-span': 'col-span-4'},
            {'view': <Payment/>, 'row-span': 'row-span-1', 'col-span': 'col-span-4'},
            {'view': <Leaderboard/>, 'row-span': 'row-span-full', 'col-span': 'col-span-3', 'height': 600}
        ]
    }

    return <DashboardPage visible={true}><LayoutManager config={config}/></DashboardPage>
}