import Marquee from "react-fast-marquee";
import Feature from "../../general/Feature";
import StatsModal from "./StatsModal";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useEffect, useContext, useRef } from 'react';
import getQueueStats from "../../../../api/endpoints/queue/getQueueStats";
import { AccountContext } from '../../../../state/Account';
import { QueueContext } from "../../../../state/Queue";
import { formatAxisLabels } from "../../../../util/labels";
import useUpdateOnPropsChange from "../../../../hooks/useUpdateOnPropsChange";
import Widget from "../../general/layout/Widget";
import { bg, text } from "../../../../config";
import { getSafeValue } from "../../../../util/json";

export default function TopBar(props) {

    useUpdateOnPropsChange(props);

    const { width } = useWindowDimensions();

    const { queueStats, setQueueStats, setMode } = useContext(QueueContext);

    const { user } = useContext(AccountContext);

    useEffect(() => {
        getQueueStats(user, setMode).then((stats) => {
            setQueueStats(stats);
            props.setTopBarLoading(false);
        }).catch((err) => {});
    }, []);

    const mappings = {
        "queue_len": ["QLEN", "Number of Straato Listed on Queue"],
        "avg_time": ["AVGTQ", "Average Time in Queue"],
        "sd_time": ["SDTQ", "Standard Deviation of Time in Queue"],
        "last_time": ["TSLL", "Time Since Last Listing"],
        "avg_price": ["AVGP", "Average Price"],
        "sd_price": ["SDP", "Price Standard Deviation"],
        "max_price": ["MAXP", "Max Price"],
        "med_price": ["MEDP", "Median Price"],
        "med_time": ["MEDTQ", "Median Time in Queue"],
        "max_per_buy": ["MAXBUY", "Max # Buy"],
        "max_per_sell": ["MAXSELL", "Max # Sell"],
        "max_per_price": ["MAXPP", "Max Per Price"],
        "avg_time_unit": "days",
        "sd_time_unit": "days",
        "last_time_unit": "seconds",
        "med_time_unit": "days",
        "min_price_jump": ["JUMP", "Min Price Jump"]
    }

    const getStatsView = (modal) => {
        let fdat = Object.keys(mappings).filter((key) => !key.endsWith("unit"))
        
        return fdat.map((key, i) => {
            let value = getSafeValue(queueStats, key, "float", 2, -1);

            if(value === -1){
                value = "-"
            }else{
            value = Math.round(value * 100) / 100
            if(key.endsWith("price") && key !== "max_per_price"){
                value = formatAxisLabels(value, true, false, false);
            }
            if(key.endsWith("buy") || key.endsWith("sell") || key === "max_per_price"){
                value += " Straato"
            }
            if(key === "avg_time"){
                value += " " +  queueStats["avg_time_unit"]
            }else if(key === "last_time"){
                value += " " +  queueStats["last_time_unit"]
            }else if(key === "sd_time"){
                value += " " + queueStats["sd_time_unit"]
            }else if(key === "med_time"){
                value += " " + queueStats["med_time_unit"]
            }}
            if(modal){
                return (
                    <>
                    <div className="flex flex-row w-full my-1 justify-between">
                        <small className="font-semibold">{mappings[key][1] + " [" + mappings[key][0] + "]"}</small>
                        <p className={`${text.blue} font-semibold`}>{value}</p>
                    </div>
                    {i !== (fdat.length - 1) ? <hr className="w-full"/> : <></>}
                    </>
                )
            }else{
                return <Feature label={mappings[key][0]} value={value} small_label={mappings[key][1]}/>
            }
        });
    }

    return (
        <Widget className="py-6 pl-6 queue-target" ref={props.refer}>
            <div className="flex flex-row">
            <div className="flex flex-col items-start pr-6 flex-no-wrap select-none">
                <h2 className="whitespace-nowrap">Offer Queue</h2>
                <StatsModal>
                    {getStatsView(true)}
                </StatsModal>
            </div>
            {width > 480 ? 
                <>
                    <div className={`inline-block h-full py-6 min-h-[1em] w-0.5 self-stretch ${bg.offwhite}`}>
                    </div>
                    <Marquee>
                        {getStatsView(false)}
                    </Marquee>
                </> 
                : <></>
            }
            </div>
        </Widget>
    );
}