import React from "react";
import { PopupButton } from "react-calendly";
import { text, standard, bg, outline } from "../../../config";
import { useEffect } from "react";

const EventSchedule = (props) => {
  return (
    <div className="mb-24">
    <div class="mb-6">
    <h2 className={`${text.alwaysblack} font-bold`}>{props.title}</h2>
    <p className={`${text.desc} font-light mt-4 leading-8`}>{props.text}</p>
    </div>
    <div className={`flex w-fit justify-center ${standard.formRounded} ${bg.blue} px-12 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:${bg.lightBlue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}>
    <PopupButton
        url={props.url}
        rootElement={document.getElementById("root")}
        text={props.url_text}
      />
    </div>
</div>
  );
};

export default function Contact(){

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '44344611',
                    formId: 'e56b51ce-a7ef-454e-a7e0-90d314622152',
                    target: '#contactForm'
                })
            }
        });
    }, []);

    return (
        <div className="flex flex-row w-full p-4 md:p-16 mt-24 flex-wrap">
        <div class="p-6 md:p-16 w-full md:w-1/2">
            <h2 className={`${text.alwaysblack} font-bold`}>Contact us</h2>
            <p className={`${text.desc} mt-4 leading-8 font-light`}>Got a technical issue? Want to send feedback about a beta feature? Want to learn more about Straato's system? Let us know!</p>
            <div class="space-y-8 mt-8">
                    <div id="contactForm"></div>
            </div>
        </div>
        <div className="p-6 md:p-16 w-full md:w-1/2">
            <EventSchedule 
                title={"Sign up for a trading session"}
                text={"In preparation for our full beta launch, we are hosting live trading sessions. We need your advice to help Straato accomplish its mission of leveling the playing field for retail day traders."}
                url={"https://calendly.com/straato/straato-trading-session"}
                url_text={"See upcoming trading sessions."}
            />
            <EventSchedule
                title="Book a meeting with our team"
                text={"We want to share our passion for trading and discuss any questions you might have about Straato's system. Check our availability here and schedule a call with one of our founders. We can't wait to meet you!"}
                url={"https://calendly.com/straato/30min"}
                url_text={"Schedule a time."}
            />
        </div>
        </div>
    );
}