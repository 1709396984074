import { useState, useEffect, useContext } from "react";
import getQueueGraph from "../../../../api/endpoints/queue/getQueueGraph";
import { AccountContext } from "../../../../state/Account";
import Chart from "react-apexcharts";
import React from 'react';
import { formatAxisLabels } from "../../../../util/labels";
import { SystemContext } from "../../../../state/System";
import { QueueContext } from "../../../../state/Queue";
import { divideByUntil, getMax, getMin, partition } from "../../../../util/math";
import useUpdateOnPropsChange from "../../../../hooks/useUpdateOnPropsChange";
import { bg, standard } from "../../../../config";
import FilterDropdown from "../../actions/components/FilterDropdown";

function AreaGraph (props) {

  const { darkMode } = useContext(SystemContext);
  const { queueStats } = useContext(QueueContext);

  let series =  [{
          name: props.group,
          data: props.data
      }];

  let min = props.id === 'price' ? getMin(props.data, "x") : 0;
  let parts = props.id === 'price' ? partition(min, getMax(props.data, "x"), queueStats.min_price_jump, 6) : [undefined, undefined];

  let options = {
        chart: {
          type: 'bar',
          zoom: {
            enabled: false
          },
          toolbar: {
              show: false
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: props.title,
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '20px',
            fontWeight:  '600',
            fontFamily:  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            color:  darkMode ? '#ffffff' : '#000000'
          },
      },
      fill: {
        colors: ['#3B82F6'],
        opacity: 1,
        type: 'solid',
      },
        xaxis: {
          title: {
            text: props.xLab,
            style: {
               color: '#90A4AE',
               fontWeight: 400,
               fontFamily:  "Segoe UI",
            }
          },
          axisTicks: {
            show: false,
            color: '#90A4AE'
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: function (value) {
              return formatAxisLabels(value, props.xMoneySign);
            },
            style: {
              colors: '#90A4AE',
            }
          },
          overwriteCategories: parts[0],
          min: min,
          max: parts[1],
          tooltip: { enabled: false }
        },
        yaxis: {
          title: {
            text: props.yLab,
            style: {
              color: '#90A4AE',
              fontWeight: 400,
              fontFamily:  "Segoe UI",
           }
          },
          min: 0, 
          max: getMax(props.data, "y") + 1,
          axisTicks: {
            show: false,
          },
          labels: {
              formatter: function (value) {
                return formatAxisLabels(value, props.yMoneySign, false, true);
              },
              style: {
                colors: ['#90A4AE'],
              }
            },
        },
        grid: {
          show: true,
          borderColor: '#90A4AE',
          yaxis: {
              lines: {
                  show: true
              }
          }
        },
        noData: {
          text: "The offer queue is empty. No distribution data available.",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: -20,
          style: {
            color: darkMode ? "#ffffff" : "#000000",
            fontSize: '16px',
            fontFamily: "Segoe UI"
          }
        }
      }

      return (
      <Chart id={props.id} options={options} series={series} type="bar" height={props.height} />
    );
}

export default function Distributions(props) {

  useUpdateOnPropsChange(props);

  //need to add the first price (minimum price floor)
  let options = [
      {
          'id': 'price',
          'label': 'Queue Volume vs. Price Distribution',
          'type': 'line',
          'xLab': 'Listed Price ($)',
          'yLab': 'Number of Straato',
          'xKey': 'x',
          'yKey': 'y',
          'yMoneySign': false,
          'xMoneySign': true
      },
      {
          'id': 'time',
          'label': 'Queue Volume vs. Time Distribution',
          'type': 'line',
          'yLab': 'Number of Straato',
          'xLab': 'Time b/w Offers (Seconds)',
          'xKey': 'x',
          'yKey': 'y',
          'yMoneySign': false,
          'xMoneySign': false
      }
  ]

  const { user } = useContext(AccountContext);

  const { setWSGraphType, setGraphDataSetter, mode, setMode } = useContext(QueueContext);

  const [timeData, setTimeData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [configIndex, setConfigIndex] = useState(0);


  const updateData = (setData) => {
      getQueueGraph(user, options[configIndex].id, setMode).then((data) => {
          setData(data);
          props.setDistLoading(false);
      }).catch(() => {
        props.setDistLoading(false);
      });
  }

  useEffect(() => {
      if(options[configIndex].id === 'time' && timeData.length === 0){
          setWSGraphType('time');
          setGraphDataSetter(() => setTimeData);
          updateData(setTimeData);
      }else if(options[configIndex].id === 'price' && priceData.length === 0){
          setWSGraphType('price');
          setGraphDataSetter(() => setPriceData);
          updateData(setPriceData);
      }
  }, [configIndex]);


  const handleGraphChange = () => {
    setConfigIndex(configIndex === (options.length - 1) ? 0 : configIndex + 1)
  }

  return (
    <div className={`relative flex box-border flex-col h-full w-full ${bg.white} ${standard.rounded} overflow-hidden cols-auto relative`}>
      <div className="p-4 h-full w-full">
        <AreaGraph {...options[configIndex]} data={options[configIndex].id === 'time' ? timeData : priceData} height={"100%"} title={options[configIndex].label} />
        <div className={`absolute right-5 top-3 z-20 font-light cursor-pointer select-none`}>
          <FilterDropdown statuses={['queue_vs_price', 'queue_vs_time']} handleFilterChange={handleGraphChange} includeLabel={false} label={'Queue Vol. vs Price Dist.'} />
        </div>
      </div>
    </div>
  );
}

//{!props.isDistLoading && (options[configIndex].id === 'time' ? timeData.length === 0 : priceData.length === 0) ? <p className="absolute w-full h-full z-10 flex flex-row items-center justify-center backdrop-blur-md ">Queue distribution data not available.</p> : <></>}