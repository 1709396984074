import API from "../../api";
import notification from "../../datatypes/notification";

const getNotifications = (user, offset) => {
    return new API(user).getRequest('/getNotifications?offset=' + parseInt(offset)).then(json => {
        let nots = []
        for (let i = 0; i < json.notifications.length; i++) {
            nots.push(new notification(json.notifications[i]));
          }
        return nots;
    });
}

export default getNotifications;