import { useContext, useEffect, useState } from 'react';
import getMyReferrals from "../../../../api/endpoints/referrals/getMyReferrals";
import { AccountContext } from "../../../../state/Account";
import { bg, border, standard } from "../../../../config";
import Referral from "./Referral";
import Widget from "../../general/layout/Widget";
import { getSafeValue } from '../../../../util/json';

export default function MyReferrals(props) {

    const [loading, setLoading] = useState(true);
    const { user } = useContext(AccountContext);
    const [data, setData] = useState({})

    useEffect(() => {
        if(loading){
        getMyReferrals(user, 0).then((refs) => {
            setData(refs);
            setLoading(false);
        }).catch((err) => {
            setData({});
            setLoading(false);
        });}
    }, [loading]);

    return (
        <>
            <Widget className="pt-6 overflow-hidden">
                <div className="flex flex-row justify-between items-center w-full select-none">
                    <div className="flex flex-row items-center justify-start">
                        <h4 className="pr-4">My Referrals</h4>
                    </div>
                    <h4 className="pl-4">{getSafeValue(data, "count", "int")}</h4>
                </div>
                <hr className={`h-px ${standard.mt} ${bg.blue} ${bg.darkBlue} border-1 ${border.blue}`} />
                <div className="overflow-y-scroll no-scrollbar" style={{ maxHeight: 500 }}>
                    <ul>
                        {getSafeValue(data, "referrals", "list").map((referral, index) => (
                            <Referral key={index} referral={referral} index={index} setLoading={setLoading} user={user}/>
                        ))}
                        {(!loading && getSafeValue(data, "referrals", "list").length === 0) ? (
                            <div className="flex flex-row w-full items-center justify-center mt-10 px-20 mb-10">
                                <p className="text-center">You have no referrals at the moment. Refer your friends to earn free Straato and other awesome prizes!</p>
                            </div>) : <></>
                        }
                    </ul>
                </div>
            </Widget>
        </>
    );
}