import { useState, useEffect, useContext } from 'react';
import { AccountContext } from "../../../../state/Account";
import getDashboardChart from "../../../../api/endpoints/trading/getDashboardChart";
import Chart from "react-apexcharts";
import Datetime from '../../../../util/datetime';
import { getMax } from '../../../../util/math';
import { TradingContext } from '../../../../state/Trading';
import getDashboardLiveNumbers from '../../../../api/endpoints/trading/getDashboardLiveNumbers';
import { getSafeValue } from '../../../../util/json';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import Widget from '../../general/layout/Widget';
import { text } from '../../../../config'
import ChartToggleView from './charts/Toggle';
import { getPriceConfig } from './charts/config';
import { PriceTooltip } from './charts/Tooltip';
import { capitalize } from '../../../../util/string';
import { SystemContext } from '../../../../state/System';
import PauseView from '../../general/PauseView';

export default function MainChartView(props) {

    const MIN_BARS = 50

    const [volumeData, setVolumeData] = useState([]);
    const [priceData, setPriceData] = useState([]);
    //const [boxData, setBoxData] = useState([]);

    const [timeInterval, setTimeInterval] = useState('one minute');

    const { darkMode } = useContext(SystemContext);
    const { user } = useContext(AccountContext);
    const { width } = useWindowDimensions();

    const { setMode, mode, liveNumbers, setLiveNumbers } = useContext(TradingContext);
    
    const [firstNR, setFirstNR] = useState(true);

    useEffect(() => {
      getDashboardLiveNumbers(user, setMode).then((data) => {
        setLiveNumbers(data);
      }).catch((err) => {});
    }, [props.unit]);

    useEffect(() => {
        getDashboardChart(user, 'price', 'display', props.unit, setMode).then((json) => {
            setTimeInterval(getSafeValue(json, "time_interval", "string").toLowerCase())
            var chart_data = getSafeValue(json, "chart_data", "list");
            setPriceData(chart_data);
            let volArr = JSON.parse(JSON.stringify(chart_data));
            volArr.forEach(row => {row['y'] = row['volume']});
            setVolumeData(volArr);
            //let boxArr = JSON.parse(JSON.stringify(chart_data));
            //boxArr.forEach(row => {row["y"] = [row["low"], row["high"], row["low"], row["y"]]});
            //console.log(boxArr);
            //setBoxData(boxArr);
            props.setLoading(false);
        }).catch((err) => {
          props.setLoading(false);
        });
    }, [props.unit]);

    useEffect(() => {
      if(liveNumbers){
        let newRecord = { t: Datetime.getCurrentUTCDateTime(), x: getSafeValue(priceData[priceData.length - 1], "x", "int"), y: liveNumbers.mkt_price}
        if(firstNR){
          setPriceData([...priceData, newRecord]);
          setFirstNR(false);
        }else{
          setPriceData([...priceData.slice(0, -1), newRecord]);
        }
      }
    }, [liveNumbers])

    let options = getPriceConfig(
      PriceTooltip, //tooltip
      function ({ value, seriesIndex, dataPointIndex, w }) { //colorFunction
        if(getSafeValue(volumeData[dataPointIndex], "volume_bar_color", "string") === "green"){
          return "#16a34aaa";
        }else{
          return "#D9534Faa";
        }
      },
      width < 768 ? priceData.length : Math.max(priceData.length, MIN_BARS), //xMax
      getMax(volumeData, "y")*6, //yMaxVol
      getMax(priceData, "y"), //yMaxPrice
      (width < 768 ? Math.min(Math.pow(volumeData.length, 1.5), 100) : Math.min(volumeData.length, 100) / 1.3), //colWidth
      darkMode
    );

    let series = [
      {
        name: "price",
        type: "area",
        data: priceData
      },
    {
      name: "volume",
      type: "column",
      data: volumeData
    },
    /*{
      name: "box",
      type: "candlestick",
      data: boxData
    },*/
  ];

    return(
        <Widget className="overflow-hidden relative dashboard-target" ref={props.refer}>
          <ChartToggleView unit={props.unit} setUnit={props.setUnit}>
            <div className="flex flex-col w-fit justify-center px-4 pt-2">
              <h1 className={`${text.blue} mt-0 font-normal`}>{mode === 'normal' ? `$${liveNumbers.mkt_price.toFixed(2)}` : "$0.00"}</h1>
              <small className={`font-bold ${text.green} mt-1 ml-1 whitespace-nowrap`}><span className={`${text.black}`}>STRAATO [</span>{mode === 'normal' ? (liveNumbers.price_change >= 0 ? "+$" + Math.abs(liveNumbers.price_change) : "-$" + Math.abs(liveNumbers.price_change)) : "$0.00"}<span className={`${text.black}`}> last hour]</span></small>
            </div>
          </ChartToggleView>
          {mode === 'pause' ?
            <PauseView time={10000} />
            : <>
              <div className="w-full h-full pb-1">
                <Chart options={options} series={series} height={"100%"} width={"100%"}/>
              </div>
              <small className={`w-full absolute bottom-0 text-center tiny ${text.darkGrey}`}>*Volume bars aggregated every {timeInterval}.</small>
            </>}
        </Widget>
      );
}
