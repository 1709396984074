import { text } from "../../../config";

export default function Feature(props) {

    return (
        <div className={`border-box flex flex-col flex-no-wrap snap-always snap-start ${props.noPad ? "" : "p-4"}`}>
            <h4 className={`${text.blue} whitespace-nowrap`}>{props.value}</h4>
            <small className="font-semibold mt-1 whitespace-nowrap">{props.label}</small>
            <small className="tiny">{props.small_label}</small>
        </div>
    );
}