import { text, bg, ring, standard } from "../../../../../config";

export function Label(props) {
    return (
        <label className={`block text-sm font-medium leading-6 ${text.black}`}>
            {props.text}
        </label>
    );
}

export function Input(props) {
    return (
        <div className="w-full mt-2">
            <div className="relative w-full">
                {props.overlayPosition === 'front' && (
                    <div className={`${bg.blue} rounded-tl rounded-bl h-full w-1/8 absolute grid place-items-center text-blue-gray-500 px-2`}>
                        <span className={`${text.offwhite}`}>{props.overlayText}</span>
                    </div>
                )}
                <input
                    {...props}
                    required
                    className={`${props.overlayPosition === 'front' ? 'pl-9' : 'pr-20'} block w-full ${bg.button} ${standard.formRounded} border-0 py-1.5 shadow-sm ring-1 ring-inset ${ring.grey} ${ring.darkGrey} focus:ring-2 dark:focus:ring-2 focus:ring-inset dark:focus:ring-inset focus:${ring.blue} ${ring.focusDark} sm:text-sm sm:leading-6 ${text.black}`}
                />
                {props.overlayPosition === 'back' && (
                    <div className={`${bg.blue} rounded-tr rounded-br right-0 top-0 h-full w-1/8 absolute grid place-items-center text-blue-gray-500 px-2`}>
                        <span className={`${text.offwhite}`}>{props.overlayText}</span>
                    </div>
                )}
            </div>
        </div>
    );
}