import { isSandbox } from "../../../config";
import API from "../../api";

export const handleUserOffers = (json, setMode) => {
    //API.handleMode(json, setMode);

    return {"offers" : json.offers, "count": json.count};
}

const getUserOffers = (user, setMode) => {

    let url = '/getUserOffers?rest_api_call=1&sandbox=' + (isSandbox ? parseInt(1) : parseInt(0));

    return new API(user).getRequest(url).then(json => {
        return handleUserOffers(json, setMode);
    });
}

export default getUserOffers;