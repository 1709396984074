import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useState, useEffect } from "react";
import { getSafeValue, isEmpty } from "../../../../util/json";
import React from "react";

function LayoutManager(props) {
    
    const { width } = useWindowDimensions();

    const [size, setSize] = useState('large');
    const [config, setConfig] = useState({});
    const [breakpoint, setBreakpoint] = useState(0);

    useEffect(() => {
        if(width > 1200){
            setSize('large');
        }else if(width > 768){
            setSize('medium');
        }else{
            setSize('small');
        }
    }, [width])

    useEffect(() => {
        setConfig(props.config);
        setBreakpoint('breakpoint' in props.config ? props.config.breakpoint : 768);
    }, [props.config]);

    /*Example Configuration:
        config: {
            'rows': {
                'large': <int>,
                'medium': <int>
            },
            'cols': {
                'large': <int>,
                'medium': <int>
            }
            views: [{
                'view': <View/>,
                'row-span': {
                    'large': <int>,
                    'medium': <int>
                    //small case is handled automatically by layout manager
                },
                'col-span': {
                    'large': <int>,
                    'medium': <int>
                    //small case is handled automatically by layout manager
                },
            }]
        }
    */
    
    if(isEmpty(config)) return <></>;

    return (
            <div className={width > breakpoint ? 
                `grid ${config.flow ? config.flow : 'grid-flow-row'} 
                ${typeof config.rows === 'object' ? config.rows[size] : config.rows} 
                ${typeof config.cols === 'object' ? config.cols[size] : config.cols} 
                gap-2 h-full` : 
                "grid grid-cols-1 gap-2"}>
                {config.views.map((view, i) => 
                    width > breakpoint ? 
                        <div key={i} className={`${typeof view['row-span'] === 'object' ? view['row-span'][size] : view['row-span']} 
                                ${typeof view['col-span'] === 'object' ? view['col-span'][size] : view['col-span']}`}>
                            {view.view}
                        </div> : 
                        <div key={i} style={{height: ('height' in view ? getSafeValue(view, 'height', "int") : "auto")}}>
                            {view.view}
                        </div>
                )}
            </div>
    );
}

export default LayoutManager;