import Datetime from "./datetime";

export var isEqualsJson = (obj1,obj2)=>{
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    return keys1.length === keys2.length && Object.keys(obj1).every(key=>obj1[key]===obj2[key]);
}

export var isEmpty = (obj) => {
    try{
    if(obj !== undefined && obj !== null){
        return Object.keys(obj).length === 0;
    }}catch(err){}
    return false;
}

export var isKey = (obj, key) => {
    if(obj === undefined || obj === null){return false}
    return Object.keys(obj).includes(key);
}

export var getSafeValue = (obj, key, type, dec=2, def=undefined) => {

    if(!type) type = "string"
    
    const default_mapping = {
        'int': 0,
        'float': 0.0,
        'string': "",
        'object': {},
        'list': [],
        'datetime': undefined,
        'boolean': false,
        'unknown': undefined
    }

    try{
        if(isKey(obj, key)){
            if(!obj[key]) return def ? def : default_mapping[type] 
            if(type=='int') return parseInt(obj[key]);
            if(type=='float') return parseFloat(parseFloat(obj[key]).toFixed(dec));
            if(type=='boolean') return (getSafeValue(obj, key, 'int') === 1)
            if(type=='datetime') return Datetime.reformateDatetime(getSafeValue(obj, key, "string"))

            return obj[key]
        }
    }catch(err){}

    return def ? def : default_mapping[type];
}

export var fetchObjectFromList = (l, key, value) => {
    let p = l.filter((obj) => (getSafeValue(obj, key, "unknown") === value));
    return p.length > 0 ? p[0] : getSafeValue({}, 'fake', 'object');
}