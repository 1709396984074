import resources from './test_data/resources';
import { useState, useEffect } from 'react';
import Resource from './components/Resource';
import Loader from '../../components/Loader';
import LoadingPage from '../../components/LoadingPage';
import { bg, standard, text } from '../../../config';

export default function Education() {

    const [offset, setOffset] = useState(0);
    const MAX = 21;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [prevDist, setPrevDist] = useState(0);
    const [dist, setDist] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);

    const [search, setSearch] = useState(null);
    const [category, setCategory] = useState("all");

    const updateOffset = () => setOffset(offset + MAX);

    const handleScroll = (e) => {
        setPrevDist(dist);
        setDist(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight);
        if(dist < 500 && !isLoading && prevDist > 500){
            updateOffset();
        }
      }

    const getData = () => {
        setIsLoading(true);
        new Promise(r => setTimeout(r, 2000)).then(() => {
            setData([...data, ...resources.filter((a, i) => i >= offset && i < offset + MAX )]);
            setIsLoading(false);
            setFirstLoad(false);
        }
        );
    }

    useEffect(() => {
        getData();
    }, [offset]);

    useEffect(() => {
        setData([]);
        getData();
    }, [category, search])

    return (
        <>
        <div className={`flex flex-col w-full h-screen ${standard.p} max-h-screen ${firstLoad ? "hidden" : ""}`}>
            <div className={`flex flex-col w-full ${bg.white} rounded-t-3xl ${standard.p}`}>
                <h2>Related Educational Resources</h2>
                <hr className={`h-px ${standard.mt} ${bg.darkBlue} border-0`}/>
                <div className="flex flex-row flex-wrap justify-between items-center pt-6">
                    <input placeholder="  &#x2315;  Search" className={`${bg.offwhite} ${text.black} ${standard.formRounded} p-2 border-0 w-full ${standard.mdW}`} />
                    <select value={category} onChange={(e) => setCategory(e.target.value)} className={`${bg.offwhite} ${text.black} ${standard.formRounded} p-2 border-0 w-full ${standard.mt} ${standard.mdW} md:mt-0`}>
                        <option value="all">All Resources</option>
                        <option value="crypto">Crypto</option>
                        <option value="gtheory">Game Theory</option>
                        <option value="quant">Quant</option>
                    </select>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 overflow-y-scroll no-scrollbar gap-4 py-4" onScroll={handleScroll}>
                {data.map((slot, i) => {
                    return <Resource {...slot} index={i}/>
                })}
                {isLoading ? <div className={`grid col-span-1 sm:col-span-2 md:col-span-3 w-full h-full border-box ${standard.p}`}><Loader/></div> : <></>}
            </div>
        </div>
        <LoadingPage visible={!firstLoad}/>
        </>
    );
}