import getDashboardCycleParams from "../../../../../api/endpoints/trading/getDashboardCycleParams";
import PopUp from '../../../../components/PopUp';
import { text } from '../../../../../config';
import { useContext, useEffect } from "react";
import { AccountContext } from "../../../../../state/Account";
import { TradingContext } from "../../../../../state/Trading";
import { resetParamsPopup } from "../../../tutorial/popupConfig";

export default function ResetParams(props) {

    const { cycleParams, setCycleParams } = useContext(TradingContext)
    const { user } = useContext(AccountContext);
  
    useEffect(() => {
      getDashboardCycleParams(user).then((data) => {
        setCycleParams(data);
        props.setLoading(false);
      }).catch((err) => props.setLoading(false));
    }, []);
  
    return (
      <div className="flex flex-col w-full sm:w-1/2 h-auto sm:h-full pr-0 sm:pr-4">
        <div className="flex flex-row">
          <small className="mt-2 font-bold">Parameters</small>
          <div className="mt-3 ml-1 cursor-pointer">
            <PopUp description={resetParamsPopup} />
          </div>
        </div>
  
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Reset statistic low threshold</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{cycleParams.low_reset_threshold}</p>
        </div>
        <hr />
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Reset statistic high threshold</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{cycleParams.high_reset_threshold}</p>
        </div>
        <hr />
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Minimum viable queue velocity</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{cycleParams.min_avg_queue_velocity}</p>
        </div>
        <hr />
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Reset statistic calculation window</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{cycleParams.ratio_calculation_duration}</p>
        </div>
      </div>
    );
  }