import { text, bg } from "../../../config";

export default function Toggle (props) {

    let active = props.toggle === props.name;

    return (
        <div className="flex flex-col justify-center items-center flex-no-wrap pt-4 cursor-pointer select-none" onClick={() => props.setToggle(props.name)}>
            <p className={`font-semibold px-4 ${active ? text.blue : ""}`}>{props.label}</p>
            <hr className={`w-full box-border h-px mt-2 border-0 ${active ? bg.darkBlue : ""}`}/>
        </div>
    );
}