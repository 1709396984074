import React, {useEffect} from "react";
import FormGradientView from "../FormGradientView";
import logo from "../../assets/images/text_logo.png";
import { text } from "../../config";

const HubspotContactForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '44344611',
                    formId: '9dcbeca7-651f-41b8-9ab5-3db89386a5b5',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <FormGradientView>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <a href="/">
                <img
                    className="mx-auto h-10 w-auto"
                    src={logo}
                    alt="Straato"
                />
                </a>
                <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight !${text.alwaysblack}`}>
                Submit your feedback
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
                <div id="hubspotForm"></div>
            </div>
        </div>
        </FormGradientView>
    );

}

export default HubspotContactForm;