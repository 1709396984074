import { useContext, useState, useEffect } from "react";
import Modal from "../../../general/Modal";
import { TradingContext } from "../../../../../state/Trading";
import { Input, Label } from "./Components";
import buy from "../../../../../api/endpoints/trading/buy";
import { AccountContext } from "../../../../../state/Account";
import { text, bg, standard } from "../../../../../config";
import { minMaxStepEnforcer } from "../../../../../util/labels";

export default function Buy() {

    const { liveNumbers, cycleParams, mode, setQueueDataMounted, queueData } = useContext(TradingContext);

    const { user } = useContext(AccountContext);

    const [num, setNum] = useState(1);
    const [maxPrice, setMaxPrice] = useState(0.00);

    const [resMessage, setResMessage] = useState(null);
    const [buttonStatus, setButtonStatus] = useState('normal');

    const [expectedPrice, setExpectedPrice] = useState(0.00);

    const getExpectedPrice = () => {
        var n = 0;
        var price = 0;
        var i = 0;
        while(n < num && i < queueData.length){
            for(var p = 0; p < queueData[i].num; p++){
                if(n >= num) break;
                n += 1;
                price += queueData[i].raw_price;
            }
            i+=1;
        }
        return price;
    }

    useEffect(() => {
        setExpectedPrice(getExpectedPrice);
    }, [num, maxPrice]);

    useEffect(() => {
        if(buttonStatus !== 'normal'){
            new Promise(r => setTimeout(r, 3000)).then(() => {
                setButtonStatus('normal');
                setResMessage(null);
                window.location.reload();
            });
        }
    }, [buttonStatus]);

    const callBuyEndpoint = (e) => {
        e.preventDefault();
        buy(user, num, maxPrice).then((res) => {
            setResMessage(res.message);
            setButtonStatus('success');
        }).catch((err) => {
            setResMessage(err.message);
            setButtonStatus('error');
        });
    }

    const getButton = () => {
        if(mode === 'normal'){
        let txt = "Something went wrong.";

        if(num <= 0) txt = "You must buy at least one Straato."
        else if(num > cycleParams.max_per_buy) txt = "You cannot buy more than " + cycleParams.max_per_buy + " Straato at one time.";
        else if(num > liveNumbers.queue_len) txt = "The queue only has " + liveNumbers.queue_len + " assets available to purchase."
        else if(maxPrice < liveNumbers.mkt_price) txt = "You must be at least willing to pay the current market price of $" + liveNumbers.mkt_price.toFixed(2) + ".";

        let submittable = num > 0 && num <= cycleParams.max_per_buy && maxPrice >= liveNumbers.mkt_price;

        if(buttonStatus === 'normal'){
            return(
                <div>
                    {submittable ? <></> : <small className={`${text.red}`}>{txt}</small>}
                    <button
                        type={submittable ? "submit" : ""}
                        className={`flex w-full justify-center ${standard.formRounded} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                            submittable
                              ? `${bg.blue} hover:${text.lightBlue}`
                              : `${bg.offwhite} !text-gray-400`
                          }`}
                    >
                        Buy Straato
                    </button>
                </div>
                )
        }else if(buttonStatus === 'error'){
            return (<div>
                <button className={`select-none flex w-full justify-center ${standard.formRounded} ${bg.red} px-3 py-1.5 mt-2 text-sm font-semibold leading-6 text-white shadow-sm`}>
                    Failed to List
                </button>
                <small className={`mt-2 ${text.red}`}>{resMessage}</small>
            </div>)
        }else if(buttonStatus === 'success'){
            return (<div>
                <button className={`select-none flex w-full justify-center ${standard.formRounded} ${bg.green} px-3 py-1.5 mt-2 text-sm font-semibold leading-6 text-white shadow-sm`}>
                    Success!
                </button>
                <small className={`mt-2 ${text.green}`}>{resMessage}</small>
            </div>)
        }}else{
            return(
                <div>
                    <small className={`${text.red}`}>You cannot buy Straato during a Marketplace pause.</small>
                    <button
                        className={`select-none flex w-full justify-center ${standard.formRounded} ${bg.offwhite} px-3 py-1.5 mt-2 text-sm font-semibold leading-6 ${text.grey} shadow-sm`}>
                        Buy Straato
                    </button>
                </div>
                )
        }
    }

    return (
        <Modal setVisible={setQueueDataMounted} title={"Buy Straato"} type={"buy"} subtext={"Buy Straato from the offer queue using your Marketplace credits."} 
        className={`flex flex-col ${bg.darkBlue} p-6 w-1/2 h-full items-center justify-center rounded-l-xl`}
        button_view={<h4 className="text-white">Buy</h4>}>
            <div className="flex flex-col w-full">
            <div className="mt-3 w-full">
                <form className="space-y-6 mb-2" onSubmit={callBuyEndpoint}>
                    <div>
                    <Label text={"Number of Straato"}/>
                    <Input
                        onChange={e => setNum(e.target.value)}
                        onBlur={() => setNum(minMaxStepEnforcer(num, 1, cycleParams.max_per_buy, 1))}
                        value={num}
                        id="number"
                        name="number"
                        type="number"
                        overlayPosition="back"
                        overlayText="Straato"
                        max={cycleParams.max_per_buy}
                        min={1}/>
                    </div>

                    <div>
                    <Label text={"Max Price per Straato"}/>
                    <Input
                        onChange={e => setMaxPrice(e.target.value)}
                        onBlur={() => setMaxPrice(minMaxStepEnforcer(maxPrice, liveNumbers.mkt_price, Number.MAX_SAFE_INTEGER, 0.01))}
                        value={maxPrice}
                        id="price"
                        name="price"
                        type="number"
                        step={0.01}
                        overlayPosition="front"
                        overlayText="$"
                        min={liveNumbers.mkt_price}/>
                    </div>
                    {getButton()}
                </form>
                <small>Expected Total Price: <span className={`${text.blue}`}>${expectedPrice.toFixed(2)}</span></small>
                </div>
                </div>
        </Modal>
    );
}