import { TradingContext } from "../../../../state/Trading";
import { useContext, useEffect } from "react";
import getDashboardPersonalInfo from "../../../../api/endpoints/trading/getDashboardPersonalInfo";
import { AccountContext } from "../../../../state/Account";
import { text, isSandbox } from "../../../../config";
import PopUp from '../../../components/PopUp';
import Widget from "../../general/layout/Widget";
import { SystemContext } from "../../../../state/System";
import { cycleParamsPopup, personalInfoPopup } from "../../tutorial/popupConfig";

export default function Personal(props) {

    const { personalInfo, setPersonalInfo, liveNumbers, cycleParams, mode } = useContext(TradingContext)
    const { user } = useContext(AccountContext);
    const { setShowTutorial } = useContext(SystemContext)

    useEffect(() => {
        getDashboardPersonalInfo(user).then((data) => {
            setPersonalInfo(data);
            setShowTutorial(data.show_tutorial);
            props.setLoading(false);
        }).catch((err) => {
            //setAcceptedTos(false);
            props.setLoading(false);
        });
    }, []);

    // if (!props.isLoading) { there seems to be an issue with this and the joyride; it will only highlight the statswidget when this is commented out
    return (
        <Widget className="overflow-hidden p-3 dashboard-target" ref={props.refer}>
            <div className="flex flex-row w-full">
                <small className="font-bold">Market Statistics</small>
                <div className="mt-1 ml-1 cursor-pointer">
                    <PopUp description={cycleParamsPopup} />
                </div>
            </div> 
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Peak queue price</small>
                <p className={`${text.blue} font-semibold`}>{mode === 'normal' ? `$${liveNumbers.min_offer_price.toFixed(2)}` : "-"}</p>
            </div>
            <hr />
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Queue length</small>
                <p className={`${text.blue} font-semibold`}>{mode === 'normal' ? liveNumbers.queue_len : "-"}</p>
            </div>
            <hr />
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Total trades in cycle</small>
                <p className={`${text.blue} font-semibold`}>{mode === 'normal' ? cycleParams.num_trades : "-"}</p>
            </div>
            <hr />
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Total Straato in cycle</small>
                <p className={`${text.blue} font-semibold`}>{mode === 'normal' ? cycleParams.num_active_assets : "-"}</p>
            </div>
            <hr />
            <div className="flex flex-row w-full mt-1">
                <small className="font-bold">Personal Info</small>
                <div className="mt-1 ml-1 cursor-pointer">
                    <PopUp description={personalInfoPopup} />
                </div>
            </div>
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">{isSandbox ? "Sandbox tokens" : "Straato owned"} </small>
                <p className={`${text.blue} font-semibold`}>{personalInfo.total_straato} [{personalInfo.pending_offers} Listed]</p>
            </div>
            <hr />
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Credit balance</small>
                <p className={`${text.blue} font-semibold`}>${personalInfo.credit_balance.toFixed(2)}</p>
            </div>
            <hr />
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Profit</small>
                <p className={`${text.blue} font-semibold`}>{personalInfo.getCashProfit(liveNumbers.mkt_price)} [{personalInfo.getPercentProfit(liveNumbers.mkt_price)}]</p>
            </div>
            <hr />
            <div className="flex flex-row w-full h-auto sm:h-full my-1 sm:my-0 justify-between items-center flex-no-wrap">
                <small className="font-light">Num trades</small>
                <p className={`${text.blue} font-semibold`}>{personalInfo.action_count}</p>
            </div>
        </Widget>
    );
    // }
}