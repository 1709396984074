import { formatAxisLabels } from "../../../../../util/labels";
import { renderToString } from "react-dom/server";
import { text, bg } from "../../../../../config";
import Datetime from "../../../../../util/datetime";
import { isKey } from "../../../../../util/json";

export function PriceTooltip({ series, seriesIndex, dataPointIndex, w }) {

    var priceData = w.globals.initialSeries[0].data[dataPointIndex];    
    var volumeData = w.globals.initialSeries[1].data[dataPointIndex];

    return renderToString(
      <div className={`flex flex-col ${bg.white}`}>
      <div className={`flex flex-row flex-no-wrap ${bg.offwhite}`}>
        <p className="p-2 font-semibold">{Datetime.reformateDatetime(priceData["t"]).split(" at ")[1]}</p>
        <p className="p-2">{Datetime.reformateDatetime(priceData["t"]).split(" at ")[0]}</p>
      </div>
      <div className="flex flex-row w-full px-2 py-2 pb-1 justify-between">
        <small className="font-semibold">Price</small>
        <p className={`${text.blue} font-semibold`}>{formatAxisLabels(priceData["y"], true, true)}</p>
      </div>
      <div className="flex flex-row w-full px-2 py-2 pb-1 justify-between">
        <small className="font-semibold">Volume</small>
        <p className={`${text.blue} font-semibold`}>{formatAxisLabels(volumeData["y"], false, false, true)}</p>
      </div>
    </div>);
  }

  export function ResetTooltip({ series, seriesIndex, dataPointIndex, w }) {

    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];    

    if(isKey(data, 'queue_velocity') && isKey(data, 't')){
    return renderToString(
      <div className={`flex flex-col ${bg.white}`}>
        <div className={`flex flex-row flex-no-wrap ${bg.offwhite}`}>
          <p className="p-2 font-semibold">{Datetime.reformateDatetime(data["t"]).split(" at ")[1]}</p>
          <p className="p-2">{Datetime.reformateDatetime(data["t"]).split(" at ")[0]}</p>
        </div>
        <div className="flex flex-row w-full px-2 py-2 pb-1 justify-between">
          <small className="font-semibold">Reset Stat</small>
          <p className={`${text.blue} font-semibold`}>{formatAxisLabels(data["y"], false)}</p>
        </div>
        <div className="flex flex-row w-full px-2 py-2 pt-1 justify-between">
          <small className="font-semibold">Velocity</small>
          <p className={`${text.blue} font-semibold`}>{formatAxisLabels(data["queue_velocity"], false)}</p>
        </div>
      </div>
    );}else{
      return renderToString(<></>)
    }
  }