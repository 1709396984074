import { isSandbox } from "../../../config";
import API from "../../api";

const getDashboardChart = (user, chart, action, unit, setMode) => {

    let url = '/getDashboardChart?&sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))
                + '&chart=' + chart + '&action=' + action + '&interval=' + unit;

    return new API(user).getRequest(url).then(json => {
        //API.handleMode(json, setMode);

        return json;
    });
}

export default getDashboardChart;