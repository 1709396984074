import { useContext, useEffect, useRef, useState } from "react";
import Toggle from "../general/Toggle";
import getTransactionHistory from "../../../api/endpoints/actions/getTransactionHistory";
import { AccountContext } from "../../../state/Account";
import Loader from "../../components/Loader";
import { bg, isSandbox, text, standard } from "../../../config";
import ActionsTable from "./components/ActionsTable";
import { capitalize } from "../../../util/string";
import DashboardPage from "../general/layout/DashboardPage";
import Widget from "../general/layout/Widget";
import FilterDropdown from "./components/FilterDropdown";
import { history } from "../tutorial/config";

export default function Actions() {

    const [toggle, setToggle] = useState('asset');
    const [downloading, setDownloading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const ref = useRef(null)
    const { user } = useContext(AccountContext);

    useEffect(() => {
        if (ref.current) {
            history[0].target = ref.current
        }
    })

    const emptyMessages = {
        'asset': "You have made no asset transactions yet. Start trading today!",
        'credit_purchase': "You have not made any credit purchases yet. Buy credits to trade on the Straato Market!"
    }

    const header_mappings = {
        'asset': { 'type': 'Transaction', 'time': 'Time', 'total_value': 'Value', 'min': 'Min Price', 'avg': 'Avg Price', 'max': 'Max Price', 'trade_count': 'Status', 'num': 'Num Listed' },
        'credit_purchase': { 'time': 'Time', 'amount_credits': 'Amount of Credits', 'total_charge': 'Total Charge', 'paypal_user_id': 'Paypal User Id', 'status': 'Status' },
        'credit_payout': { 'time': 'Time', 'amount_credits': 'Amount of Credits', 'paypal_user_id': 'Paypal User Id', 'status': 'Status' }
    }

    const formatting = {
        'asset': {
            'avg': (val) => val.toFixed(2),
            'max': (val) => val.toFixed(2),
            'min': (val) => val.toFixed(2),
            'type': (val) => capitalize(val),
            'total_value': (val) => val.toFixed(2),
            'trade_count': (val) => capitalize(val),
            'number': (val) => val.toFixed(2)
        }
    }

    const excludes = {
        'asset': ['user_id', 'isCurrentCycle', 'queue_count'],
        'credit_purchase': ['user_id'],
        'credit_payout': ['user_id']
    }

    const prefixes = {
        'asset': { 'total_value': '$', 'min': '$', 'max': '$', 'avg': '$' },
        'credit_purchase': { 'amount_credits': '$', 'total_charge': '$' },
        'credit_payout': { 'amount_credits': '$' }
    }

    const postfixes = {
        'asset': {},
        'credit_purchase': {},
        'credit_payout': {}
    }

    const blocks = {
        'status': {
            'Failed': bg.red,
            'Success': bg.green,
            'Pending': bg.yellow
        },
        'type': {
            'buy': bg.green,
            'sell': bg.blue
        },
        'trade_count': {
            'completed': bg.green,
            'error': bg.red,
            'waiting': bg.yellow,
            'in_progress': bg.blue
        }
    }

    const handleFilterChange = (status) => {
        setSelectedStatus(status);
    };

    const getData = (setIsLoading, offset, data, setData, MAX) => {
        setIsLoading(true);
        getTransactionHistory(user, false, toggle, offset).then((json) => {
            setData([...data, ...json.data]);
            setIsLoading(false);
        }).catch((err) => {
            setData([]);
            setIsLoading(false);
        })
    }

    const status_transform = (trade_count, queue_count) => {
        if (queue_count === 0) return 'completed';
        else if (trade_count > 0 && queue_count > 0) return 'in_progress';
        else if (trade_count === 0) return 'waiting';
        else return "error";
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const downloadCSV = () => {

        setDownloading(true);
        getTransactionHistory(user, true, toggle, 0).then((json) => {
            let headers = Object.values(header_mappings[toggle]).join(",");
            let csv = json.data.reduce((acc, user) => {
                let obj = Object.values(Object.fromEntries(Object.entries(user).filter(([key]) => !(excludes[toggle].includes(key))))).join(",");
                acc.push(obj);
                return acc;
            }, []);

            downloadFile({
                data: [headers, ...csv].join("\n"),
                fileName: "straato-marketplace-" + toggle + "s.csv",
                fileType: "text/csv",
            });
            setDownloading(false);
        }).catch((err) => setDownloading(false));
    };

    return (
        <DashboardPage visible={true}>
            <Widget className={`${standard.p}`}>
                <div className="flex flex-row w-full justify-between items-center flex-wrap select-none">
                    <h2>Action History</h2>
                    {downloading ?
                        <div>
                            <Loader />
                        </div>
                        :
                        <div className={`${bg.button} ${standard.rounded} ${standard.topRightButton}`} onClick={downloadCSV}>
                            <small className={`${text.blue}`}>Download</small>
                        </div>
                    }
                </div>
                <hr className={`h-px ${standard.mt} ${bg.blue} border-0`} />
                <div className="flex flex-row flex-wrap justify-start pb-4">
                    <Toggle name={"asset"} toggle={toggle} setToggle={setToggle} label={"Assets"} />
                    {!isSandbox ?
                        <>
                            <Toggle name={"credit_purchase"} toggle={toggle} setToggle={setToggle} label={"Credits"} />
                            <Toggle name={"credit_payout"} toggle={toggle} setToggle={setToggle} label={"Payouts"} />
                        </>
                        : <></>
                    }
                    <div className={`ml-auto ${standard.mt} select-none`}>
                        <FilterDropdown statuses={['completed', 'in_progress', 'waiting', 'error']} handleFilterChange={handleFilterChange} />
                    </div>
                </div>
                <ActionsTable formatting={formatting[toggle]} emptyMessage={emptyMessages[toggle]}
                    blocks={blocks} key={toggle} prefixes={prefixes[toggle]} postfixes={postfixes[toggle]}
                    excludes={excludes[toggle]} getData={getData} map={header_mappings[toggle]}
                    status_transform={status_transform} selectedStatus={selectedStatus} refer={ref} />
            </Widget>
        </DashboardPage>
    );
}