import { isSandbox } from "../../../config";
import API from "../../api";
import queueObject from "../../datatypes/queue_object";

export const handleQueueData = (json, setMode) => {
    //API.handleMode(json, setMode);
    
    let offers = []
    for (let i = 0; i < json.offers.length; i++) {
        offers.push(new queueObject(json.offers[i]));
      }
    return offers;
}

const getQueueData = (user, setMode) => {

    let url = '/getQueueData?rest_api_call=1&sandbox=' + (isSandbox ? parseInt(1) : parseInt(0));

    return new API(user).getRequest(url).then(json => {
        return handleQueueData(json, setMode);
    });
}

export default getQueueData;