import Volatility from '../assets/volatility.png';
import { standard, text } from '../../../config';
import ShowOnScroll from './ShowOnScroll';
import SideBySide from './SideBySide';
import Across1 from '../assets/across1.png';
import Across2 from '../assets/across2.png';
import Across3 from '../assets/across3.png';

export default function Marketplace(){

    return ( 
        <>
        <SideBySide
            textRight={true}
            wrapBack={true}
            imgHeight={400}
            img_source={Volatility}
            title={"A new market to re-envision trading."}
            text={"Straato is a centralized trading market for uniform digital tokens built with a new exchange system. The Straato Market is designed specifically for active trading, making it possible to develop more reliable and consistent intraday strategies, while providing the possibility of generating larger returns in shorter periods of time. Our system levels the playing field by ensuring information symmetry and creating a number of unique trading puzzles. Are you ready to join the market revolution?"}
            />
            <ShowOnScroll>
            <div className="flex flex-row w-full px-0 md:px-20 pb-10">
                    <div className="flex flex-row w-full flex-wrap justify-evenly font-light">
                        <div className={`flex flex-col w-full ${standard.mdW} items-center px-8 mt-12`}>
                            <img src={Across1} className="aspect-video w-full object-cover object-top"/>
                            <h4 className={`mt-6 ${text.alwaysblack} dark:text-gray-900 text-center`}>A new idea of financial value.</h4>
                            <small className={`${text.alwaysblack} mt-6 leading-6 text-center`}>The Straato Market is based on the idea that financial value is solely derived from demand and is fundamentally different from inherent value. In other words, for any asset, from land to stocks to Straato tokens, financial value or price is solely determined by how much others are willing to pay. With this concept, we can trade anything, regardless of any attached inherent value. As such, Straato tokens have no inherent value themselves and are conferred value only by the Market. You can read more about our thesis here.</small>
                        </div>
                        <div className={`flex flex-col w-full ${standard.mdW} items-center px-8 mt-12`}>
                            <img src={Across2} className="aspect-video w-full object-cover object-top"/>
                            <h4 className={`mt-6 ${text.alwaysblack} dark:text-gray-900 text-center`}>Controlling randomness.</h4>
                            <small className={`${text.alwaysblack} mt-6 leading-6 text-center`}>Stock prices are moved by tiny fluctuations in supply and demand, resulting in unpredictable negative volatility and market efficiency making profit hard to come by. Straato effectively encapsulates negative volatility so that prices are only negatively affected when demand drops significantly. This allows traders to develop more consistent strategies, without having to worry about noise, while creating fast upside potential.</small>
                        </div>
                        <div className={`flex flex-col w-full ${standard.mdW} items-center px-8 mt-12`}>
                            <img src={Across3} className="aspect-video w-full object-cover object-top"/>
                            <h4 className={`mt-6 ${text.alwaysblack} dark:text-gray-900 text-center`}>Can you crack the market?</h4>
                            <small className={`${text.alwaysblack} mt-6 leading-6 text-center`}>Through the personal experiences of our team, we realized the importance of developing an exciting and intellectually engaging trading system. Straato creates a number of interesting competitive trading puzzles fundamental to the operation of the Market, including a game theory 101 prisoners’ dilemma. How will you stack up?</small>
                        </div>
                    </div>
                </div>
                </ShowOnScroll>
        </>
    );

}