function formatString(theString)
{
    if(!theString){
        return "";
    }
    else{
        return theString;
    }
}

function capitalize(string){
    if(!string || string === undefined || string === null){ return ""; }

    string = string.replace("_", " ");
    const words = string.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
}

function isEmptyString(string){
    return string === undefined || string === null || !(string.length > 0) || 
    string === "" || !string.replace(/\s/g, '').length;
}


function checkLowercase(str){
    for (var i=0; i<str.length; i++){
      if (str.charAt(i) == str.charAt(i).toLowerCase() && str.charAt(i).match(/[a-z]/i)){
        return true;
      }
    }
    return false;
};

function checkUppercase(str){
    for (var i=0; i<str.length; i++){
      if (str.charAt(i) == str.charAt(i).toUpperCase() && str.charAt(i).match(/[a-z]/i)){
        return true;
      }
    }
    return false;
};

module.exports = {
    formatString: formatString,
    capitalize: capitalize,
    isEmptyString: isEmptyString,
    checkUppercase: checkUppercase,
    checkLowercase: checkLowercase
}