import Leaderboard from "../../general/Leaderboard"
import { useContext } from "react"
import { AccountContext } from "../../../../state/Account"
import getReferralLeaderboard from "../../../../api/endpoints/referrals/getReferralLeaderboard"
import Widget from "../../general/layout/Widget"
import { standard } from "../../../../config"

export default function ReferralLeaderboard(props) {

    let leaderMap = { 'tag_name': "", 'referrals': "Referrals", 'joined': "Joined" }

    const { user } = useContext(AccountContext);

    const getData = (setIsLoading, setData) => {
        setIsLoading(true);
        getReferralLeaderboard(user).then((data) => {
            setData(data);
            setIsLoading(false);
        }).catch((err) => {
            setData([]);
            setIsLoading(false);
        });
    }

    return (
        <Widget className={`${standard.p} overflow-hidden`}>
            <h4 className="select-none mb-3">Top Referrers</h4>
            <Leaderboard excludes={["isUser", "username", "rank"]} getData={getData} map={leaderMap} emptyMessage={"We can't fetch the referral leaderboard now."} />
        </Widget>
    );
}