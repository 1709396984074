import React, { useState, useEffect, useContext, useRef } from "react";
import { websocket } from "../config";
import { AccountContext } from "./Account";
import useInterval from '../hooks/useInterval';
import { getSafeValue, isKey } from "../util/json";

const SocketContext = React.createContext({
    tradingConnMounted: false,
    queueConnMounted: false
});

const useWebsocket = () => React.useContext(SocketContext);

const SocketManager = (props) => {

    const { user } = useContext(AccountContext);

    const [failed, setFailed] = useState(0); //probably only want to check websocket three times before closing

    const [socketMounted, setSocketMounted] = useState(false);

    const [config, setConfig] = useState([]);

    const socket = useRef(null);


    const poll = () => {
        for(let i = 0; i < config.length; i++){
            try{
                socket.current.send(JSON.stringify(config[i].path));
            }catch(err){
                setFailed(failed + 1);
            }
        }
    }

    const receive = () => {
        socket.current.onmessage = e => {
            let data = JSON.parse(e.data);
            if(isKey(data, "mode") && getSafeValue(data, "mode", "string") !== "normal"){ 
                setSocketMounted(false);
            }
            let obj = config.find(o => o.path.action === getSafeValue(data, "action", "string"));
            if(obj != null){
                obj.setter(data);
            }
        };
    }

    const openConnection = () => {
        socket.current = new WebSocket(websocket + "?token=" + user.idToken.jwtToken);
        socket.current.onopen = () => {};
        socket.current.onclose = () => {};
        receive();
    }

    useEffect(() => {
        if(socket !== null && socket.current !== null){
            receive();
        }else{
            setSocketMounted(true);
        }
    }, [config]);

    useEffect(() => {
        if(failed > 3){
            setFailed(0);
            setSocketMounted(false);
        }
    }, [failed]);

    useEffect(() => {
        if(socketMounted){
            openConnection();
            const socketCurrent = socket.current;

            return () => {
                socketCurrent.close();
            };
        }else{
            if(socket != null && socket.current != null){
                const socketCurrent = socket.current;
                socketCurrent.close();
            }
        }
    }, [socketMounted]);

    useInterval(()=> poll(), 3000);

        return (
            <SocketContext.Provider value={{
                setConfig: setConfig,
                setSocketMounted: setSocketMounted}}>
                {props.children}
            </SocketContext.Provider>
        );
}

export { SocketContext, SocketManager, useWebsocket }