import { useEffect, useRef } from 'react';

// Custom hook to handle updates when props change
const useUpdateOnPropsChange = (props) => {
  const prevPropsRef = useRef(props);

  useEffect(() => {
    // Compare the previous props with the current props
    const propsChanged =
      Object.keys(props).some((key) => props[key] !== prevPropsRef.current[key]);

    // If props have changed, trigger the update logic
    if (propsChanged) {
      // Your update logic here
      //console.log('Props have changed:', props);
    }

    // Save the current props for the next comparison
    prevPropsRef.current = props;
  }, [props]);
};

export default useUpdateOnPropsChange;