import Cloud from '../../../assets/images/text_logo.png';
import { text } from '../../../config';
import { InlineWidget } from "react-calendly";
import Widget from './layout/Widget';

export default function ClosedView(props) {
    return (
        <div className="border-box h-screen w-full p-16">
        <Widget className="flex flex-col items-center justify-center p-8">
            <div className="flex flex-row w-full justify-center items-center mb-2">
                <img src={Cloud} className="w-48 max-h-full aspect-auto" />
            </div>
            <div className="mt-0 mb-4 p-2 text-center">
                <p class="mb-3 font-light text-gray-500 md:text-lg dark:text-gray-400">The Straato Market is currently only open for <span className={`${text.blue} font-semibold`}>trading sessions</span>. See below to sign up for a trading session.</p>
                <p class="font-light text-gray-500 md:text-lg dark:text-gray-400">In the meantime, join the community on the <a href="https://discord.gg/BrWFGP7CTs" className={`${text.blue} hover:${text.lightBlue} font-semibold underline`}>Straato Discord</a> to stay up-to-date on announcements and more.</p>
            </div>
            <div class="items-center px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 lg:px-6">
                <div class="mt-4 md:mt-0">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Ready to start trading?</h2>
                    <h4 class="mb-4 tracking-tight font-extrabold text-gray-900 dark:text-white">Sign up for a trading session today!</h4>
                    <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">In preparation for our full beta launch, we are hosting live trading sessions. We need your advice to help Straato accomplish its mission of leveling the playing field for retail day traders.</p>
                    <span class="inline-flex items-center text-white bg-blue-600 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 mb-4">
                        Signup for a session
                        <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </span>
                </div>
                <div className='w-full'>
                    <InlineWidget
                        url="https://calendly.com/straato/straato-trading-session?hide_event_type_details=1&hide_gdpr_banner=1"
                        styles={{
                            height: '350px',
                            width: '100%'
                        }}
                    />
                </div>
            </div>
        </Widget>
        </div>
    );
}