import { text, bg, outline, standard } from "../../../config";

export default function CallToAction() {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className={`mt-2 text-3xl font-bold tracking-tight ${text.alwaysblack} sm:text-4xl text-center`}>
            Ready to join the Straato Market?
          </p>
          <p className={`mt-6 text-lg leading-8 text-gray-700 text-center`}>
            
          </p>
        </div>

            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/signup"
                className={`${standard.formRounded} ${bg.blue} px-3.5 py-2.5 text-sm font-semibold ${text.offwhite} shadow-sm hover:${bg.lightBlue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue}`}
              >
                Sign up
              </a>
              <a href="#" className={`text-sm font-semibold leading-6 ${text.alwaysblack}`}>
                To the top <span aria-hidden="true">↑</span>
              </a>
            </div>
      </div>
    </div>
  );
}
