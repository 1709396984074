import React, { useContext, useState, useEffect } from 'react';
import personal_info from '../api/datatypes/personal_info';
import cycle_params from '../api/datatypes/cycle_params';
import live_numbers from '../api/datatypes/live_numbers';
import { SocketContext } from './Socket';
import { isSandbox } from '../config';
import { handleDashboardLiveNumbers } from '../api/endpoints/trading/getDashboardLiveNumbers';
import { handleQueueData } from '../api/endpoints/queue/getQueueData';

const TradingContext = React.createContext();

const TradingContextView = (props) => {

  const [liveNumbers, setLiveNumbers] = useState(new live_numbers({}));
  const [cycleParams, setCycleParams] = useState(new cycle_params({}));
  const [personalInfo, setPersonalInfo] = useState(new personal_info({}));
  

  const [queueDataMounted, setQueueDataMounted] = useState(false);
  const [queueData, setQueueData] = useState([]);

  const [mode, setMode] = useState('normal');

  const { setConfig, setSocketMounted } = useContext(SocketContext);

  useEffect(() => {
    if(mode !== 'normal'){ setSocketMounted(false); }
  }, [mode]);

  let local_config = queueDataMounted ? [
    {
      'path':{
        "action": "getDashboardLiveNumbers",
        "sandbox": isSandbox ? 1 : 0, 
        "rest_api_call": 0,
        "last_pull_time" : "new"
      },
      'setter': (json) => setLiveNumbers(handleDashboardLiveNumbers(json, setMode))
    },
    {
      'path':{
        "action" : "getQueueData", 
        "sandbox": isSandbox ? 1 : 0, 
        "rest_api_call": 0,
      },
      'setter': (json) => setQueueData(handleQueueData(json, setMode))
    }
  ] :
  [
    {
      'path':{
        "action": "getDashboardLiveNumbers",
        "sandbox": isSandbox ? 1 : 0, 
        "rest_api_call": 0,
        "last_pull_time" : "new"
      },
      'setter': (json) => setLiveNumbers(handleDashboardLiveNumbers(json, setMode))
    },
  ]

  useEffect(() => {
    setConfig(local_config);
  }, [queueDataMounted]);

  useEffect(() => {
    setSocketMounted(true);
  }, []);

  return (
    <TradingContext.Provider
      value={{liveNumbers, setLiveNumbers, cycleParams, setCycleParams, 
      personalInfo, setPersonalInfo, mode, setMode,
      queueDataMounted, setQueueDataMounted, queueData}}>
        {props.children}
    </TradingContext.Provider>
  );
};

export { TradingContext, TradingContextView };