import { useContext } from "react";
import logo from "../../../assets/images/text_logo.png";
import { AccountContext } from "../../../state/Account";
import { useEffect, useState } from "react";
import { text, bg, outline, standard } from "../../../config";

export default function Hero() {

  const { user, getSession, setUser } = useContext(AccountContext);

  const [dash, setDash] = useState(false);

  useEffect(() => {
    if(!user){
    getSession().then((user) => {
        setUser(user);
        setDash(true);
    }).catch((err) => {
        setUser(null);
    });}else{
      setDash(true);
    }
}, []);

  return (
    <div className="h-screen">
            <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Straato</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt="Straato logo"
              />
            </a>
          </div>
          <div className="lg:flex lg:flex-1 lg:justify-end">
            <a href={dash? "/d/trade" : "/login"} className={`text-sm font-semibold leading-6 !${text.alwaysblack}`}>
              {dash? "Dashboard" : "Log in"} <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        </header>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto px-16 max-w-3xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className={`text-4xl font-bold tracking-tight sm:text-6xl text-center ${text.alwaysblack}`}>
              Welcome to Straato.
            </h1>
            <p className={`mt-6 text-md md:text-lg leading-8 !${text.desc}`}>
            Straato is a new trading market designed to level the playing field for active traders by eliminating random market noise.
            Learn more about Straato and earn free tokens for joining today!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/signup"
                className={`whitespace-nowrap relative ${standard.formRounded} ${bg.blue} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:${bg.lightBlue} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${outline.blue} z-10`}
              >
                Get started
              </a>
              <a href="#about-straato" className={`text-sm font-semibold leading-6 !${text.alwaysblack} whitespace-nowrap`}>
                Learn more <span aria-hidden="true">↓</span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
