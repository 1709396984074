import API from "../../api";
import referral_leader from "../../datatypes/referral_leader";

const getReferralLeaderboard = (user) => {
    return new API(user).getRequest('/getReferralLeaderboard').then(json => {
        let leaders = []
        for (let i = 0; i < json.leaderboard.length; i++) {
            leaders.push(new referral_leader(json.leaderboard[i]).getJSON());
          }
        return leaders;
    });
}

export default getReferralLeaderboard;