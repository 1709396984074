import { isSandbox } from "../../../config";
import API from "../../api";

export const handleQueueGraph = (json, setMode) => {
    //API.handleMode(json, setMode);

    return json.graph;
}

const getQueueGraph = (user, graph_type, setMode) => {

    let url = '/getQueueGraph?rest_api_call=1&sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))
                + '&graph_type=' + graph_type;

    return new API(user).getRequest(url).then(json => {
        return handleQueueGraph(json, setMode);
    });
}

export default getQueueGraph;