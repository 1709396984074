import Queue from "./components/Queue";
import Distributions from "./components/Distributions";
import MyOffers from "./components/MyOffers";
import TopBar from "./components/TopBar";
import { useContext, useEffect, useRef, useState } from 'react';
import LoadingPage from "../../components/LoadingPage";
import DashboardPage from "../general/layout/DashboardPage";
import LayoutManager from "../general/layout/LayoutManager";
import { offerQueue } from "../tutorial/config";
import { QueueContext } from "../../../state/Queue";
import ClosedView from "../general/ClosedView";

export default function OfferQueue(){

    const [topBarLoading, setTopBarLoading] = useState(true);
    const [queueLoading, setQueueLoading] = useState(true);
    const [distLoading, setDistLoading] = useState(true);

    const ref = useRef(null)

    const { mode } = useContext(QueueContext);

    useEffect(() => {
        const targets = document.querySelectorAll('.queue-target');
        targets.forEach((target, index) => {
            if (target && offerQueue[index]) {
                offerQueue[index].target = target;
            }
        });
    })

    let config = {'rows': 'grid-rows-5', 'cols': 'grid-cols-2',
        views: [
            {'view': <TopBar refer={ref} setTopBarLoading={setTopBarLoading}/>, 'row-span': 'row-span-1', 'col-span': 'col-span-full'},
            {'view': <Queue setQueueLoading={setQueueLoading}/>, 'row-span': 'row-span-2', 'col-span': 'col-span-full', 'height': 300},
            {'view': <MyOffers/>, 'row-span': 'row-span-2', 'col-span': 'col-span-1', 'height': 400},
            {'view': <Distributions setDistLoading={setDistLoading}/>, 'row-span': 'row-span-2', 'col-span': 'col-span-1', 'height': 300},
        ]
    }

    let visible = !topBarLoading && !queueLoading && !distLoading;

    return mode === 'closed' ?
        <ClosedView/>
        : <><DashboardPage visible={visible}><LayoutManager config={config}/></DashboardPage>
            <LoadingPage visible={visible} loadingText={"We are fetching live queue data. Stay sharp!"}/></>
}