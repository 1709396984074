import { isSandbox } from "../../../config";
import API from "../../api";

export const handleQueueStats = (json, setMode) => {
    API.handleMode(json, setMode);

    return json.stats;
}

const getQueueStats = (user, setMode) => {

    let url = '/getQueueStats?rest_api_call=1&sandbox=' + (isSandbox ? parseInt(1) : parseInt(0));

    return new API(user).getRequest(url).then(json => {
        return handleQueueStats(json, setMode);
    });
}

export default getQueueStats;