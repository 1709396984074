import React, { useState, useEffect, useContext } from 'react';
import { SocketContext } from './Socket';
import { isSandbox } from '../config';
import { handleQueueStats } from '../api/endpoints/queue/getQueueStats';
import { handleUserOffers } from '../api/endpoints/queue/getUserOffers';
import { handleQueueData } from '../api/endpoints/queue/getQueueData';
//import { handleQueueGraph } from '../api/endpoints/queue/getQueueGraph';

const QueueContext = React.createContext();

const QueueContextView = (props) => {

  const [userOffers, setUserOffers] = useState({});
  const [queueStats, setQueueStats] = useState({});
  const [queueData, setQueueData] = useState([]);

  const [mode, setMode] = useState('normal');

  const [wsGraphType, setWSGraphType] = useState('price');
  const [graphDataSetter, setGraphDataSetter] = useState(() => () => {});

  
  const { setConfig, setSocketMounted } = useContext(SocketContext);
  
  useEffect(() => {
    if(mode !== 'normal'){ setSocketMounted(false); }
  }, [mode]);
  
  let local_config = [
    {
      'path':{
        "action" : "getQueueStats", 
        "sandbox": isSandbox ? 1 : 0, 
        "rest_api_call": 0,
      },
      'setter': (json) => setQueueStats(handleQueueStats(json, setMode))
    },
    /*{
        'path':{
          "action" : "getUserOffers", 
          "sandbox": isSandbox ? 1 : 0, 
          "rest_api_call": 0,
        },
        'setter': (json) => setUserOffers(handleUserOffers(json, setMode))
    },*/
    {
      'path':{
        "action" : "getQueueData", 
        "sandbox": isSandbox ? 1 : 0, 
        "rest_api_call": 0,
      },
      'setter': (json) => setQueueData(handleQueueData(json, setMode))
  },
  /*{
    'path':{
      "action" : "getQueueGraph", 
      "sandbox": isSandbox ? 1 : 0, 
      "rest_api_call": 0,
      "graph_type": wsGraphType
    },
    'setter': (json) => graphDataSetter(handleQueueGraph(json, setMode))
  }*/
  ]

  useEffect(() => {
    setConfig(local_config);
    setSocketMounted(true);
  }, []);

  return (
    <QueueContext.Provider
      value={{userOffers, setUserOffers, queueStats, setQueueStats, queueData, setQueueData,
              setWSGraphType, setGraphDataSetter, mode, setMode}}>
        {props.children}
    </QueueContext.Provider>
  );
};

export { QueueContext, QueueContextView };