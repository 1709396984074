import React, { useContext, useState, useEffect, useRef } from 'react';
import { ReactComponent as Trash } from '../assets/trashIcon.svg';
import { ReactComponent as Minus } from '../assets/minusSign.svg';
import { SystemContext } from '../../../../state/System';
import { bg, border, standard, text } from '../../../../config';
import { minMaxStepEnforcer } from '../../../../util/labels';

const OfferItem = ({ offer, index, handleEditOffer }) => {
    const { darkMode } = useContext(SystemContext)
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [changedVal, setChangedVal] = useState(1);
    const formattedPrice = offer.price % 1 === 0 ? `${offer.price}.00` : offer.price.toFixed(2);
    const editingRef = useRef(null);

    const handleTrashClick = (event) => {
        event.stopPropagation();
        setIsEditing(!isEditing);
    };

    const handleInputChange = (event) => {
        const newVal = event.target.value;
        setChangedVal(newVal);
    };

    const formatDateTime = (datetime) => {
        const options = { month: '2-digit', day: '2-digit', year: '2-digit', hour: 'numeric', minute: '2-digit' };
        const formattedDateTime = new Date(datetime).toLocaleString('en-US', options);
        return formattedDateTime.replace(',', '');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editingRef.current && !editingRef.current.contains(event.target)) {
                setIsEditing(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    })

    return (
        <li key={index} ref={editingRef} className={`flex justify-between items-center py-2 ${bg.white} ${text.black} border-t font-semibold ${border.darkGrey}`}>
            <div className="flex flex-row py-2 w-full">
                <div className='w-1/4 flex justify-start'>
                    <div className='w-full text-center'>
                        {offer.count} Straato
                    </div>
                </div>
                <div className='w-1/4 text-center px-2'>
                    <p className={`rounded text-white ${bg.blue}`}>${formattedPrice}</p>
                </div>
                <div className='w-4/6 flex justify-between px-2'>
                    <span>Listed {formatDateTime(offer.time)}</span>
                    {isEditing && (
                        <input
                            type="number"
                            onBlur={() => setChangedVal(minMaxStepEnforcer(changedVal, 1, offer.count, 1))}
                            value={minMaxStepEnforcer(changedVal, 1, offer.count, 1)}
                            min={1}
                            step={1}
                            max={offer.count}
                            onChange={handleInputChange}
                            className={`${standard.formRounded} ${border.grey} ${bg.white} max-h-6 px-1`}
                        />
                    )}
                </div>
                <div className='w-1/8 flex justify-center align-center px-2 cursor-pointer'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>
                    <button
                        onClick={(e) => handleTrashClick(e)}
                    >
                        {isEditing ? (
                            <Trash fill={isHovered ? 'red' : (darkMode ? 'white' : 'grey')} onClick={() => handleEditOffer(changedVal)}/>
                        ) : (
                            <Minus fill={isHovered ? '#2563eb' : (darkMode ? 'white' : 'black')} />
                        )}
                    </button>
                </div>
            </div>
        </li>
    );
};

export default OfferItem;
