import useIsVisible from "../../../hooks/useIsVisible";
import { useRef } from "react";

export default function ShowOnScroll(props){

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <div ref={ref} className={`transition-opacity ease-in duration-250 ${isVisible ? "opacity-100" : "opacity-0"}`}>
            {props.children}
        </div>
    )

}