import QRCode from 'qrcode.react'
import { useRef, useState, useEffect, useContext } from 'react';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import useUpdateOnPropsChange from '../../../../hooks/useUpdateOnPropsChange';
import Widget from '../../general/layout/Widget';
import { bg, border, standard } from '../../../../config';
import { SystemContext } from '../../../../state/System';
import { ReactComponent as Download } from '../assets/download.svg';

export default function QRCodeDisplay(props) {

    useUpdateOnPropsChange(props);

    const topRef = useRef(null);
    const mainRef = useRef(null);
    const overlayRef = useRef(null);

    const { darkMode } = useContext(SystemContext)

    const { width, height } = useWindowDimensions();
    const [qrHeight, setQRHeight] = useState(0);
    const [showDownloadOverlay, setShowDownloadOverlay] = useState(false);

    const downloadQRCode = () => {
        try {
            const qrCodeURL = document.getElementById('refQrCode')
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let aEl = document.createElement("a");
            aEl.href = qrCodeURL;
            aEl.download = "straato-referral-qrcode.png";
            document.body.appendChild(aEl);
            aEl.click();
            document.body.removeChild(aEl);
            //console.log("DOWNLOAD")
        } catch (error) {
            //console.error("Error downloading QR code:", error);
        }
    }

    useEffect(() => {
        setQRHeight(mainRef.current.clientHeight - topRef.current.clientHeight - 100);
    }, [height, width]);

    const handleMouseEnter = () => {
        setShowDownloadOverlay(true);
    };

    const handleMouseLeave = () => {
        setShowDownloadOverlay(false);
    };

    return (
        <Widget ref={mainRef} className={`overflow-hidden ${standard.p}`}>
            <div ref={topRef} className="flex flex-row w-full justify-between items-start md:items-center flex-no-wrap select-none">
                <h4>Referral QR Code</h4>
            </div>
            <p className="mt-2">Download the QR Code below to share your referral link with others!</p>
            <div className={`flex flex-row justify-evenly items-center`}>
                {width > 768 ? <div className="align-middle mr-8 mt-4 h-4/5 items-center w-1/2">
                    <p>Scanning your QR Code will bring people to Straato's signup page. If they signup using your unique link, you will earn free Straato! Make sure to share your link on social media.</p>
                </div> : <></>}
                <div className="relative mt-4 w-48 h-36" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div>
                        {props.link !== null && ( <QRCode value={props.link} id="refQrCode" bgColor={darkMode ? '#111827' : '#FFFFFF'} fgColor={darkMode ? '#FFFFFF' : '#111827'} 
                            style={{minWidth: 100, minHeight: 100, maxWidth: 250, maxHeight: 250, width: qrHeight, height: qrHeight, position: 'absolute', top: 0, left: 0}}/>
                        )}
                        {showDownloadOverlay && (<div ref={overlayRef} className={`${bg.offwhite} border border-gray-400 flex justify-center items-center`}
                            style={{ minWidth: 100, minHeight: 100, maxWidth: 250, maxHeight: 250, width: qrHeight, height: qrHeight, position: 'absolute', top: 0, left: 0}}>
                                <Download fill={darkMode ? '#e5e7eb' : '#000000'} className='h-6 cursor-pointer hover:fill-gray-400' onClick={downloadQRCode}/>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </Widget>
    );
}