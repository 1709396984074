import PopUp from '../../../../components/PopUp';
import { text } from '../../../../../config';
import { useContext, useEffect, useState } from "react";
import { TradingContext } from "../../../../../state/Trading";
import useCountDown from '../../../../../hooks/useCountDown';
import { resetStatsPopup } from '../../../tutorial/popupConfig';

export default function ResetStat(props) {
    const { mode, liveNumbers, cycleParams } = useContext(TradingContext)
  
    //days, hours, minutes, seconds
    const time_length = useCountDown(cycleParams.cycle_start_time);
  
    const [timeString, setTimeString] = useState("00:00:00");
  
    //this needs more work...check for plurals and cut down on length of the string...
    useEffect(() => {
      if (mode !== 'normal') return "00:00:00"
      else {
        var use = ((Math.abs(time_length[0]) - 1)* 24 + Math.abs(time_length[1]) - 1).toString().padStart(2, '0') + ":" + Math.abs(time_length[2]).toString().padStart(2, '0') + ":" + Math.abs(time_length[3]).toString().padStart(2, '0')
        var t = use.charAt(0) === '-' ? "00:00:00" : use;
        /*var t = Math.abs(time_length[3]) + " secs"
        if(time_length[2] !== 0) t = Math.abs(time_length[2]) + " mins, " + t
        if(time_length[1] !== 0) t = Math.abs(time_length[1]) + " hrs, " + t
        if(time_length[0] !== 0) t = Math.abs(time_length[0]) + " days, " + t*/
        setTimeString(t);
      }
    }, [time_length]);
  
    return (
      <div className="flex flex-col w-full sm:w-1/2 h-auto sm:h-full pl-0 sm:pl-4">
        <div className="flex flex-row w-full">
          <small className="mt-2 font-bold">Statistics</small>
          <div className="mt-3 ml-1 cursor-pointer">
            <PopUp description={resetStatsPopup} />
          </div>
        </div>
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Current reset statistic</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{mode === 'normal' ? liveNumbers.reset_ratio : "-"}</p>
        </div>
        <hr />
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Queue velocity</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{mode === 'normal' ? liveNumbers.queue_velocity.toFixed(2) : "-"}</p>
        </div>
        <hr />
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Cycle length</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>{timeString}</p>
        </div>
        <hr />
        <div className={`flex flex-row h-auto sm:h-full my-1 sm:my-0 justify-between items-center`}>
          <small className="font-light whitespace-nowrap">Last cycle peak price</small>
          <p className={`${text.blue} whitespace-nowrap font-semibold`}>${Math.abs(cycleParams.sandbox_last_peak_price).toFixed(2)}</p>
        </div>
      </div>
    );
  }