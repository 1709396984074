import logo from "../../../assets/images/text_logo.png";
import GradientView from '../../GradientView';
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AccountContext } from '../../../state/Account';
import Form from '../../components/Form';
import { getAmplifyErrorMessage } from "../../../util/error";
import SignInWithGoogle from "../other/Google";
import { isEmptyString } from "../../../util/string";
import FormGradientView from "../../FormGradientView";
import { text } from "../../../config";

export default function Login() {

  const navigate = useNavigate()
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { authenticate, setUser } = useContext(AccountContext);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!isEmptyString(searchParams.get('failed')) && searchParams.get('failed') === 'true') {
      setErrorMessage("Something went wrong. Please try again or login with a different account.");
    }
  }, [searchParams]);

  const signin = (event) => {
    event.preventDefault()
    setIsLoading(true)
    authenticate(email, password)
      .then(user => {
        setUser(user);
        setIsLoading(false);
        let navString = '/mfa?username=' + btoa(user.username) +
          '&challengeName=' + btoa(user.challengeName) +
          '&userpool=' + btoa(user.pool.userPoolId) +
          '&clientid=' + btoa(user.pool.clientId) +
          '&endpoint=' + btoa(user.client.endpoint) +
          '&session=' + btoa(user.Session) +
          '&email=' + btoa(email);
        navigate(navString);
      })
      .catch((err) => {
        setErrorMessage(getAmplifyErrorMessage(err));
        setIsLoading(false)
        if (err.code === 'UserNotConfirmedException') {
          navigate('/confirm-email?login=true&email=' + btoa(email));
        }
      })
  }

  const formProps = {
    submit: 'Sign in',
    callback: signin,
    schema: [
      {
        title: "Email address",
        id: "email",
        name: "email",
        type: "email",
        autoComplete: "email",
        setter: setEmail
      },
      {
        title: "Password",
        id: "password",
        name: "password",
        type: "password",
        autoComplete: "current-password",
        setter: setPassword
      }
    ]
  }

  return (
    <FormGradientView>
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <a href="/">
              <img
                className="mx-auto h-10 w-auto"
                src={logo}
                alt="Straato"
              />
            </a>
            <h2 className={`mt-10 text-center text-2xl font-bold leading-9 tracking-tight !${text.alwaysblack}`}>
              Log In
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form {...formProps} loading={isLoading} errorMessage={errorMessage} />
            <SignInWithGoogle />
            <p className={`mt-10 text-center text-sm ${text.alwaysblack}`}>
              Don't have an account?{' '}
              <a href="/signup" className={`font-semibold leading-6 ${text.blue} hover:${text.lightBlue}`}>
                Sign up.
              </a>
              <br />
              <br />
              Forgot password?{' '}
              <a href="/reset-password" className={`font-semibold leading-6 ${text.blue} hover:${text.lightBlue}`}>
                Reset.  
              </a>
            </p>
          </div>
        </div>
      </div>
    </FormGradientView>
  );
}
