import { useState, useEffect, useContext } from 'react';
import Chart from "react-apexcharts";
import React from 'react';
import getDashboardChart from '../../../../api/endpoints/trading/getDashboardChart';
import { AccountContext } from '../../../../state/Account';
import { TradingContext } from "../../../../state/Trading";
import Widget from '../../general/layout/Widget';
import { text, standard } from '../../../../config';
import { ResetTooltip } from './charts/Tooltip';
import getResetConfig from './charts/config';
import ChartToggleView from './charts/Toggle';
import ResetParams from './reset/ResetParams';
import ResetStat from './reset/ResetStats';
import { SystemContext } from '../../../../state/System';

export default function ResetGraph(props) {

  const [data, setData] = useState([]);

  const { darkMode } = useContext(SystemContext);
  const { user } = useContext(AccountContext);
  const { setMode, mode, liveNumbers } = useContext(TradingContext);

  const [upperBound, setUpperBound] = useState([]);
  const [lowerBound, setLowerBound] = useState([]);

  useEffect(() => {
    getDashboardChart(user, 'reset', 'display', props.unit, setMode).then((json) => {
        let dat = json.chart_data;
        let filler = Array(Math.max(dat.length, 100)).fill({'x': 0, 'y': json.resetUpperBound});
        setUpperBound(filler.map((r, i) => {
            return {'x': i + 1, 'y': json.resetUpperBound}
        }));
        setLowerBound(filler.map((r, i) => {
            return {'x': i + 1, 'y': json.resetLowerBound}
        }));
        setData(dat);
        props.setLoading(false);
    }).catch((err) => {
      props.setLoading(false);
    });
}, [props.unit]);

  let options = getResetConfig(
    ResetTooltip, //tooltip
    lowerBound.length > 0 ? lowerBound[0].y - 0.1 : 0, //yMin
    upperBound.length > 0 ? upperBound[0].y + 0.1 : 0, //yMax
    data.length, //data_length
    darkMode
  )
      
  let series = [{
    name: 'Reset Statistic',
    data: data,
    type: "line",
  },
  {
    name: 'Upper Bound',
    data: upperBound
  },
  {
    name: 'Lower Bound',
    data: lowerBound
  }];

    return(
      <Widget className="overflow-hidden relative px-4 pb-4 dashboard-target" ref={props.refer}>
        <ChartToggleView unit={props.unit} setUnit={props.setUnit}>
          <div className="flex flex-col w-fit justify-center pt-2">
            <h2 className={`${text.blue} mt-0 font-normal`}>{mode === 'normal' ? liveNumbers.reset_ratio : "-"}</h2>
            <small className={`font-bold ${text.black} mt-1 ml-1 whitespace-nowrap`}>Reset Statistic (sQGf)</small>
          </div>
        </ChartToggleView>
          <div className={`${standard.mt} w-full h-full`}>
            <Chart id={"reset-graph"} options={options} series={series} height={"100%"} type={'line'}/>
          </div>
          <div className="flex flex-row w-full h-full flex-wrap">
            <ResetParams setLoading={props.setLoading}/>
            <ResetStat/>
          </div>
        </Widget>
    );
  }
