import { isSandbox } from "../../../config";
import { getSafeValue } from "../../../util/json";
import API from "../../api";
import personal_info from "../../datatypes/personal_info";

const getDashboardPersonalInfo = (user) => {
    return new API(user).getRequest('/getDashboardPersonalInfo?sandbox=' + (isSandbox ? parseInt(1) : parseInt(0))).then(json => {
        if(getSafeValue(getSafeValue(json, "personal_info", "object"), "accepted_tos", "boolean")){
            return new personal_info(getSafeValue(json, "personal_info", "object"));
        }else{
            throw "accept_tos";
        }
    });
}

export default getDashboardPersonalInfo;