import { useState, useEffect } from 'react';
import Datetime from '../util/datetime';

const getReturnValues = (countDown) => {
    return [Datetime.calculateDaysLeft(countDown), 
      Datetime.calculateHoursLeft(countDown), 
      Datetime.calculateMinsLeft(countDown),
      Datetime.calculateSecsLeft(countDown)];
  };

const useCountDown = (targetDate) => {
  const countDownDate = targetDate ? new Date(targetDate + " UTC").getTime() : Date.now();
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());
  useEffect(() => {
    if(targetDate){
      let isMounted = true;
      const interval = setInterval(() => {
        if (isMounted) {
          setCountDown(countDownDate - new Date().getTime());
        }
      }, 1000);
  
      return () => {
        isMounted = false;
        clearInterval(interval);
      };
    }
  }, [countDownDate]);

  return targetDate ? getReturnValues(countDown) : "0000";
};

export default useCountDown;