import { isSandbox } from "../../../config";
import API from "../../api";

const offer = (user, price, num) => {

    let data = {
        "price": parseFloat(price),
        "num": parseInt(num),
        "sandbox": isSandbox ? parseInt(1) : parseInt(0)
    }

    return new API(user).postRequest('/offer', data);
}

export default offer;