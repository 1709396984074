import { isSandbox } from "../../../config";
import API from "../../api";

const buy = (user, num_coins, max_price) => {

    let data = {
        "max_price": parseFloat(max_price),
        "num": parseInt(num_coins),
        "sandbox": isSandbox ? parseInt(1) : parseInt(0)
    }

    return new API(user).postRequest('/buy', data);
}

export default buy;