import { forwardRef, useContext } from 'react';
import { ReactComponent as Community_Icon} from '../assets/community_icon.svg';
import { ReactComponent as Dashboard_Icon} from '../assets/dashboard_icon.svg';
import { ReactComponent as History_Icon} from '../assets/history_icon.svg';
import { ReactComponent as Referrals_Icon} from '../assets/referrals_icon.svg';
import { ReactComponent as Education_Icon} from '../assets/education_icon.svg';
import { ReactComponent as Notifications_Icon} from '../assets/notifications_icon.svg';
import { ReactComponent as Queue_Icon} from '../assets/queue_icon.svg';
import { ReactComponent as Sandbox_Icon} from '../assets/sandbox_icon.svg';
import { ReactComponent as Account_Icon} from '../assets/account_icon.svg';
import { ReactComponent as Docs_Icon} from '../assets/docs_icon.svg';
import {useNavigate} from 'react-router-dom';
import { SystemContext } from '../../../../state/System';
import { bg, text, standard } from '../../../../config';

export default forwardRef(function SideBarButton (props, ref) {

    const { darkMode } = useContext(SystemContext);

    const navigate = useNavigate();

    const getIcon = (fill) => {
        if(props.name === 'notifications'){
            return <Notifications_Icon fill={fill}/>
        }else if(props.name === 'education'){
            return <Education_Icon fill={fill}/>
        }else if(props.name === 'history'){
            return <History_Icon fill={fill}/>
        }else if(props.name === 'referrals'){
            return <Referrals_Icon fill={fill}/>
        }else if(props.name === 'account'){
            return <Account_Icon fill={fill}/>
        }else if(props.name === 'queue'){
            return <Queue_Icon fill={fill}/>
        }else if(props.name === 'dashboard'){
            return <Dashboard_Icon fill={fill}/>
        }else if(props.name === 'community'){
            return <Community_Icon fill={fill}/>
        }else if(props.name === 'sandbox'){
            return <Sandbox_Icon fill={fill}/>
        }else if(props.name === 'docs'){
            return <Docs_Icon fill={fill}/>
        }else{
            return <Dashboard_Icon fill={fill}/>
        }
    }

    return props.active === props.name ? 
    (
        <div className="box-border snap-always snap-end motion-safe:animate-fadeIn py-2 select-none">
        <div className={"flex flex-col items-center"}>
            <div ref={ref} className={`flex flex-col justify-center items-center p-5 w-16 h-16 ${standard.rounded} shadow-lg ${bg.darkBlue} m-1 aspect-square cursor-pointer`}>
                {getIcon("white")}
            </div>
            <p className={`${text.blue} text-center`}>{props.label}</p>
        </div>
        </div>
    ) :
    (
        <div className="box-border snap-always snap-end motion-safe:animate-fadeIn py-2 select-none">
        <div className={"box-border flex flex-col items-center"} onClick={() => navigate('/d/' + props.name)}>
            <div ref={ref} className={`flex flex-col justify-center items-center w-16 h-16 p-5 ${standard.rounded} ${bg.button} m-1 aspect-square cursor-pointer`}>
                {getIcon(darkMode ? "white" : "blue")}
            </div>
            <p className="text-center">{props.label}</p>
        </div>
        </div>
    );
});