import Modal from "../../../general/Modal";
import { useContext, useState, useEffect } from "react";
import { TradingContext } from "../../../../../state/Trading";
import { minimum } from "../../../../../util/math";
import { Input, Label } from "./Components";
import { AccountContext } from "../../../../../state/Account";
import offer from "../../../../../api/endpoints/trading/offer";
import { text, bg, standard } from "../../../../../config";
import { minMaxStepEnforcer } from "../../../../../util/labels";

export default function Sell() {

    const { liveNumbers, cycleParams, personalInfo, mode } = useContext(TradingContext);

    const { user } = useContext(AccountContext);

    const [num, setNum] = useState(1);
    const [price, setPrice] = useState(0.00);

    const [resMessage, setResMessage] = useState(null);
    const [buttonStatus, setButtonStatus] = useState('normal');

    useEffect(() => {
        if(buttonStatus !== 'normal'){
            new Promise(r => setTimeout(r, 3000)).then(() => {
                setButtonStatus('normal');
                setResMessage(null);
            });
        }
    }, [buttonStatus]);

    const callOfferEndpoint = (e) => {
        e.preventDefault();
        offer(user, price, num).then((res) => {
            setResMessage(res.message);
            setButtonStatus('success');
        }).catch((err) => {
            setResMessage(err.message);
            setButtonStatus('error');
        });
    }

    const getButton = () => {

        if(mode === 'normal'){
        let txt = "Something went wrong.";
        let max_price = minimum(liveNumbers.min_offer_price * cycleParams.max_offer_increase_proportion, liveNumbers.min_offer_price + cycleParams.max_offer_increase_dollar)
        if(num <= 0) txt = "You must offer at least one Straato."
        else if(num > cycleParams.max_per_sell) txt = "You cannot offer more than " + cycleParams.max_per_sell + " Straato at one time.";
        else if(num > personalInfo.total_straato) txt = "You only own " + personalInfo.total_straato + " Straato.";
        else if(price < liveNumbers.min_offer_price) txt = "Your offer must be at least $" + liveNumbers.min_offer_price.toFixed(2) + ".";
        else if(price > max_price) txt = "Your offer cannot exceed $" + max_price.toFixed(2) + ".";

        let submittable = num > 0 && num <= cycleParams.max_per_sell 
                            && num <= personalInfo.total_straato && price >= liveNumbers.min_offer_price
                            && price < max_price;

        if(buttonStatus === 'normal'){
            return(
                <div>
                    {submittable ? <></> : <small className={`${text.red}`}>{txt}</small>}
                    <button
                        type={submittable ? "submit" : ""}
                        className={`flex w-full justify-center ${standard.formRounded} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                            submittable
                              ? `${bg.blue} hover:${text.lightBlue}`
                              : `${bg.offwhite} ${text.grey}`
                          }`}
                    >
                        List on Offer Queue
                    </button>
                </div>
                )
        }else if(buttonStatus === 'error'){
            return (<div>
                <button className={`select-none flex w-full justify-center ${standard.formRounded} ${bg.red} px-3 py-1.5 mt-2 text-sm font-semibold leading-6 text-white shadow-sm`}>
                    Failed to List
                </button>
                <small className={`mt-2 ${text.red}`}>{resMessage}</small>
            </div>)
        }else if(buttonStatus === 'success'){
            return (<div>
                <button className={`select-none flex w-full justify-center ${standard.formRounded} ${bg.green} px-3 py-1.5 mt-2 text-sm font-semibold leading-6 text-white shadow-sm`}>
                    Success!
                </button>
                <small className={`mt-2 ${text.green}`}>{resMessage}</small>
            </div>)
        }}else{
            return(
                <div>
                    <small className={`${text.red}`}>You cannot list Straato during a Marketplace pause.</small>
                    <button
                        className={`select-none flex w-full justify-center ${standard.formRounded} ${bg.offwhite} px-3 py-1.5 mt-2 text-sm font-semibold leading-6 ${text.grey} shadow-sm`}>
                        List on Offer Queue
                    </button>
                </div>
                )
        }
    }

    return (
        <Modal title={"Sell Straato"} type={"offer"} subtext={"List Straato on the offer queue to sell them for credits."} 
        className={`flex flex-col ${bg.white} w-1/2 h-full p-6 items-center justify-center rounded-r-xl`} 
        button_view={<h4>Sell</h4>}>
            <div className="mt-10 w-full">
                <form className="space-y-6" onSubmit={callOfferEndpoint}>
                    <div>
                    <Label text={"Number of Straato"}/>
                    <Input
                        onChange={e => setNum(e.target.value)}
                        onBlur={() => setNum(minMaxStepEnforcer(num, 1, cycleParams.max_per_sell, 1))}
                        value={num}
                        id="number"
                        name="number"
                        type="number"
                        max={cycleParams.max_per_sell}
                        min={1}
                        overlayPosition="back"
                        overlayText="Straato"
                        step={1}/>
                    </div>

                    <div>
                    <Label text={"Listing Price"}/>
                    <Input
                        onChange={e => setPrice(e.target.value)}
                        onBlur={() => setPrice(minMaxStepEnforcer(price, liveNumbers.min_offer_price, Number.MAX_SAFE_INTEGER, 0.01))}
                        value={price}
                        id="price"
                        name="price"
                        type="number"
                        step={0.01}
                        overlayPosition="front"
                        overlayText="$"
                        min={liveNumbers.min_offer_price}
                        />
                    </div>
                    {getButton()}
                </form>
                </div>
        </Modal>
    );
}