import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import useIsVisible from "../../../hooks/useIsVisible";
import ReferralsMockup from '../assets/referrals_mockup.png';

import { CurrencyDollarIcon, TrophyIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid'
import ShowOnScroll from "./ShowOnScroll";
import { bg, standard, text } from "../../../config";
import ResponsiveImage from "../../components/Image";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export default function Sandbox(){

    const navigate = useNavigate();

    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const { width } = useWindowDimensions();

    /*                <div className="w-full mb-20 md:mb-0 md:w-1/2 px-0 sm:px-10 md:px-20">
    <img src={Straato} className="max-h-full aspect-auto"/>
    </div>*/
    return (
        <>
            <ShowOnScroll>
            <div ref={ref} className="flex flex-row flex-wrap-reverse w-full p-6 md:p-20 mt-20">
                <div className="flex flex-col w-full md:w-5/12 text-center md:text-left">
                    <h2 className={`${text.alwaysblack} font-bold`}>A paper-trading sandbox.</h2>
                    <p className={`${text.desc} font-light mt-10 leading-8`}>Although the live Straato Market is still coming soon, we have released a paper-trading sandbox environment. The sandbox will allow you to practice trading and developing strategies on the Straato Market with no stakes. Other traders on the sandbox will include both people and bots to provide activity as we grow our trader base. The sandbox environment will also allow you to participate in live demos and earn free Straato tokens that can be traded when the real Market opens.</p>
                </div>
                <div className={`flex items-center justify-center w-full mt-0 mb-10 md:w-7/12 pl-0 sm:pl-20 md:pl-40
                        ${isVisible ? `animate-slideinright opacity-100` : "opacity-0"}`}>
                            <ResponsiveImage alt="Refer your friends!" src={ReferralsMockup} width={width > 768 ? "50%" : "20%"}/>
                </div>
            </div>
            </ShowOnScroll>
            <ShowOnScroll>
                    <div className="flex flex-row w-full px-0 md:px-20">
                    <div className="flex flex-row w-full flex-wrap justify-evenly">
                        <div className={`flex flex-col w-full ${standard.mdW} items-center px-8 mt-12`}>
                            <ChatBubbleLeftRightIcon className={`mb-4 h-20 w-20 flex-none ${text.orange} cursor-pointer`} aria-hidden="true" />
                            <h4 className={`${text.alwaysblack} text-center`}>Refer your friends</h4>
                            <small className={`${text.desc} font-light mt-6 leading-6 text-center`}>Refer your friends to join the Straato Market and earn free Straato tokens which can be sold for profits when trading begins. The more friends you refer to join, the more free tokens you will get!</small>
                        </div>
                        <div className={`flex flex-col w-full ${standard.mdW} items-center px-8 mt-12 `}>
                            <CurrencyDollarIcon className={`mb-4 h-20 w-20 flex-none ${text.orange} cursor-pointer`} aria-hidden="true" />
                            <h4 className={`${text.alwaysblack} text-center`}>Donate to kickstarter</h4>
                            <small className={`${text.desc} font-light mt-6 leading-6 text-center`}>We will be launching a kickstarter campaign soon to help us fund the continued improvement of the Straato Market. Donate to the campaign to earn free tokens and contribute to our mission of re-envisioning trading.</small>
                        </div>
                        <div className={`flex flex-col w-full ${standard.mdW} items-center px-8 mt-12`}>
                            <TrophyIcon className={`mb-4 h-20 w-20 flex-none ${text.orange} cursor-pointer`} aria-hidden="true" />
                            <h4 className={`${text.alwaysblack} text-center`}>Win trading competitions</h4>
                            <small className={`${text.desc} font-light mt-6 leading-6 text-center`}>We will be hosting paper trading competitions in the sandbox. Do you have what it takes to win on Straato, the ultimate strategy market? If you do, we’ll reward you with free tokens for live trading. Not to mention, bragging rights!</small>
                        </div>
                    </div>
                </div>
            </ShowOnScroll>
        </>
    )
}