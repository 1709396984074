import getUserOffers from "../../../../api/endpoints/queue/getUserOffers";
import { useContext, useState, useEffect } from "react";
import { AccountContext } from "../../../../state/Account";
import { QueueContext } from "../../../../state/Queue";
import { getSafeValue } from "../../../../util/json";
import Widget from "../../general/layout/Widget";
import { bg, border, standard } from "../../../../config";
import OfferItem from "./Offer";
import deleteOffer from "../../../../api/endpoints/queue/deleteOffer";

export default function MyOffers(props) {

    const { user } = useContext(AccountContext);

    const { userOffers, setUserOffers, setMode } = useContext(QueueContext);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            getUserOffers(user, setMode).then((res) => {
                setUserOffers(res);
                setIsLoading(false);
            }).catch((err) => {
                setUserOffers({});
                setIsLoading(false);
            });
        }
    }, [isLoading]);

    return (
        <Widget className="pt-4 px-4 overflow-hidden">
            <div className="flex flex-row justify-between items-center w-full select-none">
                <div className="flex flex-row items-center justify-start">
                    <h4 className="pr-4">Listed Offers</h4>
                </div>
                <h4 className="pl-4">{getSafeValue(userOffers, "count", "int")} Straato</h4>
            </div>
            <hr className={`h-px ${standard.mt} ${bg.blue} ${bg.darkBlue} border-1`} />
            <div className="overflow-y-scroll no-scrollbar" style={{ maxHeight: 500 }}>
                <ul>
                    {getSafeValue(userOffers, "offers", "list").map((offer, index) => (
                        <OfferItem
                            key={index}
                            offer={offer}
                            index={index}
                            handleEditOffer={(num) => {
                                deleteOffer(user, offer.price, num).then(() => {
                                    setIsLoading(true);
                                }).catch((err) => {});
                            }}
                        />
                    ))}
                    {(!isLoading && getSafeValue(userOffers, "offers", "list").length === 0) ? (
                        <div className="flex flex-row w-full items-center justify-center mt-10 px-20">
                            <p className="text-center">You have no pending listings on the offer queue at this time.</p>
                        </div>) : <></>
                    }
                </ul>
            </div>
        </Widget>
    );
}