import OfferQueue from '../assets/offer_queue.png';
import StraatoTokens from '../assets/straato_tokens.png';
import Reset from '../assets/reset.png';
import CyclicalTrading from '../assets/cyclical_trading.png';
import ShowOnScroll from './ShowOnScroll';
import { text } from '../../../config';
import ResponsiveImage from '../../components/Image';

export default function HowItWorks(){
    return (
        <ShowOnScroll>
        <div className="flex flex-col w-full items-center justify-center p-6 md:p-20">
        <div className="flex flex-col w-full md: w-11/12 justify-center font-light">
        <h1 className={`${text.alwaysblack} text-center font-bold`}>How it works</h1>
        <p className={`${text.alwaysblack} mt-10 mb-4 leading-8 text-center`}>The Straato Market exchange system is based on a couple of key mechanisms. Each plays a crucial role in making the market work.</p>
        <div className="flex flex-row w-full flex-wrap justify-evenly">
            <div className="flex flex-col w-full md:w-1/2 px-2 md:px-12 items-center">
                <ResponsiveImage src={StraatoTokens} alt={"Straato tokens."} height={158}/>
                <h4 className={`${text.alwaysblack} mt-6 text-center`}>Straato tokens.</h4>
                <p className={`${text.alwaysblack} mt-2 leading-2 text-center`}>Straato tokens are all uniform and only exist on the Straato Market. You can think of a token as just a unique record on a hidden ledger. The tokens are valuable only in the context of the Market’s exchange system, where the real work happens.</p>
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-2 md:px-12 items-center">
                <ResponsiveImage src={OfferQueue} alt={"The offer queue."} height={158}/>
                <h4 className={`${text.alwaysblack} mt-6 text-center`}>The offer queue.</h4>
                <p className={`${text.alwaysblack} mt-2 leading-2 text-center`}>Straato tokens are exchanged on what we call the “offer queue” (OQ), based on a data structure called a priority queue. You can list your tokens for sale on the queue and buy other tokens from the front. Tokens on the queue are ordered by price, enforcing that the price of tokens only increases during trading.</p>
            </div>
        </div>
        <div className="flex flex-row w-full flex-wrap justify-evenly mt-20">
            <div className="flex flex-col w-full md:w-1/2 px-2 md:px-12 items-center">
                <ResponsiveImage src={Reset} alt={"The reset."} height={158}/>
                <h4 className={`${text.alwaysblack} mt-6 text-center`}>The reset.</h4>
                <p className={`${text.alwaysblack} mt-2 leading-2 text-center`}>The price of tokens obviously cannot increase forever – eventually the price will get too high and demand for tokens will vanish. We monitor market demand, calculated through 2 different statistics: 1) the “velocity of the offer queue” (QVEL) and 2) “queue growth rate” (QGR). You’ll have access to these statistics at all times. As soon as demand goes below the Market minimum threshold, the Market resets and the token price goes back down to $1.</p>
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-2 md:px-12 items-center">
                <ResponsiveImage src={CyclicalTrading} alt={"Cyclical trading"} height={158}/>
                <h4 className={`${text.alwaysblack} mt-6 text-center`}>Cyclical trading.</h4>
                <p className={`${text.alwaysblack} mt-2 leading-2 text-center`}>After a market reset, trading begins again after a 5-minute pause. All owners of Straato through the Reset will likely incur unrealized losses as a result. The next cycle presents an opportunity to recoup those unrealized losses and generate profits. This cyclical process forms the basis of the Straato Market and each trading period is called a “cycle.”</p>
            </div>
        </div>
    </div>
    </div>
    </ShowOnScroll>
    )
}


/*export default function HowItWorks(){
    return (
        <ShowOnScroll>
        <div className="flex flex-col w-full p-20">
        <h1 className="text-gray-600 dark:text-gray-600 text-center font-bold">How it works</h1>
        <p></p>
        <div className="flex flex-row w-full flex-wrap justify-evenly mt-20">
            <div className="flex flex-col w-full md:w-1/4 px-2 md:px-8">
                <img src={Paypal} className="max-h-full aspect-auto"/>
                <h4 className="text-gray-900 dark:text-gray-900 mt-6 text-center">Straato tokens.</h4>
                <small className="text-gray-600 dark:text-gray-600 mt-2 leading-2 text-center">Straato tokens are all uniform and only exist on the Straato Market. You can think of a token as just a unique record on a hidden ledger. The tokens are valuable only in the context of the Market’s exchange system, where the real work happens.</small>
            </div>
            <div className="flex flex-col w-full md:w-1/4 pt-12 md:pt-32 px-2 md:px-8">
                <img src={Paypal} className="max-h-full aspect-auto"/>
                <h4 className="text-gray-900 dark:text-gray-900 mt-6 text-center">The offer queue.</h4>
                <small className="text-gray-600 dark:text-gray-600 mt-2 leading-2 text-center">Straato tokens are exchanged on what we call the “offer queue” (OQ), based on a data structure called a priority queue. You can list your tokens for sale on the queue and buy other tokens from the front. Tokens on the queue are ordered by price, enforcing that the price of tokens only increases during trading.</small>
            </div>
            <div className="flex flex-col w-full md:w-1/4 px-2 pt-12 md:pt-0 md:px-8">
                <img src={Paypal} className="max-h-full aspect-auto"/>
                <h4 className="text-gray-900 dark:text-gray-900 mt-6 text-center">The reset.</h4>
                <small className="text-gray-600 dark:text-gray-600 mt-2 leading-2 text-center">The price of tokens obviously cannot increase forever – eventually the price will get too high and demand for tokens will vanish. We monitor market demand, calculated through 2 different statistics: 1) the “velocity of the offer queue” (QVEL) and 2) “queue growth rate” (QGR). You’ll have access to these statistics at all times. As soon as demand goes below the Market minimum threshold, the Market resets and the token price goes back down to $1.</small>
            </div>
            <div className="flex flex-col w-full md:w-1/4 pt-12 md:pt-32 px-2 md:px-8">
                <img src={Paypal} className="max-h-full aspect-auto"/>
                <h4 className="text-gray-900 dark:text-gray-900 mt-6 text-center">Cyclical trading.</h4>
                <small className="text-gray-600 dark:text-gray-600 mt-2 leading-2 text-center">After a market reset, trading begins again after a 5-minute pause. All owners of Straato through the Reset will likely incur unrealized losses as a result. The next cycle presents an opportunity to recoup those unrealized losses and generate profits. This cyclical process forms the basis of the Straato Market and each trading period is called a “cycle.”</small>
            </div>
        </div>
    </div>
    </ShowOnScroll>
    )
}*/