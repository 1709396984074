import { ReactComponent as Trophy } from "./assets/trophy-solid.svg";
import { useState, useEffect, memo } from 'react';
import Loader from '../../components/Loader';
import { getSafeValue } from '../../../util/json';
import { standard, border, dark, text, bg } from "../../../config";

export default memo(function Leaderboard(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    props.getData(setIsLoading, setData);
  }, []);

  const mapper = item => props.map[item];

  let excludes = props.excludes ? props.excludes : [];

  let headers = props.noheader ? [] : data.length > 0 ? Object.keys(data[0]).filter((val) => !excludes.includes(val)).map(mapper) : Object.values(props.map);

  const indexView = (index, isUser) => {
    if (index === 1) {
      return <td valign="center" className="w-1/12"><Trophy fill={"gold"} /></td>
    } else if (index === 2) {
      return <td valign="center" className="w-1/12"><Trophy fill={"silver"} /></td>
    } else if (index === 3) {
      return <td valign="center" className="w-1/12"><Trophy fill={"#CD7F32"} /></td>
    } else {
      return <td valign="center" className={`w-12 text-center whitespace-nowrap text-lg sm:text-xl font-semibold ${isUser ? text.blue + + " font-semibold" : ""}`}>{index}.</td>
    }
  }

  return (
    <>
      <table className={`w-full text-center`}>
        <thead className={`${bg.white} font-semibold ${border.darkGrey} select-none`}>
          <tr>
            <th scope="col" className={`${text.black}`}>Rank</th>
            {headers.map((header, i) => {
              return <th key={i} scope="col">{header}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={i} className={`${i === (data.length - 1) ? "" : "border-b"} ${border.darkGrey} ${bg.white}`}>
                {indexView(i + 1, row.isUser)}
                {Object.keys(row).map((key, k) => {
                  if (!excludes.includes(key)) {
                    return <td valign="center" key={key + i} className={`whitespace-nowrap text-base py-4 ${row.isUser ? text.blue + " font-semibold" : ""} ${props.text_style}`}>{getSafeValue(props.prefixes, key, "string")}{row[key]}{getSafeValue(props.postfixes, key, "string")}</td>
                  }
                })}
              </tr>);
          })}
        </tbody>
      </table>
      {!isLoading && data.length === 0 ? <div className="flex flex-row w-full items-center justify-center mt-10 px-20"><p className="text-center">{props.emptyMessage ? props.emptyMessage : "No data at the moment."}</p></div> : <></>}
      {isLoading ? <div className={`w-full h-full border-box ${standard.p}`}><Loader /></div> : <></>}
    </>
  );
});
