/*import Feature from "../../general/Feature";
import { ReactComponent as X } from '../../general/assets/x.svg'
import { useState, useEffect } from 'react';
import { ReactComponent as Add } from '../assets/plus.svg'
import { ReactComponent as RightArrow } from '../assets/right_arrow.svg'*/
//import { isSandbox } from "../../../../config";

import { standard } from '../../../../config';
import Widget from '../../general/layout/Widget';
import Paypal from '../assets/paypal.png';

export default function Payment(props) {

    //const [account, setAccount] = useState(false);

    /*useEffect(() => {
        new Promise(r => setTimeout(r, 2000)).then(() => {
            props.setPaymentLoading(false);
        });
    }, []);*/

    return (
        <Widget className={`${standard.p} justify-start items-start`}>
            <div className="flex flex-col items-center justify-center h-8">
                <img src={Paypal} className="max-h-full aspect-auto" />
            </div>
            <small className="pt-3">The Straato Market is still in sandbox mode. When live trading begins, you will be able to link your paypal account here.</small>

        </Widget>
    );
}

/*
export default function Payment (props) {

    const [account, setAccount] = useState(false);

    useEffect(() => {
        new Promise(r => setTimeout(r, 2000)).then(() => {
            props.setPaymentLoading(false);
        });
    }, []);

    const getAccountView = () => {
        return account ? (
            <div className="flex flex-col w-full h-full mt-5 border rounded-2xl border-blue-500 dark:border-blue-800 bg-blue-50 dark:bg-gray-600 justify-between p-6">
                <div className="flex flex-row w-full items-center justify-between flex-no-wrap space-x-6">
                    <div className="flex flex-col items-center justify-center w-5/12 sm:w-1/3 md:w-1/4">
                        <img src={Paypal} className="max-h-full aspect-auto"/>
                    </div>
                    <div className="w-4" onClick={() => setAccount(false)}><X/></div>
                </div>
                <div className="flex flex-row w-full items-end justify-between flex-no-wrap mt-10">
                    <div className="mr-4">
                        <Feature value={"owefenfeofneo3f"} label={"Connected Account ID"} noPad={true}/>
                    </div>
                    <div className="flex flex-col bg-blue-500 w-1/2 h-full items-center justify-center rounded-2xl">
                        <h4 className="text-white">Payout</h4>
                    </div>
                </div>
            </div>
        ): (
            <div className="flex flex-col w-full h-full mt-5 border rounded-2xl border-blue-500 bg-gray-50 dark:border-blue-800 dark:bg-gray-600 justify-center items-center p-6">
                <div className="flex flex-row w-full items-center justify-between flex-no-wrap space-x-6">
                    <div className="flex flex-col items-center justify-center w-5/12 sm:w-1/3 md:w-1/4">
                        <img src={Paypal} className="max-h-full aspect-auto"/>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center cursor-pointer" onClick={() => setAccount(true)}>
                <div className="flex flex-row items-center justify-center w-24 h-24 rounded-2xl p-4">
                    <Add/>
                </div>
                <p className="text-blue-500">Link Paypal Account</p>
                </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col h-full w-full bg-white dark:bg-gray-900 rounded-2xl p-4">
            <h4>Payment Account Setup</h4>
            {isSandbox ? <div className="w-full h-full flex flex-col justify-center items-center"><p className="text-center">Payment configuration is not applicable in sandbox mode.</p></div>
            : getAccountView()}
        </div>
    );
}*/